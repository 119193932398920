import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import plugins from './plugins';
import globalMix from './mixins/global';
import ElementUI, { Table } from 'element-ui';
import { VueJsonp } from 'vue-jsonp';
import moment from 'moment';
import _ from 'lodash';
import aegis from 'nges-common/src/web/utils/aegis.js';
// import { loadJs } from 'nges-common/src/web-mobile/util';

import './assets/css/app.scss';
import './assets/css/reset.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'nges-common/src/css/web/index.scss';
import './common/iconfont/style.css';
import 'nges-common/src/css/theme.scss';
import { flushTask } from 'nges-common/src/tracking/common/task';
import trackingListener from 'nges-common/src/tracking/listener';
import { loadCustomIconFont } from 'nges-common/src/web/utils/loadCustomIconFont';
import './overrides';

import LocalStore from 'nges-common/src/web/components/layout/list/localStore';

import drag from 'v-drag';

import { lstore } from 'nges-common/src/web/utils/util';

Vue.prototype.$moment = moment;
Vue.prototype._ = _;
Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nStack: ${err.stack}\nInfo: ${info}`);
  aegis.error(`Error: ${err.toString()}\nStack: ${err.stack}\nInfo: ${info}`);
};

Vue.use(plugins);
Vue.use(VueJsonp);
Vue.use(drag, { removeTransition: false });

/* 预加载 wujie 组件，移动到 App.vue 文件，根据 kv 获取预请求配置 */

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
// https://github.com/ElemeFE/element/issues/11420#issuecomment-898992179
const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener;
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};
fixElTableErr(Table);
Vue.use(ElementUI);

// 企业微信扫码网页嵌入登录页 扫码后跳转到登录页会必定触发接口 需要阻止被嵌入时接口的提示
(() => {
  const message = Vue.prototype.$message;
  const newMessage = _.debounce(function (...e) {
    if (parent !== window) return;
    message(...e);
  }, 100);

  const keyList = ['success', 'warning', 'info', 'error'];
  keyList.forEach((key) => {
    const old = Vue.prototype.$message[key];
    newMessage[key] = _.debounce(function (...e) {
      if (parent !== window) return;
      old(...e);
    }, 100);
  });

  Vue.prototype.$message = newMessage;
})();

Vue.mixin(globalMix);

Vue.routerGo = function (option, isReplace) {
  const routerFn = isReplace ? router.replace : router.push;
  const routerGo = () => {
    routerFn.call(router, option);
  };
  routerGo();
};

// 网关越权sdk接入，仅测试环境有效
// if (process.env.VUE_APP_ENV === 'test') {
//   loadJs({
//     url: 'https://static.wecity.qq.com/h5/2024-1/index-d734b7334096f958d7c2ceb42525b91a.js',
//     onload: () => {
//       const report = new window.HealthDeptReqReport({
//         chargeUser: 'simonsmchen',
//         bizId: 'nges',
//         ignoreHackReg: /aegis.qq.com/gi,
//         standardHost: '',
//       });
//       report.startMonitor();
//     },
//   });
// }
// 创建事件总线并添加到 Vue 原型上
Vue.prototype.$trackingEventBus = new Vue();
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    flushTask();
  } else {
    Vue.prototype.$trackingEventBus.$emit('tracking_on_show', {
      curUrl: location.pathname + location.search,
    });
  }
});
// 在关闭页面埋点上报前，清空上报任务，防止数据丢失
window.addEventListener('beforeunload', () => {
  flushTask();
});

// 打开埋点监听上报
trackingListener();

// 导入租户自定义的字体文件
loadCustomIconFont();

// 全局挂载获取安全时间的函数
const _sfDate = () => {
  const deffTime = lstore.storage.getItem('__deff_date__');
  if (deffTime && !Number.isNaN(Number(deffTime))) {
    return new Date().getTime() + Number(deffTime);
  }
  return new Date().getTime();
};
Object.defineProperty(Object.prototype, '_sfDate', {
  value: _sfDate,
});

try {
  LocalStore.processAndUpdateConfig();
} catch (error) {
  console.log(error);
}
export default new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
