// 用户列表
// 获取用户列表
const UserList = `query {data:admin_user_info(_order_by: { update_time: _desc},$pageOption,$filter) {_aggregate{ _count},id, user_info{name},user_id, wechat}}`;

const AllUserCount = `query{user_info($filter){_aggregate{ _count}}}`;

const UserInfoList = `query{user_info(_where: {name: {_neq:""},name:{_is_null:false}},_order_by: { name: _asc},$filter, $pageOption){uin,name,email,user_code,territorys { id,name}}}`;

const MCCPUser = `query{user_info(_order_by: { update_time: _desc},$filter){id,name,uin,user_code,territorys{name},departments{name}}}`;

// 上级岗位code
const LeaderTerritory = `query { territory (_where:{code: $_territory}) { parent_code } }`;

// 查询当前用户信息 (userInfo接口查询 信息不全)
const UserInfoGql = `query{user_info(uin: $uin $where_qgl){ id, uin, department_ids $other_keys }}`;

export { UserList, AllUserCount, UserInfoList, MCCPUser, LeaderTerritory, UserInfoGql };
