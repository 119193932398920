//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash';

export default {
  name: 'ListInput',
  props: {
    mutation: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: [String, Number, void 0],
      default: void 0,
    },
    row: {
      type: Object,
      default: () => {},
    },
    prop: {
      type: String,
      default: '',
    },
    // 表单变更对象
    mutations: {
      type: [Object, void 0],
      default: void 0,
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  watch: {
    mutations() {
      this.deInitData();
    },
  },
  mounted() {
    this.deInitData = debounce(() => {
      let mutation;
      if (this.mutations) {
        mutation = this.mutations?.[this?.row?.id]?.find(
          (i) => i.field.replaceAll('.', '-') === this.prop,
        );
        if (mutation) {
          this.row[this.prop] = mutation.value;
          const oldInputValue = this.inputValue;
          this.inputValue = mutation?.value;
          // 通过当前组件handleChange出去的，重新merge回来的数据是一致的，不需要再次出发resetFields, 防止清空了校验，其他则触发reset
          if (oldInputValue !== this.inputValue) {
            this.$emit('resetFields');
          }
        }
      }
    }, 200);
    this.initData();
  },
  methods: {
    initData() {
      let mutation;
      if (this.mutations) {
        mutation = this.mutations?.[this?.row?.id]?.find(
          (i) => i.field.replaceAll('.', '-') === this.prop,
        );
        if (mutation) {
          this.row[this.prop] = mutation.value;
        }
      }
      this.inputValue = mutation?.value ?? this.formValue;
      this.$emit('resetFields');
    },
    handleInput(e) {
      this.row[this.prop] = e;
      this.$emit('handleChange', {
        value: e,
        data: e,
      });
    },
  },
};
