//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep, debounce, isNil } from 'lodash';
import { easyQueryGql } from 'nges-common/src/layout/queryUtil';
import { DataService } from '../../../utils/api';
import { filterOptions, searchGql } from 'nges-common/src/layout/util';
const _ = { cloneDeep };

export default {
  name: 'MultipleSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchText: '',
      page: 1,
      size: 30,
      checkList: [],
      seq: 1, // 用来标志请求，如果请求前和请求后的seq不一致，则不执行渲染
    };
  },
  computed: {
    selectOptions() {
      let pageOptions = [];
      if (this.filter?.use_split_page) {
        pageOptions = this.filter?.options;
      } else {
        pageOptions = this.pageOptions;
      }
      const { searchText } = this;
      const options = searchText
        ? _.cloneDeep(pageOptions).reduce((pre, i) => {
            const { label = '' } = i || {};
            if (label) {
              const lowerCaseLabel = label?.toLowerCase();
              const lowerCaseSearchText = searchText.toLowerCase();
              if (lowerCaseLabel.includes(lowerCaseSearchText)) {
                i.htmlLabel = label
                  .split(searchText)
                  .join(`<span style="color: var(--Text-Brand-default)">${searchText}</span>`);
                pre.push(i);
              }
            }
            return pre;
          }, [])
        : pageOptions;
      return options.filter((i) => i.label);
    },
    pageOptions() {
      if (!this.searchText) {
        const totalOptions = this.options || [];
        return totalOptions.slice(0, this.page * this.size);
      }
      return this.filterOptions.slice(0, this.page * this.size);
    },
    filterOptions() {
      if (this.searchText) {
        const totalOptions = this.options || [];
        const filterOptions = totalOptions.filter((item) => {
          return item.label?.toLowerCase().includes(this.searchText.toLowerCase());
        });
        return filterOptions;
      }
      return [];
    },
    search() {
      return this.filter?.render?.search || this.filter?.search || undefined;
    },
    descDelimiter() {
      return this.filter?.desc_delimiter || '';
    },
  },
  watch: {
    value(val) {
      console.log('value', val);
      this.checkList = Array.isArray(val) ? val : [];
    },
    searchText() {
      this.searchHandle();
    },
  },
  mounted() {
    this.checkList = Array.isArray(this.value) ? this.value : [];
  },
  methods: {
    clearCheckedNodes() {
      this.checkList = [];
    },
    open() {
      if (this.filter?.use_split_page) {
        this.$set(this.filter, '$page_size', this.filter?.page_size || this.size);
        this.$set(this.filter, '$page', this.filter?.page || 1);
        this.$set(this.filter, 'queryOptions', []);
        this.$set(this.filter, 'options', []);
        this.$set(this.filter, '$isLoaded', false);
        this.$set(this.filter, '$loading', false);
        this.load();
      }
    },
    searchHandle: debounce(function () {
      if (this.filter?.use_split_page) {
        this.doSearch();
      }
    }, 300),
    doSearch() {
      this.open(); // 重新初始化搜索项
      this.load();
    },
    change(val) {
      this.$emit('change', val);
    },
    setSearchToFilter(filter) {
      let searchGqlStr = '';
      if (this.search) {
        searchGqlStr = searchGql(this.searchText, this.search.fields, this.filter) || undefined;
      }
      if (searchGqlStr) {
        if (!filter.wheres) {
          filter.wheres = [];
        }
        const { wheres } = filter;
        // 注入搜索查询条件
        let whereMainFields = wheres.find((item) => item.object === filter.object || !item.object);
        if (!whereMainFields) {
          whereMainFields = { fields: [] };
          wheres.push(whereMainFields);
        }
        whereMainFields.fields.push({ where_gql: searchGqlStr });
      }
      return filter;
    },
    load() {
      const { filter } = this;
      if (filter?.use_split_page && !filter.$isLoaded && !filter.$loading) {
        if (!filter.$isLoaded && !filter.$loading) {
          const pageSize = filter.$page_size;
          const condition = {
            [filter?.object]: {
              _limit: pageSize,
              _offset: (filter.$page - 1) * pageSize,
            },
          };
          const queryFilter = this.setSearchToFilter(cloneDeep(filter));
          filter.$loading = true;
          const gql = easyQueryGql(
            {
              ...queryFilter,
              fields: ['_aggregate._count'].concat(
                filter.fields || [filter.label, filter.value].filter((item) => !isNil(item)),
              ),
            },
            condition,
          );
          this.seq = this.seq + 1;
          const { seq } = this;
          DataService({
            query: gql,
          })
            .then((res) => {
              if (seq !== this.seq) return;
              filter.queryOptions = filterOptions(filter, {
                [filter.object]: res?.[filter.object]?._data ?? res?.[filter.object],
              });
              filter.options = [...filter.options, ...filter.queryOptions];
              if (res?.[filter.object]?._aggregate?.count <= filter.$page * filter.$page_size) {
                filter.$isLoaded = true;
              }
              filter.$page += 1;
              console.log('------filter.options------', filter.options);
            })
            .catch((e) => {
              this.$message.error(e.message || '加载失败');
            })
            .finally(() => {
              filter.$loading = false;
            });
        }
      } else {
        const pageTatalSize = this.page * this.size;
        if (!this.searchText) {
          const total = this.options.length;
          const noMoreWhenNoFilter = total > pageTatalSize;
          if (noMoreWhenNoFilter) {
            this.page += 1;
            console.log('pageTatalSize', pageTatalSize);
          }
        } else {
          const filterOptionsSize = this.filterOptions.length;
          const noMoreWhenHadFilter = filterOptionsSize > pageTatalSize;
          if (noMoreWhenHadFilter) {
            this.page += 1;
            console.log('pageTatalSize', pageTatalSize);
          }
        }
      }
    },
  },
};
