//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep, debounce, isNil } from 'lodash';
import { easyQueryGql } from 'nges-common/src/layout/queryUtil';
import { DataService } from '../../../utils/api';
import { filterOptions, searchGql } from 'nges-common/src/layout/util';
import topSelectMixin from './topSelectMixin';
export default {
  name: 'TopMultipleSelect',
  mixins: [topSelectMixin],
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    valueType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: '',
      page: 0,
      size: 10,
      filterOptions: [],
      showOptions: [],
      searchText: '',
      seq: 1, // 用来标志请求，如果请求前和请求后的seq不一致，则不执行渲染
      inputText: '',
      needfocus: false,
    };
  },
  computed: {
    search() {
      return this.filter?.render?.search || this.filter?.search || undefined;
    },
    descDelimiter() {
      return this.filter?.desc_delimiter || '';
    },
  },
  watch: {
    options() {
      this.filterMethod();
    },
    value(val) {
      this.selectedValue = val;
    },
  },
  mounted() {
    this.filterMethod();
    this.selectedValue = this.value;
    // if (this.valueType === 'SELECT_MANY') {
    //   // 多选的blur事件不生效，额外设置在这里
    //   this.$refs.select.$refs.input.blur = () => {
    //     this.handleBlur();
    //   };
    // }
  },
  methods: {
    change() {
      this.resetSelect(this.selectedValue, 'select');
      this.$emit('change', this.selectedValue);
    },
    filterMethod(filterVal) {
      if (this.filter?.use_split_page) {
        return;
      }
      if (filterVal) {
        const lowerCaseSearchText = filterVal.toLowerCase();
        this.filterOptions = (this.options || []).filter((item) => {
          return item?.label.toLowerCase().indexOf(lowerCaseSearchText) > -1;
        });
      } else {
        this.filterOptions = this.options;
      }
      this.page = 0;
      this.getMore();
    },
    debounceFilterMethod: debounce(function (filterVal) {
      if (this.filter?.use_split_page) {
        this.searchText = filterVal;
        this.open(); // 重新初始化搜索项
        this.getMore();
      }
    }, 300),
    getMore() {
      const { filter } = this;
      if (filter?.use_split_page) {
        if (!filter.$isLoaded && !filter.$loading) {
          const pageSize = filter.$page_size;
          const condition = {
            [filter?.object]: {
              _limit: pageSize,
              _offset: (filter.$page - 1) * pageSize,
            },
          };
          const queryFilter = this.setSearchToFilter(cloneDeep(filter));
          filter.$loading = true;
          const gql = easyQueryGql(
            {
              ...queryFilter,
              fields: ['_aggregate._count'].concat(
                filter.fields || [filter.label, filter.value].filter((item) => !isNil(item)),
              ),
            },
            condition,
          );
          this.seq = this.seq + 1;
          const { seq } = this;
          DataService({
            query: gql,
          })
            .then((res) => {
              if (seq !== this.seq) return;
              filter.queryOptions = filterOptions(filter, {
                [filter.object]: res?.[filter.object]?._data ?? res?.[filter.object],
              });
              filter.options = [...filter.options, ...filter.queryOptions];
              if (res?.[filter.object]?._aggregate?.count <= filter.$page * filter.$page_size) {
                filter.$isLoaded = true;
              }
              filter.$page += 1;
              console.log('------filter.options------', filter.options);
              this.showOptions = filter.options;
            })
            .catch((e) => {
              this.$message.error(e.message || '加载失败');
            })
            .finally(() => {
              filter.$loading = false;
            });
        }
      } else {
        const filterOptions = this.filterOptions || [];
        // this.filterOptions && this.filterOptions.length ? this.filterOptions : this.options;
        if (this.showOptions >= filterOptions.length) {
          return;
        }
        this.page = this.page + 1;
        const length = this.page * this.size;
        this.$nextTick(() => {
          if (this.needfocus) {
            this.$refs?.inputSearch?.focus();
            this.$refs?.inputSearchNew?.focus();
          }
        });
        this.showOptions = filterOptions.slice(0, length);
      }
    },
    handleFocus() {
      this.filterMethod('');
      this.$emit('focus');
    },
    handleBlur() {
      this.$emit('blur');
    },

    open() {
      if (this.filter?.use_split_page) {
        this.$set(this.filter, '$page_size', this.filter?.page_size || this.size);
        this.$set(this.filter, '$page', this.filter?.page || 1);
        this.$set(this.filter, 'queryOptions', []);
        this.$set(this.filter, 'options', []);
        this.$set(this.filter, '$isLoaded', false);
        this.$set(this.filter, '$loading', false);
        this.getMore();
      }
    },

    handleVisibleChange(visible) {
      if (visible) {
        this.open();
      }
    },
    setSearchToFilter(filter) {
      let searchGqlStr = '';
      if (this.search) {
        searchGqlStr = searchGql(this.searchText, this.search.fields, this.filter) || undefined;
      }
      if (searchGqlStr) {
        if (!filter.wheres) {
          filter.wheres = [];
        }
        const { wheres } = filter;
        // 注入搜索查询条件
        let whereMainFields = wheres.find((item) => item.object === filter.object || !item.object);
        if (!whereMainFields) {
          whereMainFields = { fields: [] };
          wheres.push(whereMainFields);
        }
        whereMainFields.fields.push({ where_gql: searchGqlStr });
      }
      return filter;
    },
    handleChangeText() {
      this.needfocus = true;
      if (this.filter.use_split_page) {
        this.debounceFilterMethod(this.inputText);
      } else {
        this.filterMethod(this.inputText);
      }
    },
  },
};
