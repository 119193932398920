//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Action from '../../action';
import MButton from '../button/index.vue';
import { execExpression } from '../../../../layout/expression';
import RemoteCustomComponent from '../../remote-custom-component';
export default {
  components: { MButton, RemoteCustomComponent },
  mixins: [Action],
  props: {
    action: {
      type: Object,
      default: () => ({}),
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formValue: {
      type: Object,
      default: () => ({}),
    },
    json: {
      type: Object,
      default: () => ({}),
    },
    targetId: {
      type: String,
      default() {
        return '';
      },
    },
    remoteUtils: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    execExpression,
    getPopupActions(popup = {}) {
      if (popup.actions) {
        return popup.actions.filter((action) => {
          return !execExpression(action.hidden, this.expParamsObj);
        });
      }
      return [];
    },
    visibleChange(e) {
      this.visible = e;
    },
    handleDropDownClick(refKey) {
      this.$refs[refKey]?.[0]?.onClick();
    },
  },
};
