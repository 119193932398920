/* eslint-disable prettier/prettier */
import { getCurrentPagesInfo } from '../web-mobile/util';
import { debounce } from 'lodash';
const debounceLog = debounce((count) => {console.log(`current layout page did console log ${count} times , please check`)}, 4000);

const consoleConf = {
  // 按照页面来开关日志，一旦关闭不允许被打开，除非刷新
  pageLogAble: {},
  pageLogCount: {},
  isEnabled: true,
  // 默认能输出日志
  originalConsoleLog(...rest) {
    console.log(...rest);
    // 页面 log 超出 50 时作出提示
    const pageObj = getCurrentPagesInfo();
    const page = pageObj?.[(pageObj?.length || 1) - 1]?.$page?.fullPath || pageObj?.href;
    this.pageLogCount[page] = this.pageLogCount[page] ? this.pageLogCount[page] + 1 : 1;
    if (this.pageLogCount[page] > 50) { debounceLog(this.pageLogCount[page]);}
  },
  disable() {
    try {
      if (this.isEnabled) {
        this.isEnabled = false;
        const pageObj = getCurrentPagesInfo();
        const page = pageObj?.[0]?.$page?.fullPath || pageObj?.href;
        this.pageLogAble[page] = this.isEnabled;
        this.originalConsoleLog = function () {};
        console.log('expression log has been disabled');
      }
    } catch (err) {
      console.error(err);
      this.originalConsoleLog = console.log;
    }
  },
  enable() {
    try {
      if (!this.isEnabled) {
        const pageObj = getCurrentPagesInfo();
        const page = pageObj?.[0]?.$page?.fullPath || pageObj?.href;
        // 一旦页面被关闭日志，只能通过刷新恢复
        if (this.pageLogAble[page] === false) {
          console.log('current page can not log until reload mp');
          return;
        }
        this.originalConsoleLog = console.log;
        this.isEnabled = true;
        console.log('expression log has been enabled');
      }
    } catch (err) {
      console.error(err);
      this.originalConsoleLog = console.log;
    }
  },
};

export { consoleConf };
