//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Action, { execExpressionAction } from '../index';
import { selectConfirmDialog } from './util';
import { execExpression } from 'nges-common/src/layout/expression';
import subAlert from '../../alert';
import { GetObjectsByNames } from 'nges-common/src/api';
import { getObjects } from 'nges-common/src/layout/util';
import _ from 'lodash';
import Attachment from './upload.vue';

export default {
  components: {
    subAlert,
    Attachment,
  },
  mixins: [Action],
  data() {
    return {
      layout: null,
      visible: false,
      form: {
        top_text: '',
        select: '',
        checkList: [],
        text: '',
        channel: [],
        fileList: [],
      },
      title: '',
      titleIcon: '',
      formItem: [],
      alertJson: {},
      actions: [],
      initAction: {},
      expParamsObj: {},
      console: window.console,
      textareaShow: false,
      rules: {},
      realRules: {},
    };
  },
  computed: {
    hasFile() {
      return !!this.formItem.find((i) => i.type === 'attachment');
    },
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        console.log('dataProperty changed状态', newValue, oldValue, this.initAction);
        const textarea = this.findSelectConfirmAction(
          _.cloneDeep(this.initAction),
          'type',
          'textarea',
        );
        const expParamsObj = {
          ...this.expParamsObj,
          t: {
            value: this.form,
            p: this.expParamsObj?.t,
          },
        };
        if (!textarea) return;
        textarea.show = textarea.show.show;
        const res = execExpressionAction(textarea, expParamsObj);
        if (res?.show) {
          this.textareaShow = true;
          this.realRules = {
            ...this.rules,
            text: textarea?.rules || [],
          };
        } else {
          this.textareaShow = false;
          this.realRules = { ...this.rules, text: [] };
          this.$refs.form?.clearValidate?.('text');
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    selectConfirmDialog.open = this.open;
  },
  methods: {
    selectChange(data) {
      console.log(data);
    },
    handleClose() {
      this.visible = false;
    },
    async open(action, expParamsObj, act, emitCallback) {
      const { action_params: actionParams, actions } = action;
      const { title, title_icon: titleIcon, formItem, alert } = actionParams;
      // select选项
      const channel = formItem.find((i) => i.type === 'select');
      // 获取选项select现象
      if (channel?.filter?.object) {
        const res = await GetObjectsByNames({
          objects: getObjects(channel?.filter),
        });
        const list = res.list[0].fields;

        channel.options = list.map((item) => {
          return {
            label: item.display_name,
            value: item.name,
          };
        });
      } else if (channel?.filter?.options?.length > 0) {
        channel.options = channel.filter.options;
      } else if (channel?.filter?.field) {
        channel.options = actionParams[channel.filter.field].map((item) => {
          return {
            label: item[channel.filter.label],
            value: item[channel.filter.value],
          };
        });
      }
      this.title = title;
      this.titleIcon = titleIcon;
      this.formItem = formItem;
      this.alertJson = alert;
      this.actions = actions;
      this.form = {
        top_text: formItem.find((i) => i.type === 'text')?.defaultValue || '',
        select: formItem.find((i) => i.type === 'radio')?.defaultValue || '',
        checkList: formItem.find((i) => i.type === 'checkbox')?.defaultValue || [],
        text: formItem.find((i) => i.type === 'textarea')?.defaultValue || '',
        channel: channel?.defaultValue || [],
        fileList: formItem.find((i) => i.type === 'attachment')?.defaultValue || [],
      };
      const textareaConfig = this.formItem.find((i) => i.type === 'textarea');
      this.rules = {
        top_text: this.formItem.find((i) => i.type === 'text')?.rules || [],
        text:
          textareaConfig?.show === false
            ? []
            : this.formItem.find((i) => i.type === 'textarea')?.rules,
      };
      this.initAction = act;
      this.expParamsObj = expParamsObj;
      this.visible = true;
      this.emitCallback = emitCallback;
    },
    execExpression(exp) {
      return execExpression(_.cloneDeep(exp), {
        ...this.expParamsObj,
        t: {
          value: this.form,
          p: this.expParamsObj?.t,
        },
      });
    },
    handleAction(action) {
      if (action?.action?.toLowerCase() === 'cancel') {
        this.visible = false;
        return;
      }
      this.rules = _.cloneDeep(this.realRules);

      this.$nextTick(() => {
        // 单选校验
        const radioItemRequired = this.formItem
          .find((i) => i.type === 'radio')
          ?.rules?.find((i) => i.required);
        if (!this.form.select && radioItemRequired) {
          this.$message.error(
            radioItemRequired?.errorMessage || radioItemRequired?.message || '请选择选项',
          );
          return;
        }

        // 多选校验
        const checkboxItemRequired = this.formItem
          .find((i) => i.type === 'checkbox')
          ?.rules?.find((i) => i.required);
        if (this.form.checkList.length === 0 && checkboxItemRequired) {
          this.$message.error(
            checkboxItemRequired?.errorMessage || checkboxItemRequired?.message || '请选择至少一项',
          );
          return;
        }

        // select校验
        const selectItemRequired = this.formItem
          .find((i) => i.type === 'select')
          ?.rules?.find((i) => i.required);
        if (this.form.channel.length === 0 && selectItemRequired) {
          this.$message.error(
            selectItemRequired?.errorMessage || selectItemRequired?.message || '请选择至少一项',
          );
          return;
        }

        setTimeout(() => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.visible = false;
              this.dataProcess(action);
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        });
      });
    },
    dataProcess(action) {
      const expParamsObj = {
        ...this.expParamsObj,
        t: {
          value: { ...this.form, fileList: JSON.stringify(this.form.fileList) },
          p: this.expParamsObj?.t,
        },
      };
      console.log('expParamsObj', expParamsObj);
      const { action_callback: actionCallback, actions } = this.initAction;
      const myActions = actions || actionCallback?.actions;
      const currentAction = myActions.find(
        (i) => i.SELECT_CONFIRM_ACTION_ID === action.SELECT_CONFIRM_ACTION_ID,
      );
      currentAction.SELECT_CONFIRM_ACTION_ID = undefined;
      const _this = this.emitCallback();
      _this.m_actionHandle(currentAction, false, expParamsObj);
    },
    handleFileChange(fileList) {
      this.form.fileList = fileList;
    },
  },
};
