import { globalConfigDialog } from './confirm-dialog/util';

import { debounce } from 'lodash';

const showConfirm = debounce((alertFunc, actionName, actionParams, resolve) => {
  if (actionName === 'CONFIRM') {
    if (actionParams?.disable_time) {
      const open = globalConfigDialog.open[globalConfigDialog.open.length - 1];
      if (open) {
        open({
          ...actionParams,
          handleClose: () => resolve(false),
          handleConfirm: () => resolve(true),
        });
        return;
      }
    }
    alertFunc?.(actionParams?.content || ' ', actionParams?.title || '系统提示', {
      confirmButtonText: actionParams?.confirm_text || '确定',
      cancelButtonText: actionParams?.cancel_text || '取消',
      type: actionParams?.type || 'info',
      customClass: 'confirm-action-dialog',
      dangerouslyUseHTMLString: actionParams?.use_html_string || false,
    })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  } else {
    alertFunc?.(actionParams?.content || ' ', actionParams?.title || '系统提示', {
      confirmButtonText: actionParams?.confirm_text || '确定',
      type: actionParams?.type || 'info',
      customClass: 'confirm-action-dialog',
      callback: () => {
        resolve(true);
      },
    });
  }
}, 200);

function confirmAction(action) {
  return new Promise((resolve) => {
    const { action_params: actionParams, action: actionName } = action;
    const alertFunc = actionName === 'CONFIRM' ? this.$confirm : this.$alert;
    showConfirm(alertFunc, actionName, actionParams, resolve);
  });
}

export { confirmAction };
