import { isPlainObject, isArray, set, forEach, map, isEmpty } from 'lodash';
// 对象数据转GraqhQl结构时，若是关联对象，需加_values key
const formatGqlValues = (fields) => {
  const newFields = {};
  forEach(fields, (item, key) => {
    if (isPlainObject(item)) {
      const newItem = formatGqlValues(item);
      set(newFields, `${key}._values`, newItem);
    } else if (isArray(item)) {
      // 如果是数组类型,且第一个字段不是对象，则认为是其他类型
      if (item?.[0] !== undefined && !isPlainObject(item[0])) {
        newFields[key] = item;
      } else {
        set(
          newFields,
          `${key}._values`,
          item.map((subItem) => formatGqlValues(subItem)),
        );
      }
    } else {
      newFields[key] = item;
    }
  });
  return newFields;
};
/**
 * 格式化json配置subFileds
 * 将"subFields": { "dcr_field": "{{ 1 }}" } 换砖处理成 '{dcr_field(_values: 1)}'
 * {
 "action": "GRAPHQL_DCR_CREATE",
 "label": "保存",
 "action_params": {
 "object": "dcr",
 "fields": {
 "a": "{{ 1 }}",
 },
 "subFields": {
 "dcr_field": "{{ 1 }}"
 }
 },
 "action_callback": {
 "action": "RELOAD"
 }
 }
 */
const formatSubFields = (subFields = {}, level = 1) => {
  if (isEmpty(subFields)) {
    return '';
  }
  const subFieldArr = map(subFields, (value, key) => {
    if (isArray(value) || isPlainObject(value)) {
      const subFieldStr = JSON.stringify(value, null, '');
      const indent = '  '.repeat(level);
      return `${indent}${key}(_values: ${subFieldStr})`;
    }
    const indent = '  '.repeat(level);
    return `${indent}${key}(_values: "${value}")`;
  });
  const subFieldStr = subFieldArr.join('');
  return (subFieldStr ? `{${subFieldStr}${'  '.repeat(level - 1)}}` : '').replaceAll(
    /"(\w+)"(\s*:\s*)/g,
    '$1$2',
  );
};
// 将单条对象数据转成GraphQl数据
const toMutationGql = ({ actionName, object, id, fields, subFields, action }) => {
  const newFields = formatGqlValues(fields);
  const subFieldsFormated = formatSubFields(subFields);
  const jsonStr = JSON.stringify(newFields) || '';
  const setFields = jsonStr.replaceAll(/"(\w+)"(\s*:\s*)/g, '$1$2');
  let gql = ``;
  if (actionName === 'GRAPHQL_DCR_CREATE') {
    gql = `mutation insert {${object}(_values: ${setFields}) ${subFieldsFormated}}`;
  } else if (actionName === 'GRAPHQL_DCR_UPDATE') {
    gql = `mutation update {${object}(_where: {id: "${id}"}, _set: ${setFields}) ${subFieldsFormated}}`;
  } else if (action === 'update') {
    gql = `mutation update {${object}(_where: {id: "${id}"}, _set: ${setFields})}`;
  } else if (action === 'insert') {
    gql = `mutation insert {${object}(_values: ${setFields})}`;
  } else if (action === 'delete') {
    gql = `mutation delete {${object}(_where: {id: "${id}"})}`;
  }
  return gql;
};

export { toMutationGql };
