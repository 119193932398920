// import report from './report';
import { customLogin, getLoginInfo } from './login';
import { replaceParams, throttle, isWxworkMiniProgramH5, getJWeixin } from './util';
import { GetTenantObjectsPermission, GetUserMenuTree, GetAppConfig } from './api';
import { HTTP_REG } from './constant';
import { getLayoutJson } from './layout/util';
import config from './config';
import { cloneDeep } from 'lodash';
import aegis from 'nges-common/src/aegis';

// 跳转路径判断
function transOptions(inOptions) {
  const options = { ...inOptions };
  if (HTTP_REG.test(options.wxaurl)) {
    options.h5url = options.wxaurl;
    options.wxaurl = '';
  }
  const replaceOptions = {
    // adtag: report.getAdtag(),
  };
  // 小程序webview，默认透传token
  
  if (options.wxaurl) {
    
    // 若是直接打开小程序，则不需要appid参数
    if (options.open_type !== 'mp') {
      replaceOptions.appid = customLogin.getAppid();
    }
    
    options.wxaurl = replaceParams(replaceOptions, options.wxaurl);
  }
  if (options.h5url) {
    options.h5url = replaceParams(replaceOptions, options.h5url);
  }
  return options;
}
const gotoUrlHandle = (inOptions) => {
  aegis.report({
    msg: 'gotoUrlThrottle',
    level: 'log',
    ext1: `inOptions: ${JSON.stringify(inOptions)}`,
  });
  const options = transOptions(inOptions);
  const force = options.force || false;
  if (options.appid) {
    const appId = options.appid;
    const miniOptions = {
      appId,
      path: options.wxaurl,
      extraData: options.extraData,
    };
    console.log('miniOptions', miniOptions);
    uni.navigateToMiniProgram(miniOptions);
  } else if (options.wxaurl) {
    const url = options.wxaurl;
    if (options?.native) {
      force ? location.replace(url) : (location.href = url);
      return;
    }
    // 如果H5模式下，要求打开的是小程序页面，则使用小程序的打开方式
    if (options?.open_type === 'mp') {
      toMpPage(
        {
          url,
          ...options.opts,
        },
        force,
      );
      return;
    }
    
    
    toPage(
      {
        url,
        ...options.opts,
      },
      force,
    );
  } else if (options.h5url) {
    
    force ? location.replace(options.h5url) : (location.href = options.h5url);
    
  }
};

const gotoUrlThrottle = throttle(gotoUrlHandle, 200, { trailing: false });
/*
 * 跳转其他小程序/小程序页面/H5
 * options {wxaurl,appid,h5url}
 */
const gotoUrl = function (options, immediately) {
  console.log('gotoUrl', options);
  if (immediately) {
    gotoUrlHandle(options);
  } else {
    gotoUrlThrottle(options);
  }
};

/**
 * 跳转新的web-view
 */
function toWebView({ url = '', title = '', ...rest }, force = false) {
  url = encodeURIComponent(url);
  toPage(
    {
      ...rest,
      url: `/pages/main/webview/webview?shareUrl=${url}&title=${title}`,
    },
    force,
  );
}
/**
 * 跳转小程序内页面
 */
function toPage(options, force = false, reTryFlag = false) {
  
  if (force === 'reLaunch') {
    uni.reLaunch(options);
  } else if (force) {
    console.log('redirectTo', options);
    uni.redirectTo(options);
  } else {
    console.log('navigateTo', options);
    uni.navigateTo(options);
  }
}

async function toMpPage(options, force = false) {
  
  // eslint-disable-next-line no-undef
  let jwmp;
  if (isWxworkMiniProgramH5()) {
    if (!window.wx?.miniProgram) {
      await getJWeixin();
    }
    jwmp = window.wx?.miniProgram;
  } else {
    if (!window.jWeixin?.miniProgram) {
      await getJWeixin();
    }
    jwmp = window.jWeixin?.miniProgram;
  }
  if (force === 'reLaunch') {
    jwmp.reLaunch(options);
  } else if (force) {
    jwmp.redirectTo(options);
  } else {
    jwmp.navigateTo(options);
  }
  
}

function getPermissions() {
  return Promise.all([GetTenantObjectsPermission()]).then((vals) => {
    const permissions = [];
    vals.forEach((list) => {
      permissions.push(...list);
    });
    return permissions;
  });
}

function getPathJson(key) {
  const payload = {};
  return GetUserMenuTree(payload).then((res) => {
    if (!key) return Promise.reject(new Error('no key'));
    const list = res?.data;
    return getLayoutJson(list, key);
  });
}

function getAppid() {
  return customLogin.getAppid();
}

function getWebviewUrl(url) {
  return url.replace(config.prodDomain, config.domain);
}

export function getCurrentPageParams() {
  const pages = getCurrentPages();
  const currentPage = pages[pages.length - 1];
  const { options } = currentPage;
  return options;
}

export async function getPageConfig(key, type) {
  const appConfig = await GetAppConfig();
  const valueStr = appConfig?.page_config?.[key];
  if (type !== 'array' && type !== 'object') {
    return valueStr;
  }
  try {
    return JSON.parse(valueStr);
  } catch (e) {
    return type === 'array' ? [] : {};
  }
}

export async function getHomePath() {
  if(getLoginInfo('type') === 'doctor') {
    return await getPageConfig('doctor_home_path') || await getPageConfig('home_path');
  } else if(getLoginInfo('staff') === 'staff') {
    return await getPageConfig('staff_home_path') || await getPageConfig('home_path');
  } else {
    return await getPageConfig('home_path');
  }
}

export { gotoUrl, getPermissions, getPathJson, getAppid, getWebviewUrl };
