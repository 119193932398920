//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetObjectsByNames } from '../../../../utils/api.js';
import {
  getObjects,
  // transItem,
  getFieldLabel,
  getReleationObject,
  getFieldValue,
  fieldClass,
  transObjectValue,
  attachmentFieldIsPrivate,
} from '../../../../../layout/util';
import { getLayoutJson } from '../../../../../layout/queryWeb';
import { getDetailField, queryById, cmdQuery } from '../../../../utils/detail';
import { isExpression, execExpression, execExpressionDeep } from '../../../../../layout/expression';
import { initDcrInfoFromQuery } from '../../../../../layout/dcr';

import {
  cloneDeep,
  isObject,
  merge,
  get,
  isArray,
  isEmpty,
  isNumber,
  debounce,
  isString,
} from 'lodash';

import LayoutList from '../../full-list';
import LayoutAttachmentDetail from './attachment';
import Action from '../../../action';
import Premission from '../../../permission';
import ApprovalAction from '../../../action/approval-action';
import RelationLink from './relation-link';
import ApprovalCenter from './approval-center/index.vue';
import { mapState } from 'vuex';
import { MINE_MAP } from '../../../../../constant';
import previewFile from '../../../preview-file';
import { APPROVAL_ACTIONS } from '../../../action/constant';
import NoPermission from '../../no-permission';
import mButton from '../../button';
import validateItem from './validate-item';
import subAlert from '../../../alert';
import miniDashboardCard from '../../../mini-dashboard-card';
import lockRecord from '../../../lock-record/index.vue';
import Teleport from './teleport.vue';
import tooltipDetail from '../../tooltip-detail';
import RemoteCustomComponent from '../../../remote-custom-component';
import { checkDataRowWritePermission } from '../../../../../layout/writePermission';
import { watermarkUtil } from 'nges-common/src/web/components/water-mark/util';
import { getLayoutKey } from 'nges-common/src/layout/queryUtil';
import MProgress from '../../../progress';
import { requestArrange } from '../../../../../layout/requestArrange';
import MStateTag from '../../../state-tag';
import MIframe from '../../../../../web-mobile/components/iframe';
import GroupDetail from './group-detail';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';
import MMoreButton from '../../more-button';
import { reportEnd } from 'nges-common/src/web/utils/aegis';
import MAsyncImage from '../../async-image';
import { fieldLabel } from 'nges-common/src/layout/util';
import { DataService } from 'nges-common/src/api';
import TrackingLayoutViewMixin from 'nges-common/src/tracking/mixins/layout-view-mixin';

const ACTION_APPLY = 'APPLY';
const ACTION_APPEAL = 'APPEAL';
const QUERY_TAB_INDEX_KEY = 'tab_index';
const QUERY_TAB_KEY = 'tab_key';
// 默认单列自适应最小宽度
const DEFAULT_WIDTH = 356;
// 默认自适应要减去的padding宽度
const DEFAULT_PADDING_WIDTH = 20;
export default {
  name: 'LayoutDetailContent',
  components: {
    'layout-list': LayoutList,
    'layout-attachment-detail': LayoutAttachmentDetail,
    RelationLink,
    previewFile,
    ApprovalCenter,
    NoPermission,
    mButton,
    validateItem,
    subAlert,
    miniDashboardCard,
    lockRecord,
    tooltipDetail,
    RemoteCustomComponent,
    MProgress,
    Teleport,
    MStateTag,
    MIframe,
    GroupDetail,
    MMoreButton,
    MAsyncImage,
  },
  filters: {},
  mixins: [Action, Premission, ApprovalAction, TrackingCompMixin, TrackingLayoutViewMixin],
  props: {
    json: {
      type: Object,
      default() {
        return null;
      },
    },
    targetId: {
      type: String,
      default() {
        return '';
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    showType: {
      type: String,
      default: '',
    },
    inner: {
      type: Boolean,
      default: false,
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
    parentData: {
      type: Object,
      default() {
        return {};
      },
    },
    hideLoading: {
      type: Boolean,
      default: false,
    },
    // 是否用 params 参数渲染详情布局组件
    useParamsRender: {
      type: Boolean,
      default: false,
    },
    // 是否处于detail布局的tab内
    inDetailTab: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [],
      fieldGroups: [],
      formValue: {}, // 针对field返回的结果数据。
      detailValue: {}, // 查询结果返回的详细数据。
      objects: [],
      detailListCountMap: {},
      lock: false,
      formLows: [],
      loading: null,
      subTabCurrent: '0',
      subTabInited: false,
      activeName: 'first',
      imgExtension: MINE_MAP.image,
      dialogUrl: '',
      preFile: false,
      preType: '',
      mountApprovalCenter: false, // 是否挂载审批中心自定义组件
      APPROVAL_ACTIONS,
      reloadFlag: false,
      hasPermission: true,
      // 详情校验状态
      detailValidateResult: '',
      // dcr 页面需要用到 dcr信息的页面，将查询的 dcr 信息注入到 json
      dcrInfo: {},
      // 布局hook请求查询数据
      hookRequestData: {},
      tabShowList: {},
      fieldSpan: {},
      fieldSort: {},
      actions: [],
      originalActions: [],
      allButtonsWidth: 0,
      fixedMarginTop: 0,
      fixedScrollTop: null,
      boxClass: `${Math.random()}`.slice(2),
      tabJson: {},
      tabKey: '',
      tabIndex: null,
    };
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
    buttonsTeleport() {
      return this.json?.render?.actions_teleport;
    },
    buttons() {
      const actions = this.actions || [];
      return this.getActions(actions);
    },
    tabs() {
      const tabs = cloneDeep(this.json?.render?.tabs);
      return (tabs || [])
        .filter(({ hidden }) => !this.execExpression(hidden))
        .map((item) => {
          const { layout_params: layoutParams, json, layout_type: layoutType = 'detail' } = item;
          let layoutJson = json;
          if (layoutParams) {
            const { object, id } = this.execExpression(layoutParams);
            if (object) {
              layoutJson = merge(
                getLayoutJson(this.menuList, `${object}_${layoutType}`) || {},
                layoutJson,
              );
            }
            item.id = id;
          }
          item.json = layoutJson;
          return { ...item, name: this.execExpression(item.label) };
        });
    },
    expParamsObj() {
      return {
        t: {
          id: this.targetId,
          $validate: this.detailValidateResult,
          ...this.formValue,
          dcrInfo: this.dcrInfo,
        },
        p: {
          ...this.params,
        },
        json: this.json,
        $hook: {
          ...this.hookRequestData,
        },
      };
    },
    paramsObj() {
      return {
        t: {
          id: this.targetId,
          p: this.parentData,
          dcrInfo: this.dcrInfo,
        },
        p: {
          ...this.params,
        },
        $hook: {
          ...this.hookRequestData,
        },
        json: this.json,
      };
    },
    inContainer() {
      return Boolean(this.showType);
    },
    miniDashboardCard() {
      return this.json?.render?.mini_dashboard_card;
    },
    alertJson() {
      return this.json?.render?.alert;
    },
    mainSubTitleSticky() {
      console.log('---this.alertJson.sticky;---', this.alertJson.sticky);
      return this.alertJson?.sticky;
    },
    contentTitle() {
      if (isExpression(this.json?.title)) {
        return this.execExpression(this.json?.title);
      }
      return this.json?.title || '';
    },
    lockRecordConfig() {
      return {
        isShow: this.json?.render?.lock?.enable_lock || false,
        text: this.json?.render?.lock?.button_text,
      };
    },
    surroundComp() {
      return this.json?.render?.surround_components || {};
    },
    contentSurroundComp() {
      return this.json?.render?.content_surround_components || {};
    },
    tabSurroundComp() {
      return this.json?.render?.tab_surround_components || {};
    },
    remoteUtils() {
      return {
        detailLayout: {
          reload: this.reload,
          dataService: DataService,
        },
      };
    },
    tabFixed() {
      // tabFixed变量默认为true，通过render.tab_fixed可以设置为false
      let tabFixed = true;
      if (this.json?.render?.tab_fixed !== undefined) {
        tabFixed = this.json?.render?.tab_fixed;
      }
      return tabFixed && !this.inDetailTab;
    },
    topButtonAutoShow() {
      // 顶部按钮自适应页面宽度展示，默认为true打开
      const autoShow = this?.json?.render?.actions_config?.auto_show;
      return autoShow === undefined ? true : autoShow;
    },
    labelWidth() {
      return this.json?.render?.label_width;
    },
    topLabelWidth() {
      return this.json?.render?.label_width ?? '100%';
    },
    trackingConfig() {
      const { json } = this;
      return {
        trigger: 'leave',
        params: {
          event_type: 'view',
          event_id: 'detailObj_Pv',
          event_object: json?.object,
          event_label: json?.title?.text || json?.title,
          event_data_id: this.targetId,
        },
        config: execExpressionDeep(cloneDeep(json?.tracking), this.expParamsObj),
      };
    },
  },
  watch: {
    json: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          // 使用this.actions接收json.render.actions,后续的按钮操作都通过this.actions来处理
          const actions = newValue?.render?.actions;
          if (actions) {
            this.actions = actions;
            this.originalActions = actions;
          }
          // immediate第一次时oldValue为undefined不执行init
          if (oldValue) {
            this.init();
          }
        }
      },
      immediate: true,
    },
    targetId() {
      this.init();
    },
    expParamsObj: {
      handler() {
        // expParamsObj变化时，重新计算按钮显示逻辑
        this.$nextTick(() => {
          if (!this.inDetailTab && this.topButtonAutoShow) {
            this?.debounceHandleUpdateButtons?.('original');
          }
        });
      },
      deep: true,
    },
  },
  created() {
    if (!this.inDetailTab && this.topButtonAutoShow) {
      this.debounceHandleUpdateButtons = debounce(this.handleUpdateButtons, 100);
      window.addEventListener('resize', this.debounceHandleUpdateButtons);
    }
  },
  async mounted() {
    this.debounceHandleUpdateGroupList = debounce(this.handleUpdateGroupList, 100);
    window.addEventListener('resize', this.debounceHandleUpdateGroupList);
    this.boxDom = document.querySelector(`.box${this.boxClass}`);
    if (this.boxDom) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.handleUpdateGroupList();
          } else {
            console.log(`${this.boxClass} is out of view`);
          }
        });
      });
      this.observer.observe(this.boxDom);
    }
    if (this.tabFixed) {
      document
        .querySelector('.container-body main')
        ?.addEventListener('scroll', this.handleTabFixed);
    }
    this.$nextTick(() => {
      reportEnd('pagestart');
    });
    this.init();
    this.__trkEnter();
    this.__trkAddListener();
  },
  beforeDestroy() {
    this.__trkLeave();
    this.__trkRemoveListener();
    window.removeEventListener('resize', this.debounceHandleUpdateGroupList);
    if (this.boxDom) {
      this.observer.unobserve(this.boxDom);
      this.observer = null;
      this.boxDom = null;
    }
    if (this.tabFixed) {
      document
        .querySelector('.container-body main')
        ?.removeEventListener('scroll', this.handleTabFixed);
    }
    if (!this.inDetailTab && this.topButtonAutoShow) {
      window.removeEventListener('resize', this.debounceHandleUpdateButtons);
    }
  },
  methods: {
    get,
    isNumber,
    reload() {
      this.getDetail();
    },
    openLoading() {
      if (!this.loading) {
        if (!this.hideLoading) {
          this.loading = this.$loading({
            target: this.inContainer ? this.$refs['detail-wrap'] : undefined,
          });
        }
      }
    },
    closeLoading() {
      this.loading && this.loading.close();
      this.loading = null;
    },
    async init() {
      // 预加载请求编排-初始化后请求
      this.hookRequestData = {
        ...this.hookRequestData,
        ...(await requestArrange(
          get(this.json, 'hook_request.inited'),
          this.expParamsObj,
          this.openLoading,
          this.closeLoading,
        )),
      };

      this.formValue = {};
      this.fieldGroups = {};
      this.openLoading();
      // if (this.json?.title) {
      //   this.$emit('set-container-title', this.json.title);
      // }
      // 若带了object，则获取object对应的元数据配置数据
      if (this.json?.object) {
        await this.getObjects();
      }

      // 详情页需要根据 dcr 数据进行展示
      if (this.json?.dcr_json?.object) {
        const dcrInfo = await initDcrInfoFromQuery(this.json, this.expParamsObj);
        this.$emit('fetched-dcr-info', dcrInfo);
        this.dcrInfo = dcrInfo;
      }

      // 若带了cmd，则运行用cmd接口查询，并返回，或者通过处理得到一条数据
      // 带targetId或者带了where条件才允许查询对应的详情数据，且只取第一条数据
      if (this.targetId || this.json?.wheres || this.json?.cmd || this.useParamsRender) {
        await this.getDetail();
      } else {
        this.initFields();
      }
      this.closeLoading();
      this.$nextTick(() => {
        if (!this.inner) reportEnd('datastart');
        this.tabTracking();
      });
      // 初始化tab
      // this.tabJson = cloneDeep(this.json?.render?.tabs);
      const queryTabIndex = this.$route.query[QUERY_TAB_INDEX_KEY];
      const queryTabKey = this.$route.query[QUERY_TAB_KEY];
      if (queryTabKey) {
        this.tabIndex = String(this.tabs.findIndex((tab) => tab.tab_key === queryTabKey));
        if (Number(this.tabIndex) === -1) {
          this.tabIndex = '0';
        }
        this.tabKey = queryTabKey;
        this.tabShowList = { [this.tabIndex]: true };
        this.subTabCurrent = this.tabIndex;
      } else {
        // 如果没有tab_key参数,走原来的逻辑
        let tabIndex = '0';
        if (
          parseInt(queryTabIndex) == queryTabIndex &&
          this.tabs.length &&
          parseInt(queryTabIndex) <= this.tabs.length
        ) {
          tabIndex = String(parseInt(queryTabIndex) - 1);
          this.tabIndex = tabIndex;
          this.tabShowList = { [tabIndex]: true };
        }
        this.subTabCurrent = this.tabIndex;
      }
    },
    async queryService() {
      const json = {
        ...this.json,
        wheres: execExpressionDeep(this.json?.wheres, this.paramsObj),
        orders: execExpressionDeep(this.json?.orders, this.paramsObj),
        sub_query: execExpressionDeep(this.json?.sub_query, this.paramsObj),
        trigger_ext: execExpressionDeep(this.json?.trigger_ext, this.paramsObj),
      };
      let remoteValue = await queryById({ json, id: this.targetId });
      // 如果需要检查写权限，则查询权限数据并插入结果
      remoteValue = await checkDataRowWritePermission(
        this.json,
        remoteValue,
        remoteValue.id || this.targetId,
      );
      const { response = null } = this.json;
      if (response) {
        const newParamsObj = {
          t: {
            ...remoteValue,
            p: this.parentData,
          },
          p: this.params,
        };
        return execExpressionDeep(response, newParamsObj);
      }
      return remoteValue;
    },

    async cmdService() {
      const { cmd, payload, response = null } = this.json;
      const remoteValue = await cmdQuery({
        cmd,
        payload: execExpressionDeep(payload, this.paramsObj),
      });
      if (response) {
        const newParamsObj = {
          t: {
            ...remoteValue,
            p: this.parentData,
          },
          p: this.params,
          $hook: {
            ...this.hookRequestData,
          },
        };
        return execExpressionDeep(response, newParamsObj);
      }
      return remoteValue;
    },

    async getDetail() {
      try {
        if (this.lock) {
          return;
        }
        this.lock = true;
        // 预加载请求编排-前置请求
        this.hookRequestData = {
          ...this.hookRequestData,
          ...(await requestArrange(
            get(this.json, 'hook_request.before_fetch'),
            this.expParamsObj,
            this.openLoading,
            this.closeLoading,
          )),
        };

        let remoteValue;
        if (this.json?.static_data) {
          remoteValue = this.execExpression(cloneDeep(this.json.static_data));
        } else if (this.json?.cmd) {
          remoteValue = await this.cmdService();
        } else if (this.useParamsRender) {
          remoteValue = { ...this.params };
        } else if (this.json?.object) {
          remoteValue = await this.queryService();
        }
        this.hasPermission = !isEmpty(remoteValue);

        // const formValue = transItem({
        //   json: this.json,
        //   objects: this.objects,
        //   item: remoteValue,
        // });
        const formValue = transObjectValue({
          data: remoteValue,
          json: this.json,
          objects: this.objects,
        });
        this.$emit('fetched-data', formValue);
        // 注入父级数据，表达式可以取到
        if (formValue && this.parentData) {
          formValue.p = this.parentData;
        }
        this.formValue = { dcrInfo: this.dcrInfo, ...formValue };
        console.log('formvalue-----------=-=-=-=-==');
        console.log(this.formValue);
        // 预加载请求编排-后置请求
        this.hookRequestData = {
          ...this.hookRequestData,
          ...(await requestArrange(
            get(this.json, 'hook_request.after_fetch'),
            this.expParamsObj,
            this.openLoading,
            this.closeLoading,
          )),
        };

        this.lock = false;
        this.subTabCurrent = this.subTabCurrent || '0';
        this.subTabInited = false;
        this.reloadFlag = true; // 通过reload标志，触发整个界面重新渲染
        this.initFields();
        this.$nextTick(() => {
          this.subTabInited = true;
          this.tabShowList[this.subTabCurrent] = true;
          this.reloadFlag = false;

          // 重新渲染会销毁之前所有的计算结果 在重新渲染结束后再进行内容区内组件的初始化
          this.$nextTick(() => {
            this.$refs.dashboardCard?.init();
          });
        });
        watermarkUtil.getObjectWatermark({
          object: this.json?.object,
          type: 'detail',
          id: this.targetId,
          level: {
            containerLevel: this.inContainer || this.inner ? 1 : 2,
            parentLevel: this.inDetailTab ? 1 : 2,
          },
          recordType: getLayoutKey().recordType,
        });
      } catch (err) {
        console.error('getDetail err', err);
        this.hasPermission = false;
        this.lock = false;
      }
    },
    async getObjects() {
      try {
        const res = await GetObjectsByNames({
          objects: getObjects(this.json),
        });
        this.objects = res?.list || [];
      } catch (err) {
        console.error(err);
      }
    },
    getActions(actions) {
      if (actions && actions.length) {
        actions = actions
          .filter((action) => {
            return !this.execExpression(action.hidden);
          })
          .filter(this.mixinFilterPermission)
          .filter(this.mixinFilterWritePermission(this.formValue));
        actions = actions.map((action) => {
          const writeDisabled = this.mixinDisabledWritePermission(action, this.formValue);
          if (APPROVAL_ACTIONS.includes(action.action)) {
            return {
              ...action,
              approve_exec: this[`${action.action}_EXEC`](action.action_params),
              write_disabled: writeDisabled,
            };
          }
          return {
            ...action,
            approve_exec: true,
            write_disabled: writeDisabled,
          };
        });
        // 如果开启了自动收起的配置，自动将数据放入更多之中
        const actionsConfig = this.json?.render?.actions_config || {};
        if (actionsConfig.need_fold) {
          const moreActions = [];
          const primaryActions = [];
          const visibleNum = actionsConfig.visible_num || undefined;
          actions.forEach((item) => {
            // 如果按钮本身就有更多逻辑，则必须放在外边，其次如果按钮primary = true，则底部按钮还有空间时则加入
            // 底部按钮是否有空间判断依据：剩余空间超过两个或者更多列表为空时才显示
            // 新增: 如果设置了visible_num，则优先显示前visible_num个按钮,如果手动设置了更多按钮，则必会显示更多按钮
            if (visibleNum && visibleNum > 0) {
              if (primaryActions.length < visibleNum || item.popup) {
                primaryActions.push(item);
              } else {
                moreActions.push(item);
              }
            } else {
              if (item.popup || item.primary) {
                primaryActions.push(item);
              } else {
                moreActions.push(item);
              }
            }
          });
          if (moreActions.length > 0) {
            primaryActions.unshift({
              label: actionsConfig.label || '更多',
              type: actionsConfig.type || 'text',
              popup: {
                actions: moreActions,
              },
            });
          }
          actions = primaryActions;
        }
      }
      return actions;
    },
    initFields() {
      this.fieldGroups = getDetailField({
        json: this.json,
        objects: this.objects,
      });
      console.log(this.fieldGroups, 'fieldGroups');
      this.calcGroupData(this.fieldGroups);
      this.splitFieldToRow(this.fieldGroups);
    },
    execExpression(obj) {
      let params = obj;
      if (isObject(obj)) {
        params = cloneDeep(obj);
      }
      const result = execExpressionDeep(params, this.expParamsObj);
      return result;
    },
    getRelationLink(field) {
      const relationObject = getReleationObject({
        objects: this.objects,
        field,
        json: this.json,
      });
      if (!relationObject) {
        return [];
      }
      let result = [];
      if (field) {
        const idx = field.lastIndexOf('.');
        let objectData = {};
        let key = '';
        if (idx === -1) {
          objectData = this.formValue;
          key = field;
        } else {
          objectData = getFieldValue(this.formValue, field.substring(0, idx));
          key = field.substring(idx + 1);
        }
        if (isArray(objectData)) {
          result = objectData.map((item) => {
            return {
              value: item[key],
              object: relationObject,
              id: item.id,
            };
          });
        } else if (objectData) {
          const v = get(objectData, key);
          result = [
            {
              value: v,
              object: relationObject,
              id: objectData?.id,
            },
          ];
        }
      }
      return result;
    },
    getValue(field, value, itemData = {}) {
      let result;
      if (value) {
        result = this.execExpression(value) || '';
      } else if (field) {
        result = getFieldLabel(this.formValue, field);
      } else {
        result = '';
      }
      if (Array.isArray(result)) {
        const separator = itemData.separator || '；';
        result = result.join(separator) || '';
      }
      if (itemData.max_len && result.length > itemData.max_len) {
        result = `${result.substr(0, itemData.max_len)}...`;
      }
      // 针对0这个数值做处理，为0也显示
      if (result === 0) {
        return '0';
      }
      return result || '';
    },
    splitFieldToRow(groups) {
      this.fieldSort = {};
      groups.forEach((group) => {
        if (group.sub_groups?.length) {
          group.sub_groups.forEach((group) => {
            this.generateFormRows(group);
          });
        } else {
          this.generateFormRows(group);
        }
      });
      this.handleUpdateGroupList();
    },
    generateFormRows(group) {
      const fieldSort = {};
      const row = [];
      const { fields: rawFields } = group;
      const groupId = `${Math.random()}`.slice(2);
      group._groupId = groupId;
      fieldSort[groupId] = [];
      fieldSort[groupId].push([]);
      // 先判断是否展示再排布局
      const fields = (rawFields || []).filter((item) => !this.execExpression(item.hidden));
      // 如果配置了折叠 折叠关闭时折叠field不进行布局计算
      const hideFold = group.needFieldFold && !group.fieldFoldOpen;

      let colCount = group.col_count;
      if (colCount === void 0) {
        // 抽屉方式默认只能一行一项
        colCount = this.inContainer ? 1 : 2;
      }
      // 为null则取自身宽度
      group._span = colCount === null ? null : parseInt(24 / colCount, 10);
      let space = 24; // 当前行的剩余空间
      (fields || []).forEach((field) => {
        // 用来标志唯一值，给它生成一个唯一的表单id
        field._fieldId = field._fieldId || `${Math.random()}`.slice(2);
        // 折叠时折叠字段设为0宽度 > 配置的宽度 > 统一宽度 > 默认独占一行
        let colWidth;
        if (hideFold && field.fold) {
          colWidth = 0;
        } else if (field.col_width !== void 0) {
          // 为null则取自身宽度
          colWidth = field.col_width;
        } else if (group._span !== void 0) {
          // 为null则取自身宽度
          colWidth = group._span;
        } else {
          colWidth = 24;
        }
        this.fieldSpan[field._fieldId] = {
          originCol: colWidth,
          col: colWidth,
          groupId,
        };
        if (space >= colWidth) {
          if (row.length === 0) row.push([]);
          row[row.length - 1].push(field);
          // 折叠的列不应该参与自适应列计算
          if (colWidth !== 0) {
            fieldSort[groupId][fieldSort[groupId].length - 1].push(field._fieldId);
          }
          space -= colWidth;
        } else {
          row.push([field]);
          // 折叠的列不应该参与自适应列计算
          if (colWidth !== 0) {
            fieldSort[groupId].push([field._fieldId]);
          }
          space = 24 - colWidth;
        }
      });
      this.fieldSort = {
        ...this.fieldSort,
        ...fieldSort,
      };
      group._formRows = row;
      this.handleFieldSort();
    },
    handleFieldSort() {
      let newFieldSort = {};
      Object.keys(this.fieldSort).forEach((groupId) => {
        const rowFields = this.fieldSort[groupId];
        let newGroupId = groupId;
        let fieldSort = rowFields.reduce((pre, fields, index) => {
          pre[newGroupId] = pre[newGroupId] || [];
          const lastRow = rowFields[index - 1];
          let lastFieldSpan;
          if (lastRow?.length) {
            const lastField = lastRow[lastRow.length - 1];
            lastFieldSpan = this.fieldSpan?.[lastField]?.originCol;
          }
          // 单独一行的不参与自适应调整
          if (fields?.length === 1) {
            const currentFieldSpan = this.fieldSpan?.[fields[0]]?.originCol;
            // 排除是均分列的最后一行的第一列
            if (lastFieldSpan !== currentFieldSpan) {
              newGroupId = `${Math.random()}`.slice(2);
              pre[newGroupId] = [];
            } else {
              pre[newGroupId].push(fields);
            }
          } else {
            pre[newGroupId].push(fields);
          }
          return pre;
        }, {});
        fieldSort = Object.keys(fieldSort).reduce((pre, groupId) => {
          if (fieldSort[groupId]?.length) {
            pre[groupId] = fieldSort[groupId];
          }
          return pre;
        }, {});
        newFieldSort = {
          ...newFieldSort,
          ...fieldSort,
        };
      });
      const adaptive = {};
      Object.keys(newFieldSort).forEach((groupId) => {
        const rowFields = newFieldSort[groupId];
        const firstSpan = this.fieldSpan?.[rowFields?.[0]?.[0]]?.originCol;
        const isAdaptive
          = firstSpan !== void 0
          && rowFields.every((fields) => {
            return fields.every((field) => this.fieldSpan?.[field]?.originCol === firstSpan);
          });
        adaptive[groupId] = isAdaptive;
      });
      // 标志是否能够均分排列
      this.adaptive = adaptive;
      // 自适应分组表单
      this.fieldSort = newFieldSort;
    },
    handleUpdateGroupList() {
      const { fieldSort } = this;
      if (this.json?.render?.use_adaptive !== false) {
        Object.keys(fieldSort).forEach((groupId) => {
          const rowFields = fieldSort[groupId];
          if (this.adaptive[groupId]) {
            const rows = rowFields?.[0];
            // 为null的均分列不参与自适应调整
            if (this.fieldSpan?.[rows?.[0]]?.originCol === null) {
              return;
            }
            const firstFieldId = rowFields?.[0]?.[0];
            const firstSpan = this.fieldSpan?.[firstFieldId]?.originCol || 24;
            const rowsCol = firstSpan ? parseInt(24 / firstSpan, 10) : 1;
            const maxRows = [];
            for (let i = 0; i < rowsCol; i++) {
              maxRows.push(i);
            }
            const { maxSpan } = this.getMaxSpan({ rows: maxRows });
            rowFields.forEach((fields) => {
              fields.forEach((field) => {
                if (this.fieldSpan[field] !== maxSpan) {
                  this.fieldSpan[field].col = maxSpan;
                }
              });
            });
          } else {
            // 不均分的列在不满足最小宽度的情况下全部变成1列
            let maxLenRows = rowFields?.[0];
            rowFields.forEach((fields) => {
              if (fields.length > maxLenRows) {
                maxLenRows = fields;
              }
            });
            const { maxCol } = this.getMaxSpan({ rows: maxLenRows });
            if (maxCol <= maxLenRows.length) {
              rowFields.forEach((fields) => {
                fields.forEach((field) => {
                  this.fieldSpan[field].col = 24;
                });
              });
            }
          }
        });
        this.fieldSpan = { ...this.fieldSpan };
      }
    },
    toRelationLink(data) {
      this.m_actionHandle({
        action: 'TO_DETAIL',
        action_params: {
          object: data?.object,
          id: data?.id,
        },
      });
    },

    // actionHandle(params) {
    //   const act = this.execExpression(params.action, params.$index);
    //   if (act.url) {
    //     this.gotoUrl(act);
    //   } else {
    //     this.$emit('action-handle', {
    //       ...act,
    //       action_params: {
    //         ...act?.action_params,
    //         $index: params.$index,
    //         data: this.formValue,
    //       },
    //     });
    //   }
    // },
    // gotoUrl(action) {
    //   const toUrl = action?.url;
    //   this.g_tourl(toUrl);
    // },
    // buttonClickHandle(action) {
    //   this.actionHandle({ action });
    // },
    m_customAction(action) {
      // action内已经屏蔽了部分key后执行了一次 不需要再次执行
      // const newAction = this.execExpression(action);
      const newAction = action;
      if (newAction.action === 'RELOAD') {
        this.reload();
      }
      if (!newAction.action_params) {
        newAction.action_params = {};
      }
      if (newAction.action === ACTION_APPLY || newAction.action === ACTION_APPEAL) {
        newAction.action_params = {
          ...newAction.action_params,
          id: this.targetId,
          object_name: this.json.object,
        };
      }
      if (!newAction.action_params?._item) {
        newAction.action_params._item = this.formValue;
      }
      return newAction;
    },
    formatFileValue(value, type) {
      let returnValue = value;
      try {
        returnValue = JSON.parse(returnValue.replaceAll(`\\"`, `"`));
      } catch (e) {
        if (returnValue) {
          returnValue = returnValue.split(/,|;/g).map((item) => ({
            url: item,
          })); // 兼容一些老的数据
        } else {
          returnValue = [];
        }
      }
      returnValue = returnValue.map((item) => {
        if (type) {
          item.type = type;
        } else {
          item.type = /.*\.(.*)/.exec(item.name || item.url || '')
            ? /.*\.(.*)/.exec(item.name || item.url || '')[1].toLowerCase()
            : '';
        }
        return item;
      });
      return returnValue;
    },
    getTagStyle(field) {
      if (field.format_tag_type) {
        return execExpression(field.format_tag_type, {
          t: this.formValue,
          json: this.json,
          p: this.params,
          $hook: {
            ...this.hookRequestData,
          },
        });
      }
      let style = fieldClass(this.formValue, field.prop) || '';
      style = style.replace('is-', '');
      return style || '';
    },
    getStateIcon(field) {
      if (field.format_tag_icon) {
        return execExpression(field.format_tag_icon, {
          t: this.formValue,
          json: this.json,
          p: this.params,
          $hook: {
            ...this.hookRequestData,
          },
        });
      }
      return '';
    },
    // 拼接列表布局需要的json
    getTableJson(field) {
      const alias = this.json?.alias || {};
      // 新逻辑，如果有json配置，则直接使用json配置。
      if (field?.json) {
        const listJson = cloneDeep(field.json);
        const tableJson = listJson?.['list-layout'] || listJson;
        const key = field?.field || '';
        const fieldArr = key.split('.');
        const object = tableJson.object || fieldArr[fieldArr.length - 1];
        tableJson.alias = tableJson?.alias || alias;
        tableJson.object = alias[object] || object;
        return listJson;
      }
      const row = field.row || {};
      const key = row?.key || '';
      const keyArr = key.split('.');
      const object = row.object || keyArr[keyArr.length - 1];
      const fields = cloneDeep(row?.fields || []).map((item) => {
        return {
          ...item,
          filter: item.filter === undefined ? false : item.filter,
        };
      });
      return {
        ...field,
        ...row,
        actions: field.actions || [],
        object: alias[object] || object,
        alias,
        fields,
      };
    },

    // 拼接列表布局需要的数据
    getTableData(field) {
      if (field.format_value?.length) {
        return this.execExpression(field.format_value);
      }
      const row = field.row || {};
      const key = row?.key || field?.field;
      let data = get(this.formValue, key) || [];
      if (row?.filter !== undefined || row?.hidden !== undefined) {
        data = data.filter((item, index) => {
          const paramsObj = {
            t: {
              ...item,
              p: this.formValue,
            },
            p: this.params,
            $index: index,
            $hook: {
              ...this.hookRequestData,
            },
          };
          if (row?.filter !== undefined && !execExpressionDeep(row.filter, paramsObj)) {
            return false;
          }
          if (row?.hidden !== undefined && execExpressionDeep(row.hidden, paramsObj)) {
            return false;
          }
          return true;
        });
      }
      if (!Array.isArray(data)) {
        return [data];
      }
      return data;
    },
    // 判断是否私有桶附件
    isPrivateAttachment(itemData) {
      if (itemData?.text_option?.type === 'private_attachment') {
        return true;
      }
      const field = itemData?.field;
      if (field) {
        const { object } = this.json;
        const arr = field.split('.');
        let objectName = object;
        let fieldName = field;
        if (arr.length > 1) {
          objectName = arr[arr.length - 2];
          fieldName = arr[arr.length - 1];
        }
        return attachmentFieldIsPrivate({
          object: objectName,
          objects: this.objects,
          json: this.json,
          field: fieldName,
        });
      }
      return !!itemData.is_private;
    },
    calcGroupData(groups) {
      groups.forEach((group) => {
        if (group.title) {
          this.$set(group, 'title', this.execExpression(group.title));
        }
        if (group.subtitle) {
          this.$set(group, 'subtitle', this.execExpression(group.subtitle));
        }
        if (group.tags && group.tags.length) {
          const newGroupTags = (this.execExpression(group.tags) || []).filter((tag) => !tag.hidden);
          this.$set(group, 'tags', newGroupTags);
        }
        // 使用整组折叠
        if (group.fold) {
          this.$set(group, 'needFold', true);
          this.$set(group, 'foldOpen', !!group.fold.open);
        }

        const fieldFold = (group.fields || []).filter((i) => i.fold);
        // 使用分字段折叠
        if (fieldFold.length) {
          this.$set(group, 'needFieldFold', true);
          const openFold = fieldFold.map((i) => i?.fold?.open).filter((i) => !i);
          // 所有字段都为默认打开时才能默认打开
          this.$set(group, 'fieldFoldOpen', !openFold.length);
        }

        // 存在二级标题
        if (group.sub_groups?.length) {
          this.calcGroupData(group.sub_groups);
        }
      });
    },
    changeFieldFold(group) {
      group.fieldFoldOpen = !group.fieldFoldOpen;
      this.splitFieldToRow(this.fieldGroups);
    },
    // 挂载审批中心通用组件
    handleMountApprovalCenter() {
      this.mountApprovalCenter = true;
    },
    tabTracking() {
      const tabIdx = parseInt(this.subTabCurrent);
      const tabConf = this.tabs[tabIdx];
      if (!tabConf) return;
      this.__compTracking(
        {
          event_type: 'view',
          event_id: `click_Event`,
          event_name: `${this.execExpression(tabConf?.label) || ''}浏览`,
        },
        this.execExpression(tabConf?.tracking),
      );
    },
    tabClick(el) {
      if (el) this.tabShowList[el.name] = true;
      if (this.fixedScrollTop) {
        document.querySelector('.container-body main').scrollTop = this.fixedScrollTop;
      }
      this.$nextTick(() => {
        this.tabTracking();
      });
    },
    getRowsMinWidth({ rows }) {
      const defaultWidth = DEFAULT_WIDTH;
      const defaultPaddingWidth = DEFAULT_PADDING_WIDTH;
      const minWidth
        = rows.reduce((pre) => {
          const width = defaultWidth;
          pre += +width;
          return pre;
        }, 0) + defaultPaddingWidth;
      return minWidth;
    },
    getMaxSpan({ rows }) {
      let maxCol = rows.length;
      const defaultWidth = DEFAULT_WIDTH;
      const defaultPaddingWidth = DEFAULT_PADDING_WIDTH;
      const miniWidth = this.getRowsMinWidth({ rows });
      const rect = this.$refs?.box?.getBoundingClientRect();
      const width = rect?.width;
      if (width && miniWidth > width) {
        maxCol = parseInt((width - defaultPaddingWidth) / defaultWidth, 10);
      }
      return { maxSpan: !maxCol ? 24 : parseInt(24 / maxCol, 10), maxCol: !maxCol ? 1 : maxCol };
    },
    // 更新顶部按钮展示状态
    // mode为original时表示需要重新计算所有原始actions的显隐状态
    // expParamsObj对象变化时会传入mode=original
    handleUpdateButtons(mode = '') {
      const actionsConfig = this.json?.render?.actions_config || {};
      // 如果开启了actions_config的need_fold，则不自动收起按钮，actions_config的规则优先
      if (actionsConfig.need_fold) return;
      const buttonsBox = document.querySelector('.content-top > .content-actions');
      let allButtonsWidth = 0;
      const boxChildren = [...(buttonsBox?.children || [])];
      let marginPx = 0;
      if (boxChildren[1]) {
        // 获取按钮之间的间距
        const cssMap = getComputedStyle(boxChildren[1]);
        marginPx = parseInt(cssMap.getPropertyValue('margin-left'), 10);
      }

      boxChildren.forEach((item) => {
        allButtonsWidth += item.offsetWidth;
      });
      allButtonsWidth = allButtonsWidth + marginPx * (boxChildren.length - 1);
      // 过滤真正要显示的按钮
      // mode为original时，拿原始的actions对象重新过滤显示状态
      let actions = mode === 'original' ? this.originalActions : this.actions;
      actions = actions
        ?.filter?.((action) => {
          return !this.execExpression(action.hidden);
        })
        .filter(this.mixinFilterPermission)
        .filter(this.mixinFilterWritePermission(this.formValue));
      if (!actions || actions.length <= 0) return;
      if (allButtonsWidth > buttonsBox.offsetWidth) {
        if (!this.allButtonsWidth) {
          this.allButtonsWidth = allButtonsWidth;
        }
        const width = buttonsBox.offsetWidth;
        let widthSum = 0;
        let index = 0;
        // 是否已经有更多按钮
        const popupButton = actions?.find?.((item) => {
          return item.popup;
        });
        // 从左往右计算，满足按钮区间宽度的按钮应该是几个
        // 比如按钮总共6个，计算出的index为3，则只能显示前3个按钮，后3个按钮放入更多
        for (let i = 0; i < buttonsBox.children.length; i++) {
          widthSum += buttonsBox.children[i].offsetWidth + marginPx;
          if (widthSum > (popupButton ? width : width - 80)) {
            index = i;
            break;
          }
        }
        // 如果有更多按钮，则直接放入更多按钮的actions中
        // 注意：更多按钮按照现在规范是在放最前面
        if (popupButton) {
          popupButton?.popup?.actions?.push?.(...actions.splice(index));
        } else {
          // 如果没有更多按钮，则构造更多按钮
          const more = actions.splice(index);
          actions.unshift({
            label: '更多',
            type: 'text',
            popup: {
              actions: more,
            },
          });
        }
      } else if (this.allButtonsWidth <= buttonsBox.offsetWidth) {
        // 当按钮容器宽度大于所有按钮总宽度时，恢复初始状态
        if (JSON.stringify(this.originalActions) !== JSON.stringify(this.actions)) {
          actions = this.originalActions;
        }
      }
      this.actions = cloneDeep(actions);
      if (mode === 'original') {
        this.handleUpdateButtons();
      }
    },
    getFieldMore(fieldMore) {
      let result = [];
      if (fieldMore && fieldMore.length > 0) {
        result = fieldMore.filter(({ hidden }) => !this.execExpression(hidden));
        result = this.execExpression(result);
      }
      return result;
    },
    handleTabFixed() {
      if (this.tabs && this.tabs.length > 0) {
        const tabs = document.querySelector(
          '.detail-wrap .tab-wrap.notInDetail .el-tabs > .el-tabs__header',
        );
        const contentTopTitle = document.querySelector('.content-wrap .content-top.notInDetail');
        const width = document.querySelector('.detail-wrap')?.clientWidth;
        const tabContent = document.querySelector(
          '.detail-wrap .tab-wrap.notInDetail .el-tabs > .el-tabs__content',
        );
        if (tabContent && tabs && contentTopTitle) {
          const { top } = tabContent?.getBoundingClientRect();
          if (top <= tabs.offsetHeight) {
            const { scrollTop, scrollHeight, clientHeight } =
              document.querySelector('.container-body main');
            tabs.classList.add('fixed');
            contentTopTitle.classList.add('fixed');
            tabs.style.width = `${width}px`;
            tabs.style.top = `${contentTopTitle.offsetHeight}px`;
            contentTopTitle.style.width = `${width + 16}px`;
            if (!this.fixedScrollTop) {
              this.fixedScrollTop = scrollTop;
            }
            const marginTop = 2 * tabs.offsetHeight + contentTopTitle.offsetHeight + 16;
            if (scrollTop + clientHeight + marginTop >= scrollHeight) {
              return;
            }
            this.fixedMarginTop = marginTop;
          } else if (top >= tabs.offsetHeight + contentTopTitle.offsetHeight + 20) {
            tabs.classList.remove('fixed');
            contentTopTitle.classList.remove('fixed');
            tabs.style.width = 'auto';
            tabs.style.top = 'auto';
            contentTopTitle.style.width = 'auto';
            this.fixedMarginTop = 0;
            this.fixedScrollTop = null;
          }
        }
      }
    },
    getGroupDescs(descs) {
      let descList = [];
      if (isExpression(descs)) {
        const newDescs = this.execExpression(descs);
        if (Array.isArray(newDescs)) {
          descList = newDescs;
        }
      } else {
        descs.forEach((field) => {
          const desc = fieldLabel(this.formValue, field, this.expParamsObj);
          if (isString(desc) && desc) {
            descList.push(desc);
          }
        });
      }
      return descList;
    },
  },
};
