import { get, cloneDeep, has } from 'lodash';
import { getCurrentPage, getPageParams } from 'nges-common/src/web-mobile/util';

import { queryById } from './detailUtil';
import { execExpressionDeep } from './expression';

const isAppendix = (data) => data.every((i) => i?.name && i?.type && i?.url);

// 因为gql的格式限制，formDiff计算的时候，根据值的不同类型处理值
const formatFormDiffVal = (data) => {
  if (typeof data === 'string' || typeof data === 'number') {
    return data;
  }
  if (Array.isArray(data)) {
    try {
      // 通过特征值判断是否为附件字段
      if (data.length && isAppendix(data)) {
        return JSON.stringify(data).replaceAll(`"`, `\\"`);
      }
    } catch (e) {
      // 如果特征值不满足或报错，不进行附件的 formDiff
      console.log(e, data);
    }
    return data.toString();
  }
  if (data !== null && typeof data === 'object') {
    return JSON.stringify(data);
  }
  return null;
};

/**
 * 处理dcr提交字段中的old_show 和 new_show
 * dcr提交后，需要给用户展示改动前后的对应值的名称
 * 例如修改性别项：值从1：2， 则old_show：女  new_show: 男
 * old_show/new_show 的值类型因为表单配置得不同值类型存在多种形式，此方法从多种形式值中提取对应的name/label
 */
const formatFormDiffShow = ({ field, data, val }) => {
  const defaultVal = formatFormDiffVal(val);
  // 获取field对应的子对象
  const fieldData = data[`${field}-name`] || data[`${field}-label`] || data[field];

  // 如果不存在子对象，返回defaultVal
  if (!fieldData) {
    return defaultVal || null;
  }

  // 如果fieldData是字符串或数字，直接返回
  if (typeof fieldData === 'string' || typeof fieldData === 'number') {
    return fieldData;
  }

  // 如果fieldData是数组，处理数组中的每一项
  if (Array.isArray(fieldData)) {
    try {
      // 通过特征值判断是否为附件字段
      if (fieldData.length && isAppendix(fieldData)) {
        return defaultVal;
      }
    } catch (e) {
      // 如果特征值不满足或报错，不进行附件的 formDiff
      console.log(e, fieldData);
    }
    return fieldData
      .map((item) => {
        // 如果数组中的项是简单的字符串或数字，直接返回
        if (typeof item === 'string' || typeof item === 'number') {
          return item;
        }

        // 如果数组中的项是对象，查找包含"name"或"label"的键
        if (item && typeof item === 'object') {
          const keys = Object.keys(item);

          for (const key of keys) {
            if (key.includes('name') || key.includes('label')) {
              // 返回第一个满足条件的键对应的值
              return get(item, key);
            }
          }
        }

        // 如果没有找到满足条件的键，返回''
        return '';
      })
      .toString();
  }

  // 如果fieldData是对象，获取其所有键
  if (typeof fieldData === 'object') {
    const keys = Object.keys(fieldData);

    // 遍历键，查找包含"name"或"label"的键
    for (const key of keys) {
      if (key.includes('name') || key.includes('label')) {
        // 返回第一个满足条件的键对应的值
        return get(fieldData, key);
      }
    }
  }

  // 如果没有找到满足条件的键，返回defaultVal
  return defaultVal;
};

const getAttachmentNames = (data) => {
  if (typeof data === 'string') {
    data = JSON.parse(data?.replace(/\\/g, ''));
  }
  if (Array.isArray(data)) {
    return data
      ?.map((item) => item.name)
      ?.sort()
      ?.toString();
  }
  return data?.name;
};

// 小程序更新 form diff 字段
function updateDiffField({ field, propField, layoutJson, detailValue, formValue, formData }) {
  const { object } = layoutJson || {};
  const isEdit = Object.keys(detailValue || {}).length;

  const diffConf = propField?.diff_conf;
  const newValue
    = diffConf?.new_value || formatFormDiffVal((formValue && formValue[field]) ?? null);
  const oldValue
    = diffConf?.old_value || formatFormDiffVal((detailValue && detailValue[field]) ?? null);
  const newShow
    = diffConf?.new_show || formatFormDiffShow({ field, data: formData, val: newValue });
  const oldShow
    = diffConf?.old_show || formatFormDiffShow({ field, data: detailValue, val: oldValue });
  const item = {
    field: diffConf?.field || field,
    new_value: newValue,
    new_show: newShow,
    old_value: null,
    old_show: null,
    table_name: diffConf?.table_name || object,
  };
  // 如果是新增页则添加到diffFields
  if (!isEdit) {
    item.operate_type = diffConf?.operate_type || 'insert';
    return item;
  }

  // 附件组件生成newValue的时候默认帮忙加上了\\转义符号（业务需要），会影响diff计算。有的一层转义，有的多层转义。需要去除转义符号进行比较
  const isAttachment = ['ATTACHMENT', 'UPLOAD'].includes(propField?.value_type);
  let attachmentHasDiff = false;
  if (isAttachment) {
    try {
      if (newValue && oldValue) {
        attachmentHasDiff = getAttachmentNames(newValue) !== getAttachmentNames(oldValue);
      } else if ((newValue && !oldValue) || (!newValue && oldValue)) {
        attachmentHasDiff = true;
      }
    } catch (e) {
      // ignore
      console.log('attachment diff error', e);
    }
  }
  // 如果是编辑页面，且有diff则添加到diffFields
  const hasEditDiff = has(diffConf, 'rule')
    ? diffConf?.rule
    : attachmentHasDiff || (!isAttachment && newValue !== oldValue);
  if (hasEditDiff) {
    item.old_value = oldValue;
    item.old_show = oldShow;
    item.operate_type = diffConf?.operate_type || 'update';
    item.field_id = diffConf?.field_id || detailValue.id;
    return item;
  }
}

// 请求 dcr 数据，将 dcr 注入到 json 中
async function initDcrInfoFromQuery(dcrJson, expParamsObj) {
  const json = execExpressionDeep(cloneDeep(dcrJson), { ...expParamsObj }) || {};
  let options = {};
  
  options = getPageParams() || {};
  
  const res = await queryById({ json, id: json.id || options?.dcr_id || options?.id });
  if (Array.isArray(res?.dcr_field)) {
    try {
      res.dcr_field_map = res.dcr_field.reduce((p, n) => {
        p[n.field] = n;
        return p;
      }, {});
    } catch (e) {
      console.error('dcr_field try map error', e);
    }
  }
  if (res?.dcr_content) {
    try {
      res.dcr_content = JSON.parse(decodeURIComponent(res.dcr_content));
    } catch (e) {
      console.error('dcr_content parse error', e);
    }
  }
  if (dcrJson?.response) {
    const jsonRes = execExpressionDeep(cloneDeep(dcrJson.response), {
      ...expParamsObj,
      dcrInfo: res,
    });
    // 有返回结果就用自定义结果，没有返回，就用res，但是编译一遍表达式
    if (jsonRes) {
      return jsonRes;
    }
  }
  return res;
}

export { updateDiffField, initDcrInfoFromQuery };
