import config from './config';
import aegis from './aegis';
import { doLogin, clearLogin, login, customLogin } from './login';
// 解决 ./login require 动态引入 customLogin 导致的报错问题
import { customLogin as webCustomLogin } from './login/webLogin';
import report from './report';
import { weAtob, getAccountInfoSync, getFullPagePath } from './util';
import { endsWith, set, includes } from 'lodash';
import { requestHook } from './requestHook';
import { lstore } from 'nges-common/src/util';
const _ = { endsWith, set, includes };



let requestSdk = {};
requestSdk = require('@tencent/eyao-request-web-sdk');


const requestConfig = requestSdk.default;

let urlProxyMap = {}; // 记录url替换路径，由GetAppConfig接口返回，若出现访问此接口，则替换成目标接口。

const getProxyUrl = (cmd) => {
  // 获取替换的cmd接口名
  for (const k of Object.keys(urlProxyMap)) {
    if (_.endsWith(k, cmd)) {
      return urlProxyMap[k].toString().replace(/^\//g, '');
    }
  }
  return cmd;
};

// 获取并缓存服务器时间。由于本地时间存在被篡改风险，所以需要用到服务器时间。
const cacheServerDate = (header) => {
  console.log('===header11==', header);
  const date = header?.Date || header?.date;
  const serverDate = date ? new Date(date) : new Date();
  
  lstore.storage.setItem('__deff_date__', serverDate.getTime() - new Date().getTime());
  
};

const afterRequest = function (res) {
  const url = res?.config?.url;
  if (_.includes(url, 'NgesServer/NgesApp/GetAppConfig')) {
    urlProxyMap = res?.data?.body?.payload?.cmd_redirect || {};
  }
  const header = res.header || res.headers;
  cacheServerDate(header);
  let tokenExpire = header?.['x-set-token-expire'];
  tokenExpire = tokenExpire?.split(',')?.[0];
  if (tokenExpire) tokenExpire = (Number(tokenExpire) + 31536000).toString();
  let token = header?.['x-set-token'];
  token = token?.split(',')?.[0];
  if (token) {
    let uin = header?.['x-set-uin'];
    uin = uin?.split(',')?.[0];
    const loginInfo = {
      token,
      uin,
      time: tokenExpire,
    };
    console.log('header', loginInfo);
    login.setLogin(loginInfo);
  } else if (tokenExpire) {
    login.setLogin({
      token: login.getLoginInfo('token'),
      uin: login.getLoginInfo('uin'),
      time: Number(tokenExpire),
    });
    
  }
  const payload = res.data?.body?.payload;
  if (payload?.token) {
    // 需要劫持登录请求，添加请求上报
    setTimeout(() => {
      report.reportEvent('nges_enter');
    }, 500);
  }
  let extraErrmsg = header?.['x-extra-errmsg'] || header?.['X-Extra-Errmsg'];
  extraErrmsg = extraErrmsg?.split(',')?.[0];
  // 对象校验规则提示对象的json字符串，通过base64加密了(2023.11.23开始该逻辑会被兼容，已变老功能，因为只有单个错误)
  if (extraErrmsg) {
    try {
      const checkResult = JSON.parse(decodeURIComponent(escape(weAtob(extraErrmsg))));
      _.set(res, 'data.body.payload.__rule_check_result', checkResult);
    } catch (err) {
      console.error(err);
    }
  }
  // 2023.11.23新增，校验返回多个错误，是个数组
  const errMsgs = header?.['x-extra-errmsgs'] || header?.['X-Extra-Errmsgs'];
  if (errMsgs) {
    try {
      const checkResult = errMsgs.split(',').reduce((pre, e) => {
        let json;
        try {
          json = JSON.parse(decodeURIComponent(escape(weAtob(e))));
        } catch (err) {
          console.error(err);
        }
        json && pre.push(json);
        return pre;
      }, []);
      uni.$emit('SHOW_CHECK_RESULTS', { checkResult });
      _.set(res, 'data.body.payload.__rule_check_results', checkResult);
    } catch (err) {
      console.error(err);
    }
  }
  if (res?.data?.body?.bizcode === 13101) {
    res.data.body.bizcode = 31009;
    res.data.body.message = '已存在相同数据';
  }
  return res;
};
let setConfig = {
  cloud: `https://${config.domain}${config.api}`,
};
setConfig = {
  cloud: config.api,
};


let requestConfigOptions = {};





function setAppidToCmdQuery(cmd) {
  let appid = '';
  
  
  if (appid) {
    cmd = `${cmd}${cmd.indexOf('?') === -1 ? '?' : '&'}appid=${appid}`;
  }
  return cmd;
}

const { request: requestCustom, storeRequest: storeRequestSdk } = requestSdk;
const storeRequestFinishKey = [];
function storeRequest(opt, option) {
  // 访问的替换成配置的替换url
  if (opt?.cmd) {
    _.set(opt, 'cmd', getProxyUrl(opt.cmd)); // 访问的url替换成配置的替换url
    _.set(opt, 'cmd', setAppidToCmdQuery(opt.cmd)); // 注入appid
  }
  const token = customLogin.getToken();
  if (token && option?.key && option?.cache) {
    option.key = `${token}_${option.key}`;
  }
  if (config.VUE_APP_TEST_ENV) {
    opt.options = {
      ...opt.options,
      headers: {
        'coding-env-id': config.VUE_APP_TEST_ENV,
        ...opt.options?.headers,
      },
    };
  }
  // 注入当前页面路径到headers中
  _.set(opt, 'options.headers["X-Nges-Location"]', encodeURI(getFullPagePath()));
  _.set(opt, 'options.client["version"]', process.env.VUE_APP_NGES_VERSION || '');
  return storeRequestSdk(opt, option).then((res) => {
    if (!storeRequestFinishKey.includes(option.key)) {
      aegis.infoAll({
        msg: `request success ${opt.cmd} ${JSON.stringify(opt.payload || '{}')}`,
      });
      storeRequestFinishKey.push(option.key);
    }
    return res;
  });
}
function request(cmd, payload = {}, options = {}) {
  cmd = getProxyUrl(cmd); // 访问的url替换成配置的替换url
  cmd = setAppidToCmdQuery(cmd); // 注入appid
  if (config.VUE_APP_TEST_ENV) {
    options.headers = {
      'coding-env-id': config.VUE_APP_TEST_ENV,
      ...options.headers,
    };
  }
  // 注入当前页面路径到headers中
  _.set(options, 'headers["X-Nges-Location"]', encodeURI(getFullPagePath()));
  _.set(options, 'client["version"]', process.env.VUE_APP_NGES_VERSION || '');
  return requestCustom(cmd, payload, options).then((res) => {
    aegis.infoAll({
      msg: `request success ${cmd} ${JSON.stringify(payload || '{}')}`,
    });
    return res;
  });
}
export { requestConfig, request, storeRequest, afterRequest, requestCustom, requestConfigOptions };
export default request;
