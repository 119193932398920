import {
  keys,
  isObject,
  uniq,
  startsWith,
  endsWith,
  hasIn,
  isArray,
  isPlainObject,
  forEach,
  map,
  get,
  isString,
  isNil,
  has,
  cloneDeep,
  set,
  trim,
  keysIn,
  isEmpty,
  includes,
  isNumber,
} from 'lodash';
import {
  PROP_LABEL,
  PROP_CLASS,
  HTTP_REG,
  PROP_SPLIT,
  PREFIX_CLASS,
  GQL_RESERVED_WROD,
} from '../constant';
import { query } from '../web-mobile/util';
import { getValue, escapeSpecialChars } from '../graphql';
import { isExpression, execExpression, execExpressionDeep } from './expression';
import { easyQueryGql, generateQueryGqlWhere } from './queryUtil';
import transObjectUtil from './transObjectValue';
const { transItem, getFieldItem, getObjectItemAndField, transObjectValue } = transObjectUtil;
const _ = {
  keys,
  isObject,
  uniq,
  startsWith,
  endsWith,
  hasIn,
  isArray,
  isPlainObject,
  forEach,
  map,
  get,
  isString,
  isNil,
  has,
  cloneDeep,
  set,
  trim,
  keysIn,
  isEmpty,
  includes,
  isNumber,
};

function getJsonObjects(json) {
  let objects = [];
  _.keys(json).forEach((key) => {
    if (key === 'object' && !isExpression(json[key])) {
      objects.push(json[key]);
      return;
    }
    // 包含object属性但并不需要列入的对象
    const blackList = ['recipient_list'];
    if (!blackList.includes(key) && _.isObject(json[key])) {
      objects = objects.concat(getJsonObjects(json[key]));
    }
  });
  return _.uniq(objects);
}

function getObjects(json) {
  let objects = getJsonObjects(json);
  const { alias, fields = [] } = json;
  objects = objects.map((item) => {
    return (alias && alias[item]) || item;
  });
  const fieldArr = fields?.map((item) => item.field || item);
  fieldArr?.forEach((itemField) => {
    if (itemField.includes('.')) {
      const itemFieldArr = itemField.split('.');
      objects = objects.concat(
        itemFieldArr.slice(0, itemFieldArr.length - 1).filter((item) => !_.startsWith(item, '_')), // 过滤掉 _ 开头的object
      );
    }
  });
  if (alias) {
    objects = objects.map((item) => alias[item] || item);
    const aliasRealObject = Object.values(alias) || [];
    objects.push(...aliasRealObject);
  }
  return _.uniq(objects);
}

function getFieldLabel(item, field = '') {
  const prop = (field ?? '').replaceAll('.', PROP_SPLIT);
  // 如果这个属性不在item里则返回空字符串，代表不显示这个字段或者显示为空
  if (!_.hasIn(item, prop)) return '';
  return item[`${prop}${PROP_LABEL}`] || item[prop];
}
function getFieldValue(item, field) {
  const prop = field.replaceAll('.', PROP_SPLIT);
  if (!_.hasIn(item, prop)) return '';
  return item[prop];
}

// 随机分配，产品觉得没意义，后续用元数据解决
function getFieldClass(item, field = '', noPrefix = false) {
  const prop = field.replaceAll('.', PROP_SPLIT);
  const propClass = `${prop}${PROP_CLASS}`;
  if (!_.hasIn(item, prop) || !_.hasIn(item, propClass)) return '';
  const classValue = item[propClass];
  if (_.isArray(classValue)) {
    return classValue.map((value) => `${noPrefix ? '' : PREFIX_CLASS}${value || 'default'}`);
  }
  return `${noPrefix ? '' : PREFIX_CLASS}${classValue || 'default'}`;
}

function getPathOpts(url) {
  if (HTTP_REG.test(url)) {
    return {
      h5url: url,
    };
  }
  return {
    wxaurl: url,
  };
}

function getFieldRelation({ field, objects, json }) {
  const { objectItem, itemField } = getObjectItemAndField({ field, objects, json });
  let relation = false;
  if (objectItem) {
    relation
      = (objectItem?.object?.relations || []).findIndex((item) => {
        return item.virtual_field === itemField;
      }) > -1;
  }
  return relation || false;
}

// 根据字段从后台的元数据获取label和class
function getLabelAndClassFromOptions({ field, itemValue, fieldItem }) {
  let outItem = {};
  const valueType = fieldItem?.value_type;
  const prop = field.replaceAll('.', PROP_SPLIT);
  const propClass = prop + PROP_CLASS;
  const propLabel = prop + PROP_LABEL;
  const options = _.get(
    fieldItem,
    valueType === 'SELECT_ONE' ? 'select_one_option.options' : 'select_many_option.options',
    [],
  );
  if ((itemValue || itemValue === 0 || itemValue === false) && options.length) {
    if (valueType === 'SELECT_ONE') {
      const optionItem = options.filter((option) => option.value === itemValue)[0];
      if (optionItem) {
        outItem = {
          [propLabel]: optionItem.label,
          [propClass]: optionItem.class,
          value: itemValue,
          label: optionItem?.label || itemValue,
        };
      }
    } else {
      outItem = [];
      const newItemValue = _.isString(itemValue) ? itemValue.split(',') : itemValue;
      if (!_.isArray(newItemValue)) {
        console.warn('===newItemValue should be Array===');
      }
      newItemValue.forEach((item) => {
        const data = {
          value: itemValue,
        };
        const itemLabel = options.filter((option) => option.value === item)[0]?.label;
        if (!_.isNil(itemLabel)) {
          data.label = itemLabel;
          data[propLabel] = itemLabel;
          data[propClass] = options.filter((option) => option.value === item)[0]?.class;
          outItem.push(data);
        }
      });
    }
  }
  return outItem;
}

// 根据recordType过滤枚举值
function filterOptionsByRecordType(options, recordType = '', field, objects, objectName) {
  const { fields: mainObjFields } = objects.find((obj) => obj?.object?.name === objectName) || {};
  if (!mainObjFields) {
    return options;
  }
  const recordTypeField = mainObjFields.find((mainObjField) => mainObjField.name === 'record_type');
  const recordTypeOpts = recordTypeField?.select_one_option?.options;
  if (!recordTypeOpts) {
    return options;
  }
  const recordTypeConf = recordTypeOpts.find((recordTypeOpt) => recordTypeOpt.value === recordType);
  if (!recordTypeConf) {
    return options;
  }
  let optConf;
  try {
    optConf = JSON.parse(recordTypeConf.config);
    optConf = optConf.find((opt) => opt.value === field);
    if (optConf) {
      return cloneDeep(optConf.children);
    }
  } catch (err) {
    console.error(err);
  }
  return options;
}

function getFilter({ filter, objects, json }) {
  const { field, record_type: recordType = '' } = filter;
  const topFilter = { selected: '' };
  const bottomFilter = {};
  let valueType = '';
  if (filter.object) {
    topFilter.query = easyQueryGql(
      {
        ...filter,
        object: filter.object,
        wheres: filter.wheres || [],
        orders: filter.orders || [],
        sub_query: filter.sub_query || '',
        fields: [
          filter.label,
          filter.value,
          ...(Array.isArray(filter.option_desc) ? filter.option_desc : [filter.option_desc]),
        ].filter((item) => !isNil(item)),
      },
      { [filter.object]: { _distinct: true } },
    );
    bottomFilter.options = [];
    delete filter.query;
    topFilter.value_type = 'SELECT_MANY';
  }
  if (field) {
    const fieldItem = getFieldItem({ field, objects, json });
    if (fieldItem) {
      valueType = fieldItem.value_type;
      topFilter.value_type = topFilter.value_type || valueType;
      const { object } = json;
      if (valueType === 'SELECT_ONE' && fieldItem.select_one_option?.options?.length) {
        bottomFilter.options = filterOptionsByRecordType(
          [...fieldItem.select_one_option.options],
          recordType,
          field,
          objects,
          object,
        );
      } else if (valueType === 'SELECT_MANY' && fieldItem.select_many_option?.options?.length) {
        bottomFilter.options = filterOptionsByRecordType(
          [...fieldItem.select_many_option.options],
          recordType,
          field,
          objects,
          object,
        );
      }
      if (bottomFilter.options?.length && _.isString(filter.options)) {
        bottomFilter.options = execExpression(filter.options, {
          t: bottomFilter,
        });
        filter.options = [];
      }
    }
  }
  if (filter.options?.length) {
    bottomFilter.options = _.isArray(filter.options) ? [...filter.options] : filter.options;
    if (!topFilter.value_type) topFilter.value_type = 'SELECT_MANY';
  } else {
    const jsonColumn = json?.fields?.filter((item) => item.field === field)[0];
    // 是否不加全部，（json中定义的表单类型）除了单选外其他都不加全部
    const shouldNotAddAll =
      _.has(jsonColumn, 'value_type') && jsonColumn.value_type !== 'SELECT_ONE';
    if ((filter.value_type || topFilter.value_type) === 'SELECT_ONE' && !shouldNotAddAll) {
      bottomFilter.options = [{ label: '全部', value: 'all' }, ...(bottomFilter.options || [])];
    }
  }
  (bottomFilter?.options || []).forEach((obj) => {
    if (obj.remark) {
      obj.option_desc = obj.remark;
    }
  });
  const outFilter = {
    ...topFilter,
    ...filter,
    ...bottomFilter,
  };
  if (isExpression(outFilter.options)) {
    outFilter.options = execExpression(outFilter.options, { json });
  }
  // 记录初始值，方便重置
  outFilter.initSelected = _.cloneDeep(outFilter.selected);
  // if(outFilter.value_type==='SELECT_MANY'&&!_.isArray(outFilter.selected)){
  //   outFilter.selected = [];
  // }
  return outFilter;
}

// 统一处理，便于兼容
function getFilters({ objects, json, filters }) {
  return filters.map((filter) => {
    if (_.isString(filter)) {
      return {
        label: filter,
        field: filter,
        filter: {
          ...getFilter({ filter: { field: filter }, objects, json }),
        },
      };
    }
    return {
      label: filter.field,
      ...filter,
      filter: {
        ...getFilter({
          filter: {
            field: filter.field,
            ...filter.filter,
          },
          objects,
          json,
        }),
      },
    };
  });
}

/**
 * 获取级联影响到的filter
 * filters: 所有的过滤器
 * changeFilter： 当前变化的过滤器
 * recordFilters: 已经处理过的过滤器，避免出现死循环监听
 * */
function getListeningFilters({ filters, changeFilter, recordFilters = [] }) {
  const changeFilterField = changeFilter?.field || changeFilter?.filter?.field;
  if (!changeFilterField) {
    return [];
  }
  return filters.filter((filter) => {
    const field = get(filter, 'field') || get(filter, 'filter.field');
    const watch = filter?.filter?.watch;
    return (
      field !== changeFilterField &&
      watch &&
      watch?.includes(changeFilterField) &&
      !recordFilters.find((item) => {
        return field === (get(item, 'field') || get(item, 'filter.field'));
      })
    );
  });
}

function fieldConditionGql(key, map, str) {
  let conditionStr = map?.[key];
  if (map && conditionStr) {
    if (key === '_order_by') {
      conditionStr = conditionStr?.orderStr || conditionStr;
    }
    str += `${str ? ',' : ''}${key}:${conditionStr}`;
  }
  return str;
}

// fieldMap，拼接query的field体
function queryField(fieldMap, conditionMap = {}) {
  // _order_by里的字段需要一一拼接到graphql的查询字段里，因为如果是distinct的情况下，查询字段需要包含排序字段里的字段
  // 不然查询sql会报错
  if (_.has(conditionMap, '_order_by')) {
    const { orderStr, orderFields } = conditionMap._order_by || {};
    if (!_.has(fieldMap, 'list')) {
      fieldMap.list = [];
    }
    fieldMap.list = _.uniq([...fieldMap.list, ...orderFields]);
    conditionMap._order_by = orderStr;
  }
  let fieldArr = [];
  _.keys(fieldMap).forEach((key) => {
    if (key === 'list') {
      fieldArr = fieldArr.concat(fieldMap.list);
      return;
    }
    const condition = conditionMap[key];
    const objectOp = ['_order_by', '_limit', '_offset', '_where', '_distinct', '_distinct_on'];
    // 获取详情查询时需要的唯一标识key
    if (_.has(condition, 'id') && condition.id.key && condition.id.value) {
      objectOp.push(condition.id.key);
      condition[condition.id.key] = getValue(condition.id.value);
    }
    let conditionStr = '';
    objectOp.forEach((kw) => {
      conditionStr = fieldConditionGql(kw, condition, conditionStr);
    });
    conditionStr = conditionStr ? `(${conditionStr})` : '';
    fieldArr.push(`${key}${conditionStr}{${queryField(fieldMap[key], condition)}}`);
  });
  return _.uniq(fieldArr).join(',');
}

// field，如department.id,department.name，需要合并
function getFieldMap(json) {
  const aggregateKey = '_aggregate';
  const { fields = [], ignore_id: ignoreId, ignore_record_type: ignoreRecordType } = json;
  const fieldArr = fields.map((item) => item.field || item);
  const fieldMap = { list: [] };
  const fieldListForRecordType = [fieldMap.list]; // 用于记录所有的列表数据，用于后边自动注入record_type
  fieldArr.forEach((item) => {
    if (!item.includes('.')) {
      fieldMap.list.push(item);
    } else {
      const fieldItems = item.split('.');
      const fieldItemsLen = fieldItems.length;
      const fieldMapKey = fieldItems.slice(0, fieldItemsLen - 1).join('.');
      let fieldMapValue = _.get(fieldMap, fieldMapKey);
      if (!fieldMapValue) {
        const newList = [];
        _.set(fieldMap, fieldMapKey, { list: newList });
        // 聚合字段不需注入record_type
        if (!_.endsWith(`.${fieldMapKey}`, `.${aggregateKey}`)) {
          fieldListForRecordType.push(newList);
        }
      }
      fieldMapValue = _.get(fieldMap, fieldMapKey);
      if (!fieldMapValue.list) {
        const newList = [];
        fieldMapValue.list = newList;
        // 聚合字段不需注入record_type
        if (!_.endsWith(`.${fieldMapKey}`, `.${aggregateKey}`)) {
          fieldListForRecordType.push(newList);
        }
      }
      fieldMapValue.list.push(fieldItems[fieldItemsLen - 1]);
    }
  });
  if (!fieldMap.list.includes('id') && !ignoreId) {
    fieldMap.list.push('id');
  }
  // 自动往查询数据中加入record_type，
  // 如果字段中，仅包含_aggregate, 则不加record_type，防止查回多条无用数据
  // 若是_开头等聚合字段(除了_aggregate和_func)，也不加入record_type，防止出错。
  // 若已经带了record_type 也不添加
  if (!ignoreRecordType) {
    fieldListForRecordType.forEach((item) => {
      if (
        item.length === 0 ||
        (item.length === 1 && startsWith(item[0], '_aggregate')) ||
        item.find((field) => {
          // todo: 待确认@jimsonguan，此处field为啥会有存在数组的情况？
          if (!isString(field)) {
            return false;
          }
          const idx = field.indexOf(':');
          field = field.substr(idx + 1).trim();
          return (
            startsWith(field, '_')
            && !startsWith(field, '_aggregate')
            && !startsWith(field, '_func')
          );
        }) ||
        item.includes('record_type')
      ) {
        return;
      }
      item.push('record_type');
    });
  }
  return fieldMap;
}

function getWhereMap(json, extraWheres) {
  const { wheres = [], object } = _.cloneDeep(json);
  // 条件合并
  if (Array.isArray(extraWheres) && extraWheres.length) {
    extraWheres.forEach(({ object_name: objectName, fields }) => {
      const whereIndex = wheres.findIndex((item) => item.object_name === objectName);
      if (whereIndex >= 0) {
        const jsonFields = wheres[whereIndex]?.fields || [];
        Array.isArray(jsonFields) &&
          _.set(wheres, `[${whereIndex}].fields`, [...jsonFields, ...fields]);
      } else {
        wheres.push({
          fields,
        });
      }
    });
  }
  const whereMap = {};
  if (Array.isArray(wheres) && wheres.length) {
    // 相同object_name和relation的条件集合合并到同一个对象中
    let composeWheresObj = wheres.reduce(
      (pre, { object_name: objectName = '', fields, relation = '_and' }) => {
        const key = `${objectName}-${relation}`;
        if (pre[key]) {
          const exitWhereObj = pre[key];
          exitWhereObj.fields = [...exitWhereObj.fields, ...fields];
        } else {
          pre[key] = {
            object_name: objectName,
            fields,
            relation,
          };
        }
        return pre;
      },
      {},
    );
    composeWheresObj = Object.values(composeWheresObj);
    composeWheresObj.forEach(({ object_name: objectName, fields, relation = '_and' }) => {
      if (Array.isArray(fields) && fields.length) {
        const key = objectName ? `${object}.${objectName}` : object;
        const fieldsObj = {
          not: [], // 反向筛选
          normal: [], // 正常筛选
        };
        fields.forEach((item) => {
          const {
            value,
            field,
            where_type: whereType,
            where_gql: whereGqlStr,
            is_not: isNot = false,
          } = item;
          if (!whereGqlStr) {
            fieldsObj[isNot ? 'not' : 'normal'].push(
              whereGql({ value, field, where_type: whereType, force: true }),
            );
          } else {
            fieldsObj[isNot ? 'not' : 'normal'].push(whereGqlStr);
          }
        });
        const { normal, not } = fieldsObj;
        // 过滤过滤器为空的值，如[xx, '', '']
        const normalValue = normal.filter((item) => item);
        const normalWhereMapGql = `${relation}:[${normalValue.join(',')}]`;
        const notWhereMapGql = not.length ? `, _not: { ${relation}:${not.join(',')} }` : '';
        _.set(whereMap, `${key}._where`, `{${normalWhereMapGql}${notWhereMapGql}}`);
      }
    });
  } else {
    return { [object]: { _where: '' } };
  }
  return whereMap;
}

// console.log('------=====------where');
// console.log(getWhereMap({
//   object: 'events_meeting',
//   wheres: [
//     {
//       fields: [
//         {
//           field: 'status',
//           value: 5,
//           where_type: '_eq',
//         },
//         {
//           field: 'type',
//           value: '3',
//           where_type: '_eq',
//         }
//       ],
//       reletion: "_and",
//     },
//     {
//       object_name: 'hcp.hco',
//       fields: [
//         {
//           field: 'status',
//           value: 5,
//           where_type: '_eq',
//         },
//         {
//           field: 'department.type',
//           value: '3',
//           where_type: '_eq',
//         }
//       ],
//       reletion: "_or",
//     }
//   ]
// }))

function searchAnalysis(input = '', fields = [], json = {}) {
  const inputTrim = _.trim(input);
  const inputArr = [];
  const inputCondition = [];
  if (inputTrim && fields.length) {
    fields.forEach((item) => {
      if (_.isObject(item)) {
        if (item.where_type === '_like' || item.where_type === '_ilike' || !item.where_type) {
          inputArr.push(
            `{${item.field}:{${item.where_type ? item.where_type : '_ilike'}:"%${escapeSpecialChars(
              inputTrim,
              true,
            )}%"}}`,
          );
        } else {
          inputArr.push(
            `{${item.field}:{${item.where_type}:"${escapeSpecialChars(
              inputTrim,
              item.where_type === '_like' || item.where_type === '_ilike',
            )}"}}`,
          );
        }
        inputCondition.push({
          field: item.field,
          type: item.where_type ? item.where_type : '_ilike',
          value: inputTrim,
        });
      } else {
        inputArr.push(`{${item}:{_ilike:"%${escapeSpecialChars(inputTrim, true)}%"}}`);
        inputCondition.push({ field: item, type: '_ilike', value: inputTrim });
      }
    });
  }
  if (inputArr.length) {
    const relation = json?.render?.search?.relation || '_or';
    return { gql: `{${relation}:[${inputArr.join(',')}]}`, condition: inputCondition };
  }
  return { gql: '', condition: [] };
}

function searchGql(input = '', fields = [], json = {}) {
  return searchAnalysis(input, fields, json).gql;
}

export function searchCondtion(input, fields, json) {
  return searchAnalysis(input, fields, json).condition;
}

// 获取时间数据，若是秒级时间戳转成毫秒级时间戳
function getDateValue(value) {
  let dateValue = value;
  // 兼容秒级时间戳和毫秒级时间戳
  if (isNumber(dateValue) && dateValue < 100000000000) {
    dateValue = dateValue * 1000;
  }
  return dateValue;
}

function whereAnalysis({
  value,
  value_type: valueType,
  field,
  where_type: whereType,
  force,
  where_gql: whereGql,
  filters,
}) {
  let selected = isExpression(value) ? execExpression(value) : value;
  let fieldWhere = '';
  let condition = {};
  // 无field，表示本身就是query
  if (!field) {
    if (_.isArray(selected)) {
      fieldWhere = `{_or:[${selected.join(',')}]}`;
    } else if (selected && selected !== 'all') {
      fieldWhere = selected;
    }
    return {
      gql: fieldWhere,
      condition: null,
    };
  }
  if (whereGql) {
    return {
      gql: isExpression(whereGql)
        ? execExpression(whereGql, { t: selected, $filters: filters })
        : whereGql,
      condition: null,
    };
  }
  if (['DATE_TIME', 'DATE'].includes(valueType) && selected) {
    selected
      = new Date(
        getDateValue(_.isString(selected) ? selected.replaceAll('-', '/') : selected),
      ).getTime() / 1000;
  }
  if (_.isArray(selected)) {
    if (valueType === 'DATETIME') {
      selected = selected.map((item) =>
        parseInt(
          new Date(getDateValue(_.isString(item) ? item.replaceAll('-', '/') : item)) / 1000,
        ),
      );
    }
    if (
      whereType === '_between'
      || (valueType === 'DATETIME' && !whereType)
      || valueType === 'INT'
    ) {
      const first = parseFloat(selected[0]);
      let second = parseFloat(selected[1]);
      if (!isNaN(first) && !isNaN(second)) {
        // 日期选择器选择范围时，结束时间戳为结束日零点，故将结束时间戳往后加86400 - 1秒
        if (valueType === 'DATETIME') {
          second = second + 24 * 3600 - 1;
        }
        fieldWhere = `{_between:${getValue([first, second], true)}}`;
        condition = { type: '_between', value: [first, second], field };
      } else if (!isNaN(first)) {
        fieldWhere = `{_gte:${getValue(first, true)}}`;
        condition = { type: '_gte', value: first, field };
      } else if (!isNaN(second)) {
        fieldWhere = `{_lte:${getValue(second, true)}}`;
        condition = { type: '_lte', value: second, field };
      }
    } else if (selected.length) {
      fieldWhere = `{${whereType || '_in'}:${getValue(
        selected,
        true,
        whereType === '_like' || whereType === '_ilike',
      )}}`;
      condition = { type: whereType || '_in', value: selected, field };
    }
  } else if (selected || selected === 0 || selected === false || force) {
    if (valueType === 'SELECT_ONE') {
      if (selected !== 'all')
        fieldWhere = `{${whereType || '_eq'}:${getValue(
          selected,
          true,
          whereType === '_like' || whereType === '_ilike',
        )}}`;
      condition = { type: whereType || '_eq', value: selected, field };
    } else {
      if (valueType === 'DATETIME') {
        selected = parseInt(
          new Date(_.isString(selected) ? selected.replaceAll('-', '/') : selected) / 1000,
        );
      }
      fieldWhere = whereType
        ? `{${whereType}:${getValue(
            selected,
            true,
            whereType === '_like' || whereType === '_ilike',
          )}}`
        : `{_ilike:"%${escapeSpecialChars(selected, true)}%"}`;
      condition = whereType
        ? { type: whereType, value: selected, field }
        : { type: '_ilike', value: selected, field };
    }
  }
  return { gql: fieldWhere ? `{${field}:${fieldWhere}}` : '', condition };
}

function whereGql({
  value,
  value_type: valueType,
  field,
  where_type: whereType,
  force,
  where_gql: whereGql,
  filters,
}) {
  return whereAnalysis({
    value,
    value_type: valueType,
    field,
    where_type: whereType,
    force,
    where_gql: whereGql,
    filters,
  }).gql;
}

function whereCondition({
  value,
  value_type: valueType,
  field,
  where_type: whereType,
  force,
  where_gql: whereGql,
  filters,
}) {
  return whereAnalysis({
    value,
    value_type: valueType,
    field,
    where_type: whereType,
    force,
    where_gql: whereGql,
    filters,
  }).condition;
}

function filterOptions(filter, res) {
  const data = res && res[filter.object];
  if (data?.length) {
    const objectOptions = [];
    data.forEach((item) => {
      objectOptions.push({
        label: get(item, filter.label),
        value: get(item, filter.value),
        option_desc:
          filter.option_desc && Array.isArray(filter.option_desc)
            ? filter.option_desc.map((desc) => {
                return get(item, desc || '');
              })
            : get(item, filter.option_desc),
      });
    });
    return objectOptions;
  }
  return [];
}

function fieldLabel(item, field, expParamsObj = {}) {
  if (isExpression(field)) return execExpression(field, { t: item, ...expParamsObj });
  return getFieldLabel(item, field);
}

function fieldValue(item, field, expParamsObj = {}) {
  if (isExpression(field)) return execExpression(field, { t: item, ...expParamsObj });
  return getFieldValue(item, field);
}

function fieldClass(item, field, noPrefix, expParamsObj = {}) {
  if (isExpression(field)) return execExpression(field, { t: item, ...expParamsObj });
  return getFieldClass(item, field, noPrefix);
}

function mergeJson(json, newJson) {
  return {
    ...json,
    ...newJson,
    render: {
      ...json?.render,
      ...newJson?.render,
    },
  };
}

function getFieldKey(field = '') {
  return field.toString().replaceAll('.', PROP_SPLIT);
}

function getPath(url = '') {
  const path = query('path', url);
  if (path) {
    return path;
  }
  const searchIndex = url.indexOf('?');
  return url.substr(0, searchIndex > -1 ? searchIndex : url.length);
}

// 比对路径是否一致，前后去掉/mini 兼容h5 url base中带了mini这种情景
function equalPath(path1 = '', path2 = '') {
  const replace = (str) => {
    return str.replace(/^\/mini/g, '');
  };
  return replace(path1) === replace(path2);
}

function getLayoutJson(list, key) {
  try {
    if (list?.length) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (equalPath(getPath(item.path), key)) {
          const json = JSON.parse(item.layout_json);
          // 注入菜单属性到json中
          json._own_prop = {
            id: item.id, // 对应菜单id
            package: item.package, // 对应模块
            layout_type: 'PAGE', // 页面布局
          };
          return mergeCommonJson(json, list);
        }
        if (item.children?.length) {
          const result = getLayoutJson(item.children, key);
          if (result) return result;
        }
      }
    }
  } catch (e) {}
}

function transCommonJson(data, commonJson) {
  let outData = data;
  if (data.global_json && commonJson[data.global_json]) {
    outData = mergeJson(commonJson[data.global_json], data);
  }
  if (_.isArray(data) || _.isObject(data)) {
    _.keysIn(data).forEach((key) => {
      const item = data[key];
      if (_.isArray(item) || _.isObject(item)) outData[key] = transCommonJson(item, commonJson);
    });
  }
  return outData;
}

function mergeCommonJson(json, list) {
  try {
    let commonJson = list.filter((item) => item.path === 'common')[0];
    if (commonJson?.layout_json) {
      commonJson = JSON.parse(commonJson.layout_json);
      return transCommonJson(json, commonJson);
    }
  } catch (e) {}
  return json;
}
// 获取排序条件
function getFieldOrderMap(json) {
  const { orders, object } = json;
  if (!orders || !orders.length) {
    return {};
  }
  const orderMap = {};
  orders.forEach((order) => {
    const { object_name: objectName, field, type } = order;
    let objs = [object];
    if (objectName) {
      objs = objs.concat(objectName.split('.'));
    }
    if (objs.length) {
      let map = orderMap;
      objs.forEach((item, idx) => {
        if (!map[item]) {
          map[item] = {};
        }
        if (idx === objs.length - 1) {
          if (!map[item]._order_by) {
            map[item]._order_by = {};
            map[item]._order_by.orderStr = `${field}:${type}`;
            // 需要拼到查询字段里
            map[item]._order_by.orderFields = [field];
          } else {
            map[item]._order_by.orderStr += `,${field}:${type}`;
            // 需要拼到查询字段里
            map[item]._order_by.orderFields.push(field);
          }
        } else {
          map = map[item];
        }
      });
    }
  });
  orders.forEach((order) => {
    const { object_name: objectName } = order;
    const key = objectName
      ? `${object}.${objectName}._order_by.orderStr`
      : `${object}._order_by.orderStr`;
    const orderStr = _.get(orderMap, key);
    if (orderStr && !/^\{.*\}$/g.test(orderStr)) {
      _.set(orderMap, key, `{${orderStr}}`);
    }
  });
  return orderMap;
}
// 获取distinct_on条件
function getDistinctOnMap(json) {
  const { distinct_ons: distinctOns, object } = json;
  if (!distinctOns || !distinctOns.length) {
    return {};
  }
  const distinctOnMap = {};
  distinctOns.forEach((distinctOn) => {
    const { object_name: objectName } = distinctOn;
    const key = `${objectName ? `${object}.${objectName}` : `${object}`}._distinct_on`;
    _.set(distinctOnMap, key, `[${distinctOn?.fields.join(',')}]`);
  });
  return distinctOnMap;
}

// console.log('------=====------order');
// console.log(getFieldOrderMap({
//   object: 'events_meeting',
//   orders: [
//     {
//       object_name: "hcp.hco",
//       field: "id",
//       type: "_desc"
//     },
//     {
//       object_name: "hcp.hco",
//       field: "a.b",
//       type: "_desc"
//     },
//     {
//       field: "create_time",
//       type: "_asc"
//     }
//   ]
// }))

function transUins(gql, uins = []) {
  if (uins.length) {
    const uinValue = getValue(uins);
    return gql.replace(/\[\s*\$_uin\s*\]/gi, uinValue).replace(/\$_uin/gi, `{_in:${uinValue}}`);
  }
  return gql;
}

// 转换保留字，如将 "$_uin", 转成 $_uin
function formatReservedWord(gql) {
  for (let i = 0; i < GQL_RESERVED_WROD.length; i++) {
    gql = gql.replaceAll(`"${GQL_RESERVED_WROD[i]}"`, GQL_RESERVED_WROD[i]);
  }
  return gql;
}

// 通过对象和字段获取label
function getFieldLabelAndClass({ json, objects, field, itemValue, filter }) {
  const { alias, object } = json;
  let targetObject = object;
  let targetField = field;
  if (field.includes('.')) {
    const fieldList = field.split('.');
    targetObject = fieldList?.[fieldList.length - 2];
    targetField = fieldList?.[fieldList.length - 1];
  }
  // 获取对象中的列描述对象
  const fieldItem = getFieldItem({
    field: targetField,
    objects,
    json: { object: targetObject, alias },
  });
  if (fieldItem) {
    const { options } = filter;
    // 获取label和class
    let item = getLabelAndClassFromOptions({
      field: targetField,
      fieldItem,
      itemValue,
    });
    if (_.isEmpty(item)) {
      if (options?.length > 0) {
        if (_.isArray(itemValue)) {
          item = options.filter((option) => itemValue.includes(option.value)) || [];
        } else {
          item = options.find((option) => option.value === itemValue) || {};
        }
      } else {
        item = itemValue;
      }
    }
    if (item !== itemValue) {
      return item;
    }
  }
  return itemValue;
}

// 从拉到的元数据对象配置中，过滤出json主对象的配置
function getObjectConfig({ json = {}, objects = [] }) {
  const { alias, object } = json;
  let fieldObject = object;
  if (alias) {
    fieldObject = alias[fieldObject] || fieldObject;
  }
  const objectItem = objects.filter((item) => item.object.name === fieldObject)[0];
  return objectItem;
}

// json parse try catch
function saveJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.error(`parse str to obj with err: ${str.toString()}`);
    return {};
  }
}

// uniapp tab切换，会出现payload的值为null
function deleteKeys(obj, values) {
  const outObj = {};
  _.keys(obj).forEach((key) => {
    if (!values.includes(obj[key])) outObj[key] = obj[key];
  });
  return outObj;
}

function cmdPayload({
  json,
  page,
  pageSize,
  input,
  searchChecked,
  filters = [],
  filtersValue = [],
  parentData = {},
  layoutParams = {},
  hookRequestData = {},
  customCmdCondition = {},
}) {
  const { page_option: pageOption = {}, payload = {}, render } = json;
  const { whereStr, inputWhere, filterWhere } = generateQueryGqlWhere({
    filters: filters.map((item) => item.filter),
    input,
    json,
  });
  const params = {
    offset: (page - 1) * pageSize,
    limit: pageSize,
    index: page - 1,
  };
  const pagePayload = execExpressionDeep(cloneDeep(payload), {
    t: { p: parentData },
    p: layoutParams,
    $filters: filters,
    $filtersValue: filtersValue,
    $hook: hookRequestData,
  });
  _.keys(pageOption).forEach((key) => {
    pagePayload[pageOption[key]] = params[key];
  });
  let filterPayload = {};
  filters
    .map((item) => item.filter)
    .forEach((filter) => {
      filterPayload = {
        ...filterPayload,
        ...execExpression(filter.payload, {
          t: {
            p: parentData,
            input,
            searchChecked,
            query_where: whereStr,
            input_where: inputWhere,
            filter_where: filterWhere,
          },
          p: layoutParams,
          filter,
          $filters: filters,
          $filtersValue: filtersValue,
        }),
      };
    });
  let searchPayload = {};
  if (input) {
    searchPayload = execExpression(render.search.payload, {
      t: {
        input,
        searchChecked,
        p: parentData,
        query_where: whereStr,
        input_where: inputWhere,
        filter_where: filterWhere,
      },
      p: layoutParams,
      filters,
    });
  }
  let searchCheckedPayload = {};
  if (render.searchChecked?.payload) {
    searchCheckedPayload = execExpression(render.searchChecked.payload, {
      t: {
        input,
        searchChecked,
        p: parentData,
        query_where: whereStr,
        input_where: inputWhere,
        filter_where: filterWhere,
      },
      p: layoutParams,
      filters,
      searchChecked,
    });
  }
  return deleteKeys(
    {
      ...pagePayload,
      ...filterPayload,
      ...searchPayload,
      ...searchCheckedPayload,
      ...customCmdCondition,
    },
    [null],
  );
}

// 获取指定对象中的所有附件相关字段
function getAttachmentFields({ object = '', objects = [], json = {} }) {
  let fieldObject = object;
  const { alias } = json;
  if (alias) {
    fieldObject = alias[fieldObject] || fieldObject;
  }
  const fields =
    objects.find((item) => {
      return item?.object?.name === fieldObject;
    })?.fields || [];
  const attachmentFields = fields.filter((item) => {
    return (
      item?.value_type === 'TEXT' &&
      _.includes(['public_attachment', 'private_attachment'], item?.text_option?.type)
    );
  });
  return attachmentFields;
}

// 判断某附件字段是否为私有桶存储
function attachmentFieldIsPrivate({ object = '', objects = [], json = {}, field = '' }) {
  const fields = getAttachmentFields({ object, objects, json });
  const p = (fields || []).find((item) => item?.name === field);
  return p?.text_option?.type === 'private_attachment';
}

function getReleationObject({ objects = [], field = '', json = {} }) {
  if (!field) {
    return null;
  }
  const { object } = json;
  const fields = [object, ...field.split('.')];
  let pointObject = object;
  let relations = [];
  for (let i = 0; i < fields.length - 1; i++) {
    const r = relations.find((item) => {
      return item.virtual_field === fields[i];
    });
    const realObjectName = r?.target_object || fields[i];
    pointObject = objects.find((item) => {
      return item.object?.name === realObjectName;
    });
    if (!pointObject) {
      return null;
    }
    relations = pointObject.object?.relations || [];
  }
  return pointObject.object.name;
}

const helpGuideUtil = {
  history: [],
  pageConf: [],
  showHelpGuideCb: [],
  setConf(conf = {}) {
    if (conf.id && !helpGuideUtil.pageConf.find((i) => i.id === conf.id)) {
      helpGuideUtil.pageConf.push(conf);
      setTimeout(() => {
        // 这里需要等到新页面的帮助遮罩created完成后再进行 先简单用延时去保证时序
        if (helpGuideUtil.showHelpGuideCb.length) {
          helpGuideUtil.showHelpGuideCb[helpGuideUtil.showHelpGuideCb.length - 1](conf);
        }
      }, 1500);
    }
  },
  getConf: () => {
    return helpGuideUtil.pageConf;
  },
  clearConf: () => {
    helpGuideUtil.pageConf = [];
  },
};

export {
  getObjects,
  getFieldLabel,
  getFieldClass,
  getPathOpts,
  getFieldItem,
  getFieldRelation,
  transItem,
  getFilter,
  getFilters,
  getListeningFilters,
  queryField,
  getFieldMap,
  getDistinctOnMap,
  searchGql,
  whereGql,
  whereCondition,
  filterOptions,
  fieldLabel,
  fieldClass,
  fieldValue,
  getFieldValue,
  getWhereMap,
  mergeJson,
  getFieldKey,
  getLayoutJson,
  getFieldOrderMap,
  formatReservedWord,
  transUins,
  getFieldLabelAndClass,
  getObjectConfig,
  saveJsonParse,
  cmdPayload,
  getAttachmentFields,
  attachmentFieldIsPrivate,
  getReleationObject,
  transObjectValue,
  helpGuideUtil,
};
