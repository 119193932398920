//
//
//
//
//
//
//
//
//
//
//

import { includes, debounce } from 'lodash';
import { PROP_LABEL } from 'nges-common/src/constant';
import { formatTime } from 'nges-common/src/web-mobile/util';

export default {
  name: 'Date',
  props: {
    mutation: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: [Array, Object, Number, String, void 0],
      default: void 0,
    },
    formData: {
      type: [Array, Object, Number, String, void 0],
      default: void 0,
    },
    objects: {
      type: Array,
      default: () => [],
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => {},
    },
    prop: {
      type: String,
      default: '',
    },
    // 表单变更对象
    mutations: {
      type: [Object, void 0],
      default: void 0,
    },
    index: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      dateValue: '',
    };
  },
  computed: {
    calcValue() {
      if (!isNaN(this.formValue) && `${this.formValue}`.length === 10) {
        return this.formValue * 1000;
      }
      return this.formValue;
    },
    defaultPlaceholder() {
      if (includes(this.componentType, 'year')) {
        return '选择年';
      }
      if (includes(this.componentType, 'month')) {
        return '选择月';
      }
      return '选择日期时间';
    },
  },
  watch: {
    mutations() {
      this.deInitData();
    },
  },
  mounted() {
    this.deInitData = debounce(() => {
      let mutation;
      if (this.mutations) {
        mutation = this.mutations?.[this?.row?.id]?.find(
          (i) => i.field.replaceAll('.', '-') === this.prop,
        );
        if (mutation) {
          this.row[this.prop] = mutation.value;
          const dateValue = mutation?.value;
          const oldDateValue = this.dateValue;
          this.dateValue = this.formatTs(dateValue);
          // 通过当前组件handleChange出去的，重新merge回来的数据是一致的，不需要再次出发resetFields, 防止清空了校验，其他则触发reset
          if (oldDateValue !== this.dateValue) {
            this.$emit('resetFields');
          }
        }
      }
    }, 200);
    this.initData();
  },
  methods: {
    initData() {
      let mutation;
      if (this.mutations) {
        mutation = this.mutations?.[this?.row?.id]?.find(
          (i) => i.field.replaceAll('.', '-') === this.prop,
        );
        if (mutation) {
          this.row[this.prop] = mutation.value;
        }
      }
      const dateValue = mutation?.value ?? this.formValue;
      this.dateValue = this.formatTs(dateValue);
      this.$emit('resetFields');
    },
    formatTs(value) {
      if (!isNaN(value) && `${value}`.length === 10) {
        return value * 1000;
      }
      return value;
    },
    handleChange(e) {
      const value = parseInt(+e / 1000, 10);
      this.row[this.prop] = value;
      const label = formatTime(this.formatTs(+e), this.mutation?.format || 'yyyy-MM-dd HH:ii:ss');
      this.row[`${this.prop}${PROP_LABEL}`] = label;
      this.$emit('handleChange', {
        value,
        data: {
          label,
          value,
        },
      });
    },
  },
};
