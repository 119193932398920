//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MaterialDialog from './material.vue';
import OutsideQuestions from './outsideQuestions.vue';
import LocalLayoutJson from './index.json';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { getKvConfigs } from '@/graphql/api/meeting';
import { isExpression, execExpression } from 'nges-common/src/layout/expression';

export default {
  components: {
    MaterialDialog,
    OutsideQuestions,
  },
  props: {
    // 类型
    type: {
      type: String,
      default: '', // image video audio doc outsideQuestions
    },
    // 布局
    // layoutJson: {
    //   type: Object,
    //   default: null,
    // },
    // 加载模式
    loading: {
      type: Boolean,
      default: false,
    },
    // 单选模式
    isRadio: {
      type: Boolean,
      default: false,
    },
    // 选中的图片id列表 , 仅 type: image 生效
    selectedImgList: {
      type: Array,
      default: null,
    },
    imgLimit: {
      type: Number,
      default: 0,
    },
    docLimit: {
      type: Number,
      default: 0,
    },
    // 选中的文档列表 目前仅 type: doc[关联附件]生效
    selectedDocList: {
      type: Array,
      default: () => [],
    },
    // 投票id列表 仅type: vote生效
    selectedVoteList: {
      type: Array,
      default: () => [],
    },
    // 是否在列表上, 剔除掉已选的数据
    isRemoveSelectedAtList: {
      type: Boolean,
      default: false,
    },
    // 文件后缀类型
    fileExtensionType: {
      type: String || null,
      default: null,
    },
  },
  data() {
    return {
      kvLayout: null,
      submitTip: '',
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters('user', { layoutJson: 'getLayoutJson' }),
    layoutKey() {
      return this.realType ? `${this.realType}Layout` : '';
    },
    realType() {
      return this.typeList[0];
    },
    detailType() {
      return this.typeList[1];
    },
    typeList() {
      return this.type?.split('@') || [];
    },
    json() {
      if (!this.layoutKey || !this.kvLayout) {
        return null;
      }
      // 优先取页面配置(定开才需要设置, 否则别设置)
      let json = this.layoutJson?.[this.layoutKey];
      // 再取kv(建议优先设置kv  统一配置即可, 标准化弹框配置应该都一样)
      if (!json) {
        json = this.kvLayout[this.layoutKey];
      }
      if (!json) {
        // 容错, 取本地数据
        json = LocalLayoutJson[this.layoutKey];
      }
      if (this.fileExtensionType && this.realType === 'image') {
        json?.wheres?.[0]?.fields?.push({
          field: 'name',
          value: `%${this.fileExtensionType}%`,
          where_type: '_like',
        });
      }
      if (json) {
        json = cloneDeep(json);
        if (!json.render) {
          json.render = {};
        }
        if (json.render.max_height === undefined) {
          // 不能使用vh, 只能使用px. el-table 使用vh后 不会parse, 外层正确,内层table高度错误
          // json.render.max_height = '60vh'; // 默认值,这是最佳效果, 大小小屏幕也能使用
          json.render.max_height = Math.max((window?.screen?.height || 0) * 0.5, 252);
        }
        // 发布公众号走本地数据
        const official = json.fields.find(
          (i) => i.field === 'material_official.official_accounts.name',
        );
        if (official?.filter && !official.filter.object) {
          official.filter.options =
            this.publicList?.map((i) => {
              return {
                label: i.name,
                value: i.id,
              };
            }) || [];
        }
      }

      return json;
    },
    title() {
      return this.getJsonSubKeyValue('title') || '';
    },
    subTitle() {
      return this.getJsonSubKeyValue('sub_title') || '';
    },
    selectedTip() {
      return this.getJsonSubKeyValue('selected_tip') || '';
    },
    cancelText() {
      return this.json?.cancel_text || '取消';
    },
    confirmText() {
      return this.json?.comfirm_text || '确认';
    },
    // 获取文章公众号列表
    publicList() {
      return this.$store.getters['user/getPublishList'];
    },
  },
  methods: {
    async initData() {
      try {
        const res = await getKvConfigs({ keys: ['mcm_web_layout'] }).catch(() => null);
        this.kvLayout = JSON.parse(res?.kv?.[0]?.value) || {};
      } catch (error) {
        this.kvLayout = {};
      }
    },
    getJsonSubKeyValue(key) {
      if (!this.json) {
        return '';
      }
      let value;
      const subType = this.detailType;
      if (subType) {
        value = this.json?.[`${key}@${subType}`];
      }
      if (!value) {
        value = this.json?.[key];
      }

      if (value && isExpression(value)) {
        value = execExpression(value, { t: { key, subType, location: window.location } });
      }
      return value;
    },
    handleComponentError({ error }) {
      this.submitTip = error;
    },
    handleClickConfirm() {
      // getData() 子组件必须实现
      const { error, data } = this.$refs?.[this.layoutKey]?.getData() || {};
      if (error) {
        this.$message(error);
        return;
      }
      if (!data?.length) {
        this.$message(this.selectedTip);
        return;
      }

      data.forEach((item) => {
        // 公众号删除后 存在null对象
        if (item.material_official?.length) {
          item.material_official = item.material_official.filter((i) => i?.official_accounts?.id);
        }
      });

      this.closeDialog();
      // 放在最后面, 可能外部不让其消失 对type又赋值了
      this.$emit('confirm', this.getEmitParams(data));
    },
    handleClickCancel() {
      // clearData() 子组件必须实现
      // this.$refs?.[this.layoutKey]?.clearData();
      this.closeDialog();
      this.$emit('cancel', this.getEmitParams(null));
    },
    closeDialog() {
      this.submitTip = '';
      if (this.loading) {
        this.$emit('update:loading', false);
      }
      this.$emit('update:type', '');
    },
    getEmitParams(data) {
      return { type: this.realType, detailType: this.detailType, data };
    },
  },
};
