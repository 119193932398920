//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isArray, has, includes, isNumber } from 'lodash';
import multipleSelect from './multipleSelect';
import cascader from './cascader';
import subordinate from '../../subordinate/index';
import subordinateCascader from '../../subordinate/cascader';
import { execExpression, execExpressionDeep } from '../../../../layout/expression';
import { formatTime } from '../../../utils/util';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';
import tree from './tree';

const _ = { isArray };

let closeFuncSet = new Set();
let isCloseListening = false;

export default {
  components: {
    multipleSelect,
    cascader,
    subordinate,
    subordinateCascader,
    tree,
  },
  mixins: [TrackingCompMixin],
  props: {
    // 最小值
    min: {
      type: [Number, String],
      default: '',
    },
    // 最大值
    max: {
      type: [Number, String],
      default: '',
    },
    item: {
      type: [Object],
      default: () => {},
    },
    filters: {
      type: [Array],
      default: () => [],
    },
    // 自定义筛选对象
    customFilters: {
      type: [Object, void 0],
      default: () => {},
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: () => undefined,
    },
    expParamsObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      form: {
        value: this.value,
        min: this.min,
        max: this.max,
      },
    };
  },
  computed: {
    // 自定义筛选slot唯一key
    filterSlot() {
      return this.getColumnFilter(this.item).filter.slot;
    },
    // 弹出框类型
    valueType() {
      return this.getColumnFilter(this.item).filter.value_type;
    },
    // value() {
    //   return this.getColumnFilter(this.item).filter.selected;
    // },
    options() {
      return this.getColumnFilter(this.item).filter.options;
    },
    filter() {
      return this.getColumnFilter(this.item).filter;
    },
    isSingleSelect() {
      return this.options && this.valueType === 'SELECT_ONE';
    },
    width() {
      if (this.valueType === 'SUBORDINATE' && this.subordinateType === 'cascader') {
        return 'auto';
      }
      return ['CASCADER', 'TREE'].includes(this.valueType)
        ? 'auto'
        : ['INT', 'DATETIME'].includes(this.valueType)
        ? 400
        : 250;
    },
    hasFilter() {
      return _.isArray(this.form.value)
        ? this.form.value.length
        : this.form.value !== undefined &&
            !(this.isSingleSelect && this.form.value === 'all') &&
            `${this.form.value}`.length;
    },
    isDate() {
      return ['WEEK', 'MONTH', 'YEAR', 'DATES', 'MONTHS', 'YEARS', 'MONTHRANGE', 'DATE'].includes(
        this.valueType,
      );
    },
    timeDisableds() {
      return this.filter?.disables || [];
    },
    timeDisableFunc() {
      return this.filter?.filter?.disable_func;
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const valueType = this.valueType?.toLowerCase();
          let formatStr = '';
          if (includes(valueType, 'year')) {
            formatStr = 'yyyy';
          } else if (includes(valueType, 'month')) {
            formatStr = 'yyyy-mm';
          } else if (includes(valueType, 'date')) {
            formatStr = 'yyyy-mm-dd';
          }
          if (!formatStr) {
            return false;
          }
          const currentMonth = formatTime(time, formatStr);
          if (this.timeDisableFunc) {
            return execExpression(this.timeDisableFunc, {
              t: {
                value: currentMonth,
                ...this.expParamsObj,
              },
            });
          }
          return this.timeDisableds.some((disabledMonth) => disabledMonth === currentMonth);
        },
        onPick: this.onPick,
      };
    },
    showConfirmButton() {
      return !this.isSingleSelect && this.valueType !== 'COMPONENT';
    },
    subordinateType() {
      return this.filter?.type;
    },
    descDelimiter() {
      return this.filter?.desc_delimiter || '';
    },
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
    customFilters: {
      handler(nv) {
        if (this.filterSlot) {
          let { where_gql: whereGql } = this.filter || {};
          if (whereGql) {
            whereGql = execExpression(whereGql, {
              t: nv[this.filterSlot],
            });
            this.handleEmitWhereGql(whereGql);
          } else {
            this.$emit('change', nv[this.filterSlot]);
          }
          this.debounceFilterOnSubmit();
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    const refKeys = Object.keys(this.$refs);
    refKeys.forEach((key) => {
      if (this.$refs[key]) {
        this.$refs[key] = null;
      }
    });
    this.removeCloseListen();
    this.removeScrollListen();
  },
  mounted() {
    this.setValue(this.value);
    this.addCloseListen();
    this.addScrollListen();
  },
  methods: {
    setValue(val) {
      let formValue = val;
      if (val) {
        if (this.isDate && isNumber(val)) {
          formValue = val * 1000;
        } else if (isArray(val) && (this.valueType === 'DATETIME' || this.isDate)) {
          formValue = val.map((item) => {
            return new Date(isNumber(item) ? item * 1000 : item);
          });
        }
      }
      this.form.value = formValue;
    },
    getPlaceholder(defaultStr, idx) {
      const placeholder = this.filter?.placeholder;
      if (isArray(placeholder) && idx !== undefined) {
        return placeholder[idx] || defaultStr;
      }
      return placeholder || defaultStr;
    },
    getTbody() {
      return this.$parent.$parent.$el.querySelector('.el-table__body-wrapper');
    },
    // 增加页面滚动监听
    addScrollListen() {
      this.getTbody().addEventListener('scroll', this.handleScroll);
    },
    handleScroll() {
      if (this.visible) {
        this.visible = false;
      }
    },
    removeScrollListen() {
      this.getTbody().removeEventListener('scroll', this.handleScroll);
    },
    // 增加关闭事件监听
    addCloseListen() {
      closeFuncSet.add(this.doClose);
      // 只监听一次
      if (!isCloseListening) {
        document.addEventListener('click', this.handleClose);
        isCloseListening = true;
      }
    },
    handleClose(event) {
      // 组件外部被点击
      if (!this.$refs?.popover?.$el?.contains(event.target)) {
        closeFuncSet.forEach((closeFunc) => {
          closeFunc();
        });
      }
    },
    removeCloseListen() {
      closeFuncSet = new Set();
      isCloseListening = false;
      document.removeEventListener('click', this.handleClose);
    },
    resetFilter() {
      if (has(this.filter, 'where_gql')) {
        this.filter.where_gql = this.getWhereGql();
      }
      this.$emit('resetFilter');
      // reset后重新赋值给组件的from
      this.$nextTick(() => {
        this.form.value = this.value;
        this.form.min = this.min;
        this.form.max = this.max;
      });
      if (this.$refs.cascader) {
        this.$refs.cascader.searchText = '';
        this.$refs.cascader.clearCheckedNodes();
      } else if (this.$refs['select-many']) {
        this.$refs['select-many'].searchText = '';
        this.$refs['select-many'].clearCheckedNodes();
      } else if (this.$refs.subordinate) {
        this.$refs.subordinate.reset();
      } else if (this.$refs.tree) {
        this.$refs.tree.clear();
      }
    },
    filterOnSubmit() {
      if (this.showConfirmButton) {
        if (this.isDate) {
          this.valueChangeWithWhereGql(this.form.value);
        } else {
          this.$emit('change', this.form.value);
        }
      }
      this.$emit('filterOnSubmit');
    },
    debounceFilterOnSubmit() {
      this.$emit('debounceFilterOnSubmit');
    },
    filterClick() {
      if (this?.filter?.mode === 'left') return;
      this.visible = !this.visible;
      this.$emit('filterClick');
    },
    // valueChange(val) {
    //   this.$emit('change', val);
    // },
    valueChange(val) {
      // 如果有确认按钮，就等确认按钮按下时才发送 change事件。
      // 否则直接发送。
      if (!this.showConfirmButton) {
        this.$emit('change', val);
      }
      this.form.value = val;
    },
    valueChangeWithWhereGql(val) {
      let whereGql = this.getWhereGql();
      let t = val;
      if (isArray(val)) {
        t = val.map((v) => {
          // 毫秒时间戳
          if (`${v}`.length === 13) {
            return parseInt(v / 1000, 10);
          }
          return v;
        });
      } else if (`${val}`.length === 13) {
        t = parseInt(val / 1000, 10);
      }
      if (whereGql) {
        whereGql = execExpression(whereGql, {
          t,
          $filters: this.filters,
        });
        this.handleEmitWhereGql(whereGql);
      }
      this.$emit('change', t);
    },
    getWhereGql() {
      if (!this.originSelectedExp) {
        this.originSelectedExp = this.filter?.$whereGql || this.filter?.where_gql;
      }
      return this.originSelectedExp;
    },
    // 左侧筛选器不emit出去
    handleEmitWhereGql(val) {
      this.filter?.mode !== 'left' && this.$emit('whereGqlChange', val);
    },
    whereGqlChange(val) {
      this.handleEmitWhereGql(val);
    },
    singleSelectChange(val) {
      this.valueChange(val);
      this.filterOnSubmit();
    },
    minChange(val) {
      const newValue = isArray(this.form.value) ? this.form.value : [];
      newValue[0] = val;
      this.valueChange(newValue);
      this.tracking(newValue, 'input');
    },
    maxChange(val) {
      const newValue = isArray(this.form.value) ? this.form.value : [];
      newValue[1] = val;
      this.valueChange(newValue);
      this.tracking(newValue, 'input');
    },
    doClose() {
      this.$refs.popover?.doClose();
    },
    getColumnFilter(column) {
      const { field } = column;
      if (field) {
        const columnFilter = this.filters.filter((item) => item.field === field)[0];
        if (column.value_type && columnFilter) {
          columnFilter.filter.value_type = column.value_type;
        }
        return columnFilter;
      }
    },
    onPick({ maxDate, minDate }) {
      if (maxDate && this.timeDisableds.length) {
        const isInvalid = this.timeDisableds.find((item) => {
          const disabledDate = new Date(formatTime(item, 'yyyy-mm-dd hh:ii:ss'));
          return disabledDate >= minDate && disabledDate <= maxDate;
        });
        if (isInvalid) {
          this.$message('区间不能包含禁用的月份');
          throw Error('区间不能包含禁用的月份');
        }
      }
    },
    execExpression(obj) {
      return execExpressionDeep(obj, this.expParamsObj);
    },
    tracking(value, type = 'select') {
      this.__compTracking(
        {
          event_type: type,
          event_id: `${type}_Event`,
          event_data_content: value,
          event_name: `${this.execExpression(this.item?.label) || ''}${
            type === 'select' ? '选择' : '输入'
          }`,
        },
        this.execExpression(this.filter?.tracking),
      );
    },
    showHandler() {
      this.$refs['select-many']?.open();
    },
  },
};
