import { isExpression as isExp, execExpression } from '../../../layout/expression';
import {
  isString,
  includes,
  set,
  get,
  isObject,
  forEach,
  isNil,
  has,
  isPlainObject,
  cloneDeep,
  endsWith,
} from 'lodash';
import { cmdActionRequest } from './cmd-action';
import { graphqlActionRequest } from './graphql-action';
import {
  CONFIRM_ACTION,
  NAVIGATION_ACTION,
  NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH,
  NOTICE_ACTION,
  COMMON_GRAPHQL_ACTION,
  COMMON_GRAPHQL_ACTION_NAME_ENUM,
  APPROVAL_ACTIONS,
  SELECT_CONFIRM,
  ACTION_MSG_PREFIX,
  VARIATE_ACTION,
  NOT_COPY_FIELDS,
} from './constant';
import { confirmAction } from './confirm-action';
import { setPageData } from './page-data';
import { replaceParams } from '../../../web-mobile/util';
import { noticeAction } from './notice-action';
import { msgAction } from './msg-action';
import { downloadAction } from './download-action';
import { applyAction, appealAction } from './approval-action';
import { clipboardAction } from './clipboard-action';
import EmitAction from '../emit';
import { globalSharePopup } from './share-popup/util';
import { selectConfirmDialog } from './select-confirm-dialog/util';
import { dataHistoryDrawer } from './data-history-drawer/util';
import { variateAction } from './variate-action';
import { globalConfig as globalDetailDialog } from './detail-dialog/util';
const _ = {
  isString,
  includes,
  set,
  get,
  isObject,
  forEach,
  isNil,
  has,
  isPlainObject,
  cloneDeep,
  endsWith,
};

let loading = null;
let loadingSeq = 1;

export function isNavigationAction(action) {
  const { action: actionName } = action;
  return (
    _.isString(action?.action_params?.object) &&
    _.isString(actionName) &&
    _.includes(NAVIGATION_ACTION, actionName)
  );
}

function isNoticeAction({ action }) {
  return _.isString(action) && _.includes(NOTICE_ACTION, action);
}

function isCommonGqlAction({ action }) {
  return _.isString(action) && _.includes(COMMON_GRAPHQL_ACTION, action);
}

function isConfirmAction({ action }) {
  return _.isString(action) && _.includes(CONFIRM_ACTION, action);
}

function isMsgAction({ action }) {
  return _.isString(action) && action.indexOf(ACTION_MSG_PREFIX) === 0;
}

function isApprovalAction({ action }) {
  return _.isString(action) && _.includes(APPROVAL_ACTIONS, action);
}

function isSelectConfirmAction(action) {
  action = action?.action;
  return _.isString(action) && _.includes(SELECT_CONFIRM, action);
}
function isVariateAction({ action }) {
  return _.isString(action) && _.includes(VARIATE_ACTION, action);
}
// 兼容loading和toast同时发起时的神奇冲突问题。
function hideLoading(seq) {
  setTimeout(() => {
    if (!seq || seq === loading?.__seq) {
      loading && loading.close();
    }
  });
}

function setProcessed(action, v) {
  _.set(action, '__process__.processed', v);
}

function getProcessed(action) {
  return _.get(action, '__process__.processed');
}

// 重写解析表达式方法，不解析action_callback
export function execExpressionAction(obj, paramsObj = {}) {
  if (_.isObject(obj)) {
    // SELECT_CONFIRM 的 actions 如果存在 SELECT_CONFIRM_ACTION_ID 不能提前计算表达式
    // 有些事件不能提前执行(参数不全), 比如 COPY_OBJECT 需要使用的时候手动执行表达式
    if (obj.SELECT_CONFIRM_ACTION_ID || obj.__auto_exec_expression__ === false) {
      return obj;
    }

    _.forEach(obj, (value, idx) => {
      const black = ['response', 'navigate_callback', 'detail_dialog_json'];
      // action_callback需等数据返回后处理，故此处跳过
      // 此处对action_callback结尾的action都不做表达式处理，主要用于外层有阻断性的调用时，可以在阻断性的action执行完以后再执行下一步
      // 举例：有一个按钮，调用了一个自定义action(此action需要发起复杂的请求)，且有action_callback,此时可在外部将action_callback改名为a_action_callback, 这样，action_callback不会被立即执行，且其中的表达式也不会被立即计算，当复杂的自定义action执行完后，由外部调m_actionHandle方法主动触发a_action_callback,从而实现串行等待执行。
      if (!_.endsWith(idx, 'action_callback') && !black.includes(idx)) {
        const execResult = execExpressionAction(value, paramsObj);
        obj[idx] = execResult;
      }
    });
    return obj;
  }
  const result = execExpression(obj, paramsObj);
  return result;
}

// 系统 回退action
function goBack(delta) {
  // 与小程序端保持一致，delta传入正数，表示后退的数量，web端需要取负数
  let historyDelta = -1 * delta;
  if (isNaN(historyDelta)) {
    historyDelta = -1;
  }
  this.$router?.go(historyDelta);
}

// 获取对应的白板页
function getLayoutTmplPath(actionName = '') {
  if (actionName.indexOf('LIST') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.list;
  }
  if (actionName.indexOf('DETAIL') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.detail;
  }
  if (actionName.indexOf('CREATE') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.create;
  }
  if (actionName.indexOf('EDIT') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.edit;
  }
}

function getLayoutType(actionName = '') {
  return actionName.replace('TO_', '').toLowerCase();
}

export default {
  mixins: [EmitAction],
  data() {
    return {
      selectConfirmInitAction: null,
      cacheVariateKeys: [],
    };
  },
  unmounted() {
    this.cacheVariateKeys.forEach((key) => {
      this.$store.commit('action/setCacheVariate', { key, value: undefined });
    });
  },
  destroyed() {
    this.cacheVariateKeys.forEach((key) => {
      this.$store.commit('action/setCacheVariate', { key, value: undefined });
    });
  },
  methods: {
    m_loading() {
      loading = this.$loading();
      loading.__seq = loadingSeq;
      loadingSeq += 1;
      return loading.__seq;
    },
    // 跳转标准布局页
    goToTemplateLayout(action) {
      const { action_params: params, action: actionName } = action || {};
      const { object, id, record_type: recordType = '' } = params || {};
      let tplPath = getLayoutTmplPath(actionName);
      const layoutType = getLayoutType(actionName);
      let pathParams = `?path=${object}_${layoutType}&object=${object}&record_type=${recordType}`;
      if (!_.isNil(id)) {
        // 列表和新建不往路径中注入id
        if (!includes(['create', 'list'], layoutType)) {
          pathParams = `${pathParams}&id=${id.toString()}`;
        }
      } else if (_.has(params, 'sub_form_data')) {
        let { page_id: pageUniqId } = params || {};
        const { sub_form_data: subFormData } = params || {};
        const { form_value: formValue, form_data: formData } = params || {};
        pageUniqId = setPageData({
          pageUniqId,
          data: { subFormData, formValue, formData },
        });
        // 跳转后的页面通过pageKey获取需要的数据
        pathParams = `${pathParams}&pageUniqId=${pageUniqId}`;
      }
      tplPath = `${tplPath}${pathParams}`;
      setTimeout(() => {
        if (!getProcessed(action)) {
          setProcessed(action, true);
          this.g_tourl(tplPath);
        }
      });
    },
    async m_doActionHandle(action = {}, expParamsObj) {
      if (getProcessed(action)) {
        return;
      }
      const shouldUpdateContext =
        action.action === 'APPLY' ||
        action.action === 'APPEAL' ||
        action?.cmd ||
        isCommonGqlAction(action);
      const actionCallback = async (context, callbackAction = null) => {
        // 若有action_callback，进入后续action
        const actionCallbackContent = callbackAction || action?.action_callback;
        if (actionCallbackContent) {
          const newExpParamsObj = this.getActionContext({
            context,
            pExpParams: expParamsObj,
          });
          let newActionCallback;
          // 直接emit action，不做任何复杂逻辑，大action有性能问题
          if (!actionCallbackContent?.action_params?.force_emit) {
            newActionCallback = execExpressionAction(actionCallbackContent, newExpParamsObj);
            newActionCallback
              = (await this.m_customAction(newActionCallback)) || newActionCallback || {};
          } else {
            newActionCallback = isPlainObject(actionCallbackContent)
              ? { ...actionCallbackContent }
              : actionCallbackContent;
          }
          await this.m_doActionHandle(newActionCallback, newExpParamsObj);
          // 透过action_callback发起的，需将事件往外抛出
          this.$emit('action-handle', newActionCallback);
        }
      };
      // 直接emit action，不做任何复杂逻辑，大action有性能问题
      if (!action?.action_params?.force_emit) {
        this.setSCMAction(action, expParamsObj || this.expParamsObj || {});
      }

      // 若是带了判断规则，则做判断，只有通过了判断，才执行当前action;
      if (action?.continue !== undefined) {
        const cont = execExpressionAction(action.continue, expParamsObj || this.expParamsObj || {});
        if (cont === true) {
          setProcessed(action, true);
          await actionCallback(shouldUpdateContext ? {} : undefined);
          return;
        }
      }
      if (action?.rule !== undefined) {
        const ruleRes = execExpressionAction(action.rule, expParamsObj || this.expParamsObj || {});
        if (ruleRes !== true) {
          setProcessed(action, true);
          if (isString(ruleRes)) {
            throw new Error(ruleRes.toString());
          } else {
            return;
          }
        }
      }
      let context = {};
      if (!isExp(action?.url) && action?.url) {
        setProcessed(action, true);
        const { action_params: params } = action || {};
        if (_.has(params, 'sub_form_data')) {
          const { sub_form_data: subFormData } = params || {};
          let { page_id: pageUniqId } = params || {};
          const { form_value: formValue, form_data: formData } = params || {};
          pageUniqId = setPageData({
            pageUniqId,
            data: { subFormData, formValue, formData },
          });
          action.url = replaceParams({ pageUniqId }, action.url);
        }

        // 同时兼容列表格式与对象格式
        if (params?.navigate_callback && params.navigate_callback.length && this.addEmitListener) {
          params.navigate_callback.forEach((callback) => {
            const id = this.addEmitListener(callback.navigate_callback);
            action.url = replaceParams(
              {
                [`emit_after${callback.callback_hook}`]: id,
              },
              action.url,
            );
          });
        } else if (params?.navigate_callback && params?.callback_hook && this.addEmitListener) {
          const id = this.addEmitListener(params.navigate_callback);
          action.url = replaceParams(
            {
              [`emit_after${params.callback_hook}`]: id,
            },
            action.url,
          );
        }
        this.g_tourl(action.url, {
          href: params?.href,
          open: params?.open,
          replace: params?.replace,
        });
      } else if (isNavigationAction(action)) {
        this.goToTemplateLayout(action);
      } else if (isNoticeAction(action)) {
        setProcessed(action, true);
        await noticeAction(action);
      } else if (isApprovalAction(action)) {
        setProcessed(action, true);
        if (action.action === 'APPLY') {
          try {
            const seq = this.m_loading();
            const resp = await applyAction(action);
            if (this.getDetail) {
              await this.getDetail();
            } else if (this.$parent?.getDetail) {
              await this.$parent?.getDetail();
            }
            hideLoading(seq);
            _.set(action, 'action_params._response', resp);
            context = resp || {};
            // this.m_doActionCallback({ action, context: resp });
          } catch (e) {
            hideLoading();
            throw e;
          }
        } else if (action.action === 'APPEAL') {
          try {
            const seq = this.m_loading();
            const resp = await appealAction(action);
            if (this.getDetail) {
              await this.getDetail();
            } else if (this.$parent?.getDetail) {
              await this.$parent?.getDetail();
            }
            hideLoading(seq);
            _.set(action, 'action_params._response', resp);
            context = resp || {};
            // this.m_doActionCallback({ action, context: resp });
          } catch (e) {
            hideLoading();
            throw e;
          }
        } else {
          function searchDetailContentRef(obj, findkey) {
            if (obj[findkey]) {
              return obj;
            }
            if (typeof obj.$parent === 'object') {
              return searchDetailContentRef(obj.$parent, findkey);
            }
          }
          const that = searchDetailContentRef(this, 'handleMountApprovalCenter');
          that.handleMountApprovalCenter?.();

          this.$nextTick(() => {
            console.log('this====', this);
            console.log('that====', that);
            // 当前 action 有可能被被传入到了 mbutton，this 指向会有变化，因此用$parent兼容
            that[`${action.action}_CUSTOM_ACTION`]?.(action.action_params);
          });
        }
      } else if (action?.cmd) {
        if (action?.ignore) return;
        setProcessed(action, true);
        try {
          const seq = this.m_loading();
          let resp = await cmdActionRequest.call(this, action);
          hideLoading(seq);
          if (action?.response) {
            const newExpParamsObj = this.getActionContext({
              context: resp || {},
              pExpParams: expParamsObj,
            });
            resp = execExpressionAction(action.response, newExpParamsObj);
          }
          _.set(action, 'action_params._response', resp);
          context = resp || {};
          // this.m_doActionCallback({ action, context: resp });
        } catch (e) {
          hideLoading();
          throw e;
        }
      } else if (isCommonGqlAction(action)) {
        // 调用graphql接口
        setProcessed(action, true);
        try {
          const seq = this.m_loading();
          const resp = await graphqlActionRequest.call(this, action);
          hideLoading(seq);
          _.set(action, 'action_params._response', resp);
          context = resp || {};
          if (!action?.action_params?.ignore_message) {
            this.$message.success(`${COMMON_GRAPHQL_ACTION_NAME_ENUM[action.action]}成功`);
          }
          // this.m_doActionCallback({ action, context: resp });
        } catch (e) {
          this.$message.error(`${COMMON_GRAPHQL_ACTION_NAME_ENUM[action.action]}失败`);
          hideLoading();
          throw e;
        }
      } else if (action?.action === 'COPY_OBJECT') {
        // 复制当前对象 (先查询全量数据 -> 过滤掉黑名单数据 -> 处理单个字段数据 -> 调用graphql_insert接口)
        setProcessed(action, true);
        try {
          // 获取查询参数
          const getQueryGgl = function (actionParams, paramsObj) {
            const { id, object } = actionParams;
            if (!object) {
              // 这里配置有问题, 提醒ASP
              hideLoading();
              throw new Error(`object参数缺失, object: ${object}`);
            }

            // 如果是自定义graphql_str 直接读取即可
            actionParams.graphql_str = execExpression(actionParams.graphql_str || '', paramsObj);
            if (actionParams.graphql_str) {
              // mcm_vote_topic_option(vote_id:\"${$action_params.id}\"){_all}
              return actionParams.graphql_str;
            }

            if (!id) {
              // 这里配置有问题, 提醒ASP
              hideLoading();
              throw new Error(`id参数缺失, id: ${id}`);
            }
            actionParams.query_fields =
              execExpression(actionParams.query_fields || [], paramsObj) || [];
            return `${object} (id: "${id}") { ${actionParams.query_fields.join(',') || '_all'} }`;
          };

          const getCopyFields = function (objectData, actionParams, paramsObj) {
            // 查询黑名单忽略字段
            actionParams.ignore_fields = execExpression(
              actionParams.ignore_fields || [],
              paramsObj,
            );
            const ignoreFields = [...NOT_COPY_FIELDS, ...(actionParams.ignore_fields || [])];
            const modifyFields = actionParams.modify_fields;
            // 处理数据(黑名单 +  修改字段)
            const fields = {};
            Object.keys(objectData).forEach((key) => {
              // 忽略掉配置的字段
              if (ignoreFields.includes(key)) {
                return;
              }
              if (modifyFields[key] !== undefined) {
                fields[key] = execExpression(modifyFields[key], {
                  ...paramsObj,
                  $value: objectData[key],
                });
              } else {
                fields[key] = objectData[key];
              }
            });
            return fields;
          };

          // 初始化数据
          const seq = this.m_loading();
          const actionParams = _.cloneDeep(action.action_params || {}); // 复制一份
          const newExpParamsObj = { ...(expParamsObj || {}), $action_params: actionParams }; // 浅拷贝即可
          actionParams.id =
            actionParams.id ||
            newExpParamsObj.t?.targetId
            || newExpParamsObj.t?.id ||
            newExpParamsObj.t?.p?.id ||
            newExpParamsObj.$item?.id; // 兼容 列表 详情 mutation弹框 二次确认弹框逻辑
          actionParams.object = actionParams.object || newExpParamsObj.json?.object;
          actionParams.sub_objects = actionParams.sub_objects || [];

          //  ① 先查询object/subject 全量 或者 配置数据
          const queryGgl = [getQueryGgl(actionParams, newExpParamsObj)]; // 默认主表
          actionParams.sub_objects.forEach((subActionParams, index) => {
            // 子表查询  支持自定义查询语句(可能存在多张表)
            subActionParams.$parent = actionParams;
            subActionParams.id = execExpression(subActionParams.id, {
              ...newExpParamsObj,
              $index: index,
              $action_params: subActionParams,
            });
            const query = getQueryGgl(subActionParams, newExpParamsObj);
            if (query) {
              queryGgl.push(query);
            }
          });
          const resp = await graphqlActionRequest.call(this, {
            action_params: {
              graphql_str: `query { ${queryGgl.join('\n')} }`,
            },
          });

          // 保存数据
          const objectData = resp?.[actionParams.object]?.[0];
          newExpParamsObj.$item = objectData; // 主表只支持唯一查询
          newExpParamsObj.$action_params.response = resp;
          _.set(action, 'action_params._response', resp);

          // ② 获取处理复制完成的字段
          const subFields = {};
          const fields = getCopyFields(objectData, actionParams, newExpParamsObj);
          actionParams.sub_objects.forEach((subActionParams) => {
            const subField = subActionParams.sub_field; // 子表需要写入的字段, 比如id
            if (!subField) {
              return;
            }
            // 子表查询
            const isArrayObject = subActionParams.object_type === 'array';
            let dataList = resp?.[subActionParams.object];
            if (!dataList) {
              // 关联对象为空
              return;
            }
            if (!isArrayObject && dataList.length > 1) {
              dataList = [dataList[0]];
            }

            const newFields = [];
            // 支持字段
            dataList.forEach((data, index) => {
              const fields = getCopyFields(data, subActionParams, {
                ...newExpParamsObj,
                $index: index,
                $item: data,
                $action_params: subActionParams,
              });
              newFields.push(fields);
            });
            subFields[subField] = isArrayObject ? newFields : newFields[0];
          });

          // ③ 调用graphql_insert接口(需要使用 subFields)
          // mutation insert {
          //   table_name(_values: {name: ""}) {
          //   	sub_field (_values:{name: "" })
          //   }
          // }
          await graphqlActionRequest.call(this, {
            action: 'GRAPHQL_DCR_CREATE',
            action_params: { object: actionParams.object, fields, subFields },
          });

          hideLoading(seq);
          context = resp || {};
          if (actionParams.tip_msg || !actionParams.ignore_message) {
            this.$message.success(actionParams.tip_msg || `${action.label || '复制'}成功`);
          }
        } catch (e) {
          this.$message.error(e.message || e);
          hideLoading();
          throw e;
        }
      } else if (isMsgAction(action)) {
        setProcessed(action, true);
        await msgAction.call(this, action);
      } else if (isConfirmAction(action)) {
        setProcessed(action, true);
        const confirmRule = action?.action_params?.rule;
        if (confirmRule || confirmRule === undefined) {
          const confirm = action.m_confirmAction
            ? await this.m_confirmAction(action)
            : await confirmAction.call(this, action);
          action.__confirm__ = confirm;
          if (!confirm) {
            if (action.cancel_action_callback) {
              await actionCallback(context, action.cancel_action_callback);
            }
            this.$emit('action-handle', action);
            return false;
          }
        }
      } else if (isSelectConfirmAction(action)) {
        setProcessed(action, true);
        const act = this.findSelectConfirmAction(
          this.selectConfirmInitAction,
          'action',
          'SELECT_CONFIRM',
          expParamsObj || this.expParamsObj,
          action.SELECT_CONFIRM_ID,
        );
        const emitCallback = () => this;
        selectConfirmDialog.open(
          action,
          expParamsObj || this.expParamsObj,
          _.cloneDeep(act),
          emitCallback,
        );
        return;
      } else if (action?.action === 'CLIPBOARD') {
        setProcessed(action, true);
        const cliboadrRes = await clipboardAction({ action });
        action.__cliboard__ = cliboadrRes;
      } else if (action?.action === 'BACK') {
        setProcessed(action, true);
        const delta = action?.action_params?.delta;
        goBack.call(this, delta || 1);
      } else if (action?.action === 'WINDOW_CLOSE') {
        setProcessed(action, true);
        window.close();
      } else if (action?.action === 'SHARE_DATA') {
        setProcessed(action, true);
        globalSharePopup.open(action);
      } else if (action?.action === 'DATA_HISTORY') {
        setProcessed(action, true);
        const scopeExpParams = expParamsObj || this.expParamsObj;
        dataHistoryDrawer.open({
          ...action,
          action_params: {
            object: scopeExpParams?.json?.object,
            id: scopeExpParams?.t?.id,
            ...action?.action_params,
          },
        });
      } else if (action?.action === 'DOWNLOAD') {
        setProcessed(action, true);
        await downloadAction.call(this, action);
      } else if (isVariateAction(action)) {
        const { action_params: actionParams, action: actionName } = action;
        const { key } = actionParams;
        if (actionName === 'SET_VARIATE') {
          this.cacheVariateKeys.push(key);
        }
        context = variateAction(action, this.$store.state.action.cacheVariate, this.$store.commit);
        setProcessed(action, true);
        console.log('------context------', context);
      } else if (action?.action === 'DETAIL_DIALOG') {
        setProcessed(action, true);
        globalDetailDialog.open(action);
      } else if (typeof action?.CUSTOM_ACTION === 'function') {
        /**
         * TODO:该逻辑分支必须处在最后一个
         */
        setProcessed(action, true);
        context = (await action.CUSTOM_ACTION(action)) || {};
        action.CUSTOM_ACTION = null;
      }

      await actionCallback(context);
    },
    // selectConfirmAction 分步提交数据将json暂存，防止 execExpressionAction 提前计算表达式
    setSCMAction(action, expParamsObj) {
      // SELECT_CONFIRM  生成唯一ID
      let idCounter = 1;
      function assignUniqueIds(obj) {
        if (obj instanceof Array) {
          obj.forEach((item) => assignUniqueIds(item));
        } else if (typeof obj === 'object' && obj !== null) {
          Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (typeof value === 'object') {
              assignUniqueIds(value);
            } else if (key === 'action') {
              const expressionRes = isExp(value) ? execExpression(value, expParamsObj) : value;
              if (expressionRes !== 'SELECT_CONFIRM') {
                return;
              }
              if (!obj.SELECT_CONFIRM_ID) {
                obj.SELECT_CONFIRM_ID = idCounter;
                if (obj.actions?.length) {
                  obj.actions.forEach((i, index) => {
                    i.SELECT_CONFIRM_ACTION_ID = `${idCounter}-${index}`;
                  });
                }
                idCounter += 1;
                // show属性不提前计算
                if (obj.action_params?.formItem?.length) {
                  obj.action_params?.formItem.forEach((i) => {
                    i.show = {
                      show: Object.prototype.hasOwnProperty.call(i, 'show') ? i.show : true,
                      SELECT_CONFIRM_ACTION_ID: true,
                    };
                  });
                }
              }
            }
          });
        }
      }
      if (~JSON.stringify(action).indexOf('SELECT_CONFIRM')) {
        assignUniqueIds(action);
        this.selectConfirmInitAction = _.cloneDeep(action);
      }
    },
    findSelectConfirmAction(obj, targetKey, targetValue, expParamsObj, SELECT_CONFIRM_ID) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            const result = this.findSelectConfirmAction(
              value,
              targetKey,
              targetValue,
              expParamsObj,
              SELECT_CONFIRM_ID,
            );
            if (result) {
              return result;
            }
          } else if (key === targetKey && obj.SELECT_CONFIRM_ID === SELECT_CONFIRM_ID) {
            // action 为表达式场景
            const expressionRes = isExp(value) && execExpression(value, expParamsObj);
            if (value === targetValue || expressionRes === targetValue) {
              return obj;
            }
          }
        }
      }
      return null;
    },
    // 获取action对象的上下文对象
    getActionContext({ context = {}, pExpParams }) {
      const expParamsObj = pExpParams ? pExpParams : this.expParamsObj;
      return expParamsObj
        ? {
            ...expParamsObj,
            t: {
              ...context,
              p: expParamsObj?.t, // 将外层数据置为其父数据
            },
          }
        : {
            t: { ...context },
          };
    },
    // 回调action
    // async m_doActionCallback({ action, context = {} }) {
    //   const paramsObj = this.getActionContext({ context });
    //   const actionCallback = execExpressionAction(action?.action_callback, paramsObj) || {};
    //   if (_.includes(CONFIRM_ACTION, actionCallback?.action)) {
    //     const confirm = action.m_confirmAction
    //       ? await this.m_confirmAction.call(this, actionCallback)
    //       : await confirmAction.call(this, actionCallback);
    //     if (confirm) {
    //       const inActionCallback
    //         = execExpressionAction(actionCallback?.action_callback, paramsObj) || {};
    //       this.m_doActionHandle(inActionCallback);
    //     }
    //   } else {
    //     this.m_doActionHandle(actionCallback);
    //   }
    //   // 抛出callback逻辑
    //   this.$emit('action-handle', actionCallback);
    // },
    async m_doPreActionHandle(action = {}) {
      try {
        if (getProcessed(action?.pre_action?.action_callback)) {
          return true;
        }
        let resp = {};
        if (action?.pre_action?.cmd) {
          setProcessed(action.pre_action, true);
          const seq = this.m_loading();
          resp = await cmdActionRequest.call(this, action?.pre_action);
          hideLoading(seq);
          if (!action.pre_action?.action_params) {
            action.pre_action.action_params = {};
          }
          action.pre_action.action_params._response = resp;
        }
        const callback = action?.pre_action?.action_callback;
        if (callback) {
          setProcessed(callback, true);
          const paramsObj = this.getActionContext({ context: resp });
          const result = execExpressionAction(callback, paramsObj);
          // 若传入的判断方法不是对象，则直接判断其表达式是否为true，表示是否通过前置action
          if (!_.isPlainObject(callback)) {
            if (result !== true && result !== undefined) {
              throw new Error(result.toString());
            }
          } else {
            // 若传入的是对象，则判断对象的rule是否为true，表示是否通过前置action;
            if (result?.rule !== true && result?.rule !== undefined) {
              throw new Error(result.rule.toString());
            }
            if (_.includes(CONFIRM_ACTION, result?.action)) {
              const confirmRule = result?.action_params?.rule;
              if (confirmRule || confirmRule === undefined) {
                // 通过前置action，若带了alert或confirm等提示操作，则先执行
                const confirm = action.m_confirmAction
                  ? await this.m_confirmAction.call(this, result)
                  : await confirmAction.call(this, result);
                action.__confirm__ = confirm;
                if (!confirm) {
                  this.$emit('action-handle', action);
                  return false;
                }
              }
            }
          }
        }
      } catch (e) {
        hideLoading();
        throw e;
      }
      return true;
    },
    // 可由组件集成重写的方法
    async m_customAction(data) {
      return data;
    },
    async m_confirmAction() {},
    async m_actionHandle(action = {}, stopEmit = false, expParamsObj) {
      let deepAction;
      let newExpParamsObj;
      // 直接emit action，不做任何复杂逻辑，大action有性能问题
      if (!action?.action_params?.force_emit) {
        deepAction = _.cloneDeep(action);

        if (action.__process__) {
          deepAction.__process__ = action?.__process__; // __process__对象需透传，不做深拷贝
        } else {
          deepAction.__process__ = {};
        }
        newExpParamsObj = expParamsObj || this.expParamsObj || {};
        this.setSCMAction(deepAction, newExpParamsObj);
        deepAction = execExpressionAction(deepAction, newExpParamsObj) || deepAction;
        deepAction = (await this.m_customAction(deepAction)) || deepAction || {};
      } else {
        deepAction = { ...action };
      }
      if (!getProcessed(deepAction)) {
        if (!isExp(deepAction.open_type) && !deepAction?.open_type) {
          try {
            if (!(await this.m_doPreActionHandle(deepAction))) {
              return;
            }
            await this.m_doActionHandle(deepAction, newExpParamsObj);
          } catch (e) {
            setProcessed(deepAction, true);
            setTimeout(() => {
              this.$message.error(e.message || '请求action失败');
            });
            return e;
          }
        }
      }
      // 直接往外抛出事件
      if (!stopEmit) {
        this.$emit('action-handle', deepAction);
      }
    },
  },
};
