//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import login from '@/utils/login.js';
import { LOGIN_AS } from 'nges-common/src/constant';
import { lstore } from '@/libs/utils/util';
import AdminLogin from './admin-login';
import { Logout } from '@/api/login';
import { gotoHome } from '@/utils/util';
import { Message } from 'element-ui';
import { SSO_LOGOUT_TYPE } from '@/utils/constant';
import ResetPassword from '../resetPassword';
import { GetAppConfig, GetKvConfigs, GetStaffUserInfo } from 'nges-common/src/web/utils/api';
import { getNewLayoutJson } from 'nges-common/src/layout/queryUtil';
import { get } from 'lodash';

export default {
  name: 'UserAction',
  components: {
    AdminLogin,
    ResetPassword,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      versionDialogVisible: false,
      menus: [],
      menuConfigs: null, // 新菜单配置
      // 默认标题
      loginAsTitle: '代理登录',
      versionNumber: null,
    };
  },
  computed: {
    show_logout() {
      return this.menuConfigs?.length
        ? !!this.menuConfigs.find((i) => i.key === 'logout')?.show
        : true;
    },
  },
  watch: {
    userInfo() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    handleClick(item) {
      if (item.method && this[item.method]) {
        return this[item.method](item);
      }
      if (item.url) {
        this.g_tourl(item.url, item.options);
      }
    },
    async help_center(item) {
      let helpUrl = item.url;
      const token = login.getLoginInfo('token');
      const userInfo = await GetStaffUserInfo();
      const activeRole = userInfo?.active_role;
      if (helpUrl) {
        helpUrl = this.replaceUrlParam(helpUrl, 'token', token);
        helpUrl = this.replaceUrlParam(helpUrl, 'role', activeRole?.join(',') || '');
      }
      this.g_tourl(helpUrl, item.options);
    },
    /**
     * 替换 URL 中的参数
     * @param {string} url - 原始 URL
     * @param {string} paramName - 要替换的参数名
     * @param {string|null} paramValue - 新的参数值，如果为 null 则删除该参数
     * @returns {string} - 替换后的 URL
     */
    replaceUrlParam(url, paramName, paramValue) {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;

      if (paramValue === null) {
        params.delete(paramName);
      } else {
        params.set(paramName, paramValue);
      }

      return urlObj.toString();
    },
    help_document() {
      this.g_tourl('/saas/help.html');
    },
    user_feedback() {
      this.g_tourl('/saas/usageFeedback.html');
    },
    admin_loginAs() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.versionDialogVisible = false
    },
    version_number() {
      this.versionDialogVisible = true
    },
    async login() {
      this.loading = true;
      await this.$refs['admin-login'].login();
      this.loading = false;
    },
    async logout() {
      const message = Message({
        message: '退出登录中... ',
        duration: 0,
      });
      try {
        if (lstore.getItem('loginas') === LOGIN_AS.LOGIN_OTHER) {
          await Logout();
          login.setLogin({ token: '', uin: '' });
          try {
            localStorage.removeItem('myEndTime');
          } catch (e) {}
          login.popUser();
          gotoHome();
        } else {
          await Logout();
          if (this.config?.sso_config?.enable) {
            if (this.config?.sso_config?.logout_url) {
              login.onlyClearLoginStatus();
              window.location.href = this.config.sso_config.logout_url;
            } else {
              sessionStorage.setItem('NGES_SSO_LOGOUT', SSO_LOGOUT_TYPE.WITHOUT_LOGOUT_URL);
              login.exit();
            }
          } else {
            login.exit();
          }
        }
        // TQ子应用的tq-token
        localStorage.setItem('tq_token', '');
      } catch (err) {
        console.error(err);
        this.$message.error(err.message || '退出登录失败，请稍后重试');
      } finally {
        message.close();
      }
    },
    async init() {
      // eslint-disable-next-line no-undef
      this.versionNumber = CODE_VERSION
      // 获取是否支持模拟管理员登录
      const isAdministrator = await GetKvConfigs('["login_as"]');
      // 获取kv中的title
      const res = await GetKvConfigs('["login_as_title"]')
        .then(({ kv }) => {
          if (!kv) return false;
          if (kv[0].value !== '代理登录') return true;
          return false;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (res) this.loginAsTitle = (await GetKvConfigs('["login_as_title"]')).kv[0].value;
      // 系统首先判断是否存在个人中心布局关联的上述配置入口，如果不存在「个人中心」的布局配置，则使用默认的KV配置进行呈现，当系统无法找到该KV默认配置时，才会使用当前系统中当前版本已存在的固定逻辑。
      const newLayoutJsonMenus = await getNewLayoutJson({ usage: 'personal' });
      const kvConfigs = (await GetKvConfigs('["personal_center_menu_configuration"]')).kv;
      const kvMenus = kvConfigs && JSON.parse(get(kvConfigs, '[0].value'));
      this.menuConfigs = newLayoutJsonMenus || kvMenus;
      // 判断是否是管理员登录，登录完后这里只有退出管理员登录菜单
      const loginAsType = lstore.getItem('loginas');
      if (loginAsType === LOGIN_AS.LOGIN_OTHER) {
        this.menus = [];
      } else {
        if (this.menuConfigs?.length) {
          const defaultMenu = [];
          this.menuConfigs
            .filter((i) => i.key !== 'logout')
            .forEach((item) => {
              if (item.show) {
                defaultMenu.push({ ...item, title: item.label, method: item.key });
              }
            });
          this.menus = defaultMenu;
          return;
        }
        if (this.userInfo?.can_login_as && isAdministrator.kv[0].value === '1') {
          this.menus = [
            {
              title: '代理登录',
              method: 'admin_loginAs',
            },
          ];
        } else {
          this.menus = [];
        }
        try {
          const appConfig = await GetAppConfig();
          // 从kv中获取是否打开了重置密码配置项, 不配置或者为1时为打开
          const enableResetPassword = appConfig?.page_config?.enable_reset_password;
          if (enableResetPassword === undefined || enableResetPassword === '1') {
            this.menus.push({
              title: '修改密码',
              method: 'change_password',
            });
          }
        } catch (e) {}
      }
    },
    close() {
      if (this.$refs['admin-login'].showTerritory) {
        login.setLogin({ token: '', uin: '' });
        login.popUser();
      }
    },
    change_password() {
      this.$refs['reset-password'].open();
    },
  },
};
