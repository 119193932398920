import { lstore, sstore, cookie, gotoUrl } from './util';
import {
  Login,
  ParseToken,
  LoginWithWorkWeixin,
  StaffLoginByOIDCForManager,
  LoginPhoneBound,
  LoginWithPhone,
  GetStaffUserInfo,
  GetAppConfig,
} from './api';
import { LOGIN_AS, LOGIN_STEP, SSO_MIDDLE_PATH } from '../../constant';
import { cloneDeep } from 'lodash';
import { getPageConfig } from './common';
const { VUE_APP_TOKEN } = process.env;

// 用来处理并储存当前用户数据，若token不更新，不重新获取数据
const loginUserInfo = {
  userInfo: {},
  token: '',
  fetchUserInfo() {
    return new Promise((resolve) => {
      const loginToken = login.getToken();
      if (loginToken) {
        if (loginToken !== loginUserInfo.token) {
          GetStaffUserInfo()
            .then((data) => {
              loginUserInfo.token = loginToken;
              loginUserInfo.userInfo = {
                ...cloneDeep(data || {}),
                type: 'staff', // web端默认用户都是员工
              };
            })
            .catch(() => {
              loginUserInfo.userInfo = {};
            })
            .finally(() => {
              resolve();
            });
        } else {
          resolve();
        }
      } else {
        loginUserInfo.userInfo = {};
        resolve();
      }
    });
  },
  getUserInfo() {
    return loginUserInfo.userInfo;
  },
};

const login = {
  storageType: '', // cookie sstore lstore 默认sstore
  setStorageType(val) {
    this.storageType = val;
    try {
      window.localStorage.setItem(this.loginKey('storage'), val);
    } catch (e) {}
  },
  getStorageType() {
    if (!login.storageType) {
      try {
        const storageType = window.localStorage.getItem(login.loginKey('storage'));
        if (storageType) login.storageType = storageType;
      } catch (e) {}
    }
    return login.storageType;
  },
  loginKey(name) {
    const key = {
      token: 'saas_token',
      uin: 'saas_uin',
      domain: null,
      storage: 'saas_storage',
    };
    return key[name];
  },
  setLogin({ token, uin, time, canGetUser = true }) {
    const timeMinute = Math.floor(time > 60 ? (time - 60) / 60 : 60);
    const timeSecond = Math.floor(time > 60 ? time - 60 : 60 * 60);
    const tokenKey = this.loginKey('token');
    const uinKey = this.loginKey('uin');
    const storageType = this.getStorageType();
    if (storageType === 'lstore') {
      token ? lstore.setItem(tokenKey, token, timeSecond) : lstore.removeItem(tokenKey);
      uin ? lstore.setItem(uinKey, uin, timeSecond) : lstore.removeItem(uinKey);
    } else if (storageType === 'cookie') {
      const domain = this.loginKey('domain');
      const path = '/';
      token
        ? cookie.setCookie(tokenKey, token, timeMinute, path, domain)
        : cookie.delCookie(tokenKey, path, domain);
      uin
        ? cookie.setCookie(uinKey, uin, timeMinute, path, domain)
        : cookie.delCookie(uinKey, path, domain);
    } else {
      token ? sstore.setItem(tokenKey, token, timeSecond) : sstore.removeItem(tokenKey);
      uin ? sstore.setItem(uinKey, uin, timeSecond) : sstore.removeItem(uinKey);
    }
    canGetUser && loginUserInfo.fetchUserInfo();
  },
  getLoginInfo(key) {
    const storageType = login.getStorageType();
    const loginKey = login.loginKey(key);
    let value = sstore.getItem(loginKey);
    if (storageType === 'lstore') {
      value = lstore.getItem(loginKey);
    } else if (storageType === 'cookie') {
      value = cookie.getCookie(loginKey);
    }
    return value;
  },
  getToken() {
    const token = login.getLoginInfo('token');
    return token || VUE_APP_TOKEN || window.$wujie?.props?.token;
  },
  getUin() {
    return this.getLoginInfo('uin') || '';
  },
  async toLogin() {
    const whiteList = ['/saas/login.html', '/saas/login/door.html', SSO_MIDDLE_PATH];
    if (whiteList.includes(window.location.pathname)) {
      return;
    }
    if (window.location.pathname.includes('/japi/qiye/oauth/ticket')) {
      return;
    }
    if (window.location.search.includes('auth_error')) {
      return;
    }
    // 退出直接跳到根目录页面。
    const keepRedirectUrl = await getPageConfig('web_logout_keep_redirect_url') === '1';
    gotoUrl(`/saas/login.html?redirect_uri=${encodeURIComponent(keepRedirectUrl ? location.href : '/')}`, {
      href: true,
    });
    // 某些页面不需要再次进入
    // const noRedirectList = ['/saas/noPermission.html'];
    // if (noRedirectList.filter((i) => window.location.href.includes(i)).length) {
    //   gotoUrl(`/saas/login.html`, {
    //     href: true,
    //   });
    //   return;
    // }
    // gotoUrl(`/saas/login.html?redirect_uri=${encodeURIComponent(window.location.href)}`, {
    //   href: true,
    // });
  },
  clearLogin() {
    login.setLogin({ token: '', uin: '' });
  },
  onlyClearLoginStatus() {
    this.setLogin({ token: '', uin: '' });
    try {
      localStorage.removeItem('myEndTime');
    } catch (e) {}
  },
  exit() {
    login.onlyClearLoginStatus();
    this.toLogin();
  },
  Login(data) {
    return Login(data).then((res) => {
      if (res.token) {
        lstore.setItem('loginas', LOGIN_AS.LOGIN_SELF);
        this.setLogin({
          token: res.token,
          uin: res.uin,
          time: res.expires_in,
          canGetUser: res.next_step !== LOGIN_STEP.phone,
        });
      }
      return res.next_step;
    });
  },
  ssoLogin(data) {
    this.setStorageType('cookie');
    // 单点登录，从url中获取登录状态
    // 先存储token，再做校验
    this.setLogin({ token: data.token });
    return ParseToken({ token: data.token })
      .then((res) => {
        this.setLogin({
          token: data.token,
          uin: res.uin,
          time: res.expire,
        });
      })
      .catch((err) => {
        // 清除登录
        login.setLogin({ token: '', uin: '' });
        return Promise.reject(err);
      });

    return Promise.resolve();
  },
  qrCodeLogin(data) {
    return LoginWithWorkWeixin(data).then((res) => {
      if (res.token) {
        this.setLogin({
          token: res.token,
          uin: res.uin,
          time: res.expires_in,
          canGetUser: res.next_step !== LOGIN_STEP.phone,
        });
      }
      return res.next_step;
    });
  },
  smsCodeLogin(data) {
    return LoginWithPhone(data).then((res) => {
      if (res.token) {
        this.setLogin({
          token: res.token,
          uin: res.uin,
          time: res.expires_in,
        });
      }
      return res.next_step;
    });
  },
  oktaLogin(code, state, nonce) {
    return new Promise((resolve, reject) => {
      StaffLoginByOIDCForManager({
        code,
        state,
        nonce,
      })
        .then(({ token, uin, expires_in: time, phone, staff_status: staffStatus }) => {
          this.setLogin({ token, uin, time });
          resolve({ phone, status: staffStatus });
        })
        .catch((e) => reject(e));
    });
  },
  oktaBindPhone(phone, code) {
    return LoginPhoneBound({
      phone,
      code,
    });
  },
  userStackStoreKey: 'LOGIN_AS_USER_STACK',
  getUserStack() {
    const userStack = lstore.getItem(this.userStackStoreKey);
    return !Array.isArray(userStack) ? [] : userStack;
  },
  setUserStack(val) {
    lstore.setItem(this.userStackStoreKey, val);
  },
  /**
   * 存储管理员用户的登录相关信息
   */
  pushUser() {
    const userStack = this.getUserStack();
    const loginInfo = {
      token: this.getToken(),
      uin: this.getUin(),
    };
    userStack.push({
      loginInfo,
    });
    this.setUserStack(userStack);
    lstore.setItem('loginas', LOGIN_AS.LOGIN_OTHER);
    loginUserInfo.fetchUserInfo();
  },
  // 还原原有用户的用户信息
  popUser() {
    lstore.setItem('loginas', LOGIN_AS.LOGIN_SELF);
    const userStack = this.getUserStack();
    const { loginInfo } = userStack.pop() || {};
    if (loginInfo) {
      this.setLogin({ token: loginInfo.token, uin: loginInfo.uin });
      this.setUserStack(userStack);
    }
    loginUserInfo.fetchUserInfo();
  },
  obtainLoginInfo() {
    if (this.getToken()) {
      return {
        type: 'staff',
      };
    }
    return {};
  },
  fetchUserInfo: loginUserInfo.fetchUserInfo,
  getUserInfoCache: loginUserInfo.getUserInfo,
};

// 进页面时先执行刷新一遍用户数据缓存
setTimeout(async () => {
  await GetAppConfig();
  loginUserInfo.fetchUserInfo();
});

export default login;
