//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoData from '../../no-data';
import ListIndex from './listIndex.vue';
import ListFilter from './filter.vue';
import TopFilter from './topFilter.vue';
import Custom from '../custom';
import ExportProgress from '../../export-progress';
import Card from './card';
import { fieldClass, getObjects, transItem, mergeJson } from '../../../../layout/util';
import { GetObjectsByNames, DataService } from '../../../utils/api';
import request from '../../../utils/request';
import MProgress from '../../progress';
import MStateTag from '../../state-tag';
import {
  getColumns,
  transJson,
  getFilters,
  getListeningFilters,
  filterOptions,
  transData,
  queryGql,
  cmdPayload,
  getFieldLabel,
  getCustomJson,
  getGlobalConfigGlobalFunc,
  getStaticDataByFilters,
} from '../../../../layout/queryWeb';
import {
  get,
  cloneDeep,
  isArray,
  debounce,
  has,
  uniqBy,
  isPlainObject,
  isNil,
  includes,
  set,
  ceil,
  isFunction,
  isNumber,
  startsWith,
} from 'lodash';
import asyncImage from '../async-image';
import asyncVideo from '../async-video';
import { mapGetters } from 'vuex';
import {
  isExpression,
  execExpression,
  execExpressionDeep,
  hasExpression,
} from '../../../../layout/expression';
import Action from '../../action';
import Premission from '../../permission';
import MButton from 'nges-common/src/web/components/layout/button';
import { getOptionsFrom } from 'nges-common/src/layout/mutation';
import miniDashboard from '../../mini-dashboard';
import miniDashboardCard from '../../mini-dashboard-card';
import Import from './import';
import CustomExport from './customExport';
import LocalStore from './localStore';
import ColsConfig from './colsConfig';
import FormItem from './form-item/index.vue';
import newExport from './newExport.vue';
import { PROP_LABEL, PROP_SPLIT } from 'nges-common/src/constant';
import { replaceParams, query } from 'nges-common/src/util';
import approvalHistoryListItemVue from './approval-center/approval-history-list-item.vue';
import subAlert from '../../alert';
import tooltipDetail from '../tooltip-detail';
import RemoteCustomComponent from '../../remote-custom-component';
import { checkDataRowWritePermission } from '../../../../layout/writePermission';
import searchHistoryPopover from './searchHistoryPopover';
import AwesomeCascader from '../../awesome-cascader';
import TrackingLayoutViewMixin from 'nges-common/src/tracking/mixins/layout-view-mixin';
import { requestArrange } from '../../../../layout/requestArrange';
import ValidateBox from '../../validate-box';
import confirmDialog from 'nges-common/src/web/components/action/confirm-dialog/index.vue';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';
import detailDialog from 'nges-common/src/web/components/action/detail-dialog/index.vue';
import highLightWord from './highLightWord.vue';
import { reportEnd } from 'nges-common/src/web/utils/aegis';
import memoryStore from 'nges-common/src/web/components/memory-store/memory-store';

const _ = {
  get,
  cloneDeep,
  isArray,
  debounce,
  uniqBy,
  isPlainObject,
  has,
  isNil,
  includes,
  set,
};

export default {
  name: 'ListLayout',
  components: {
    NoData,
    ExportProgress,
    ListIndex,
    ListFilter,
    asyncImage,
    asyncVideo,
    MButton,
    TopFilter,
    Custom,
    Card,
    miniDashboard,
    Import,
    CustomExport,
    ColsConfig,
    FormItem,
    newExport,
    miniDashboardCard,
    approvalHistoryListItemVue,
    subAlert,
    tooltipDetail,
    RemoteCustomComponent,
    searchHistoryPopover,
    MProgress,
    AwesomeCascader,
    ValidateBox,
    MStateTag,
    confirmDialog,
    detailDialog,
    highLightWord,
  },
  mixins: [Action, Premission, TrackingLayoutViewMixin, TrackingCompMixin],
  props: {
    json: {
      type: Object,
      default: null,
    },
    layout: {
      type: String,
      default: '',
    },
    selection: {
      type: Boolean,
      default: false,
    },
    allowSelectedMode: {
      type: Boolean,
      default: false,
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
    initData: {
      type: Boolean,
      default: true,
    },
    orderColumn: {
      type: Boolean,
      default: false,
    },
    orderColumnLabel: {
      type: String,
      default: '',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    longSearchBar: {
      type: Boolean,
      default: false,
    },
    showBtns: {
      type: Boolean,
      default: true,
    },
    excelName: {
      type: String,
      default: '',
    },
    // 对表格显示数据进行格式化的函数，由页面提供
    dataFormat: {
      type: Function,
      default: (e) => e,
    },
    // 页面自定义的搜索条件
    customCondition: {
      type: String,
      default: '',
    },
    // 页面自定义的搜索条件
    customCmdCondition: {
      type: Object,
      default: null,
    },
    // 自定义api参数的转换函数
    translateParams: {
      type: [Function, null],
      default: null,
    },
    // 自定义返回数据处理函数
    processData: {
      type: Function,
      default: (v) => v,
    },
    paginationLayout: {
      type: String,
      default: 'total, prev, pager, next, sizes,jumper',
    },
    customGql: {
      type: String,
      default: '',
    },
    // 已选的唯一标识数组
    selected: {
      type: [Array, String, Number, Object],
      default() {
        return [];
      },
    },
    // 已选的对象数组
    selectedDataList: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    isSelectOne: {
      type: Boolean,
      default: false,
    },
    parentData: {
      type: Object,
      default() {
        return {};
      },
    },
    parentRequest: {
      type: Function,
      default: undefined,
    },
    // 组件的额外参数，可以在表达式中通过p访问
    params: {
      type: [Array, String, Number, Object, null],
      default() {
        return null;
      },
    },
    reserveSelection: {
      type: Boolean,
      default: true,
    },
    // 是否展示默认空表占位图
    defaultEmpty: {
      type: Boolean,
      default: true,
    },
    // 自定义页数选择
    pageSizes: {
      type: Array,
      default: undefined,
    },
    // 默认的列表数据，由外部传入
    defaultListData: {
      type: [Object, String],
      default: undefined,
    },
    // 隐藏分页
    hidePagination: {
      type: Boolean,
      default: false,
    },
    // 只有一页,且小于10条的时候隐藏分页
    hidePaginationWhenOnePage: {
      type: Boolean,
      default: false,
    },
    // 是否全选列表
    selectAll: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    // 是否静态分页
    staticPage: {
      type: Boolean,
      default: false,
    },
    showSelectedLen: {
      type: Boolean,
      default: false,
    },
    // 禁用所有action
    disableAllAction: {
      type: Boolean,
      default: false,
    },
    // 是否忽略级联子节点的children处理
    ignoreCascaderChild: {
      type: Boolean,
      default: false,
    },
    // 自定义筛选对象
    customFilters: {
      type: [Object, void 0],
      default: () => {},
    },
    // 表单变更对象
    mutations: {
      type: [Object, void 0],
      default: void 0,
    },
    // 是否在详情tab下
    inDetailTab: {
      type: Boolean,
      default: false,
    },
    // 如果在详情tab下 那么详情tab是否在容器里
    inContainer: {
      type: Boolean,
      default: false,
    },
    // 表格的最大高度
    tableMaxHeightProp: {
      type: Number,
      default: 100000,
    },
    // 表格选择单元格宽度
    selectionWidth: {
      type: Number,
      default: null,
    },
    // 是否通过点击整行能触发选中逻辑
    selectByRowClick: {
      type: Boolean,
      default: false,
    },
    // 是否默认展开
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    // 是否展示tab上的已选数量
    showTabSelectCount: {
      type: Boolean,
      default: false,
    },
    // 是否禁止展示tooltip
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
    selectedListMode: {
      type: Object,
      default: () => ({}),
    },
    dialogFooterTop: {
      type: Number,
      default: null,
    },
    dialogAutoMaxHeight: {
      type: Boolean,
      default: false,
    },
    // 外层提供的action名称与函数对应表 如果传入该参数 那么名称符合的action将直接用对应函数执行
    actionExpose: {
      type: Object,
      default: () => ({}),
    },
    parentScopeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isIpad: (navigator?.appVersion || '').includes('iPad'),
      tableLoading: false,
      listJson: null,
      originColumn: [], // 原始布局中的column
      column: [],
      expandColumn: [],
      originExportColumn: [],
      exportColumn: [],
      list: [],
      search: {
        fields: [],
        placeholder: '',
        input: '',
      },
      filters: [],
      total: 0, // 分页
      pageSize: 0,
      page: 1,
      isExportLoading: false,
      exportWarning: false,
      exportMaxNum: 50000,
      otherConditionStr: '',
      objects: [],
      listSelectedData: [], // 已选项
      tmpChecked: '',
      rowKey: 'id',
      selectStatus: false,
      isClickAll: true,
      otherSelectCount: 0,
      allDataIndeterminateStatus: false,
      selectedMode: false, // 仅展示已选项的模式
      searchIndex: 0, // 用于标注查询次数，防止先查询未返回的请求覆盖了后查询的请求
      exportData: [],
      // 当前组件唯一标识已选列表
      listSelected: [],
      // 导入框参数
      importConfig: {},
      // 自定义导出框参数
      exportConfig: {},
      // 导出的总数
      downloadTotal: 0,
      // 存在前端store中的高度
      colsWidthInStore: {},
      // el-table中的columns配置
      tableColumns: [],
      // 窗口高度
      tableMaxHeight: 0,
      // 销毁窗口方法
      removeListenWinResize: () => {},
      newExportIsShow: false,
      exportActionParams: {},
      // 级联配置
      cascaderConf: [],
      // 是否展示导入框
      openImportDialog: false,
      // 顶部dropDown按钮展开状态
      topDropdownVisible: {},
      // 历史列表存储
      historyList: {},
      // 搜索框popup控制
      searchPopupVisible: false,
      // 历史查询记录列表
      histories: [],
      // 列表左侧筛选
      leftFilter: null,
      // 布局hook请求查询数据
      hookRequestData: {},
      // // 过滤器级联逻辑存储防抖函数
      // filterWatchDebounce: {},
      // table 唯一id
      tableId: Math.floor(Math.random() * 900000) + 100000,
      // 规则校验返回数据对象
      validateObj: null,
      // tab位置
      oldTabIdx: '0',
      tabIdx: '0',
      checkboxRowConfig: {}, // 【【web】编辑列表支持给指定列配置单选多选按钮】https://tapd.woa.com/MedSaaS/prong/stories/view/1020452645118380812
      // 原始列表json，用于切换tab，用tab内的json来更新外部的json
      originListJson: null,
      tabSelectTmp: {},
      tableVisible: true,
      tabCountLoading: false,
      searchActive: false, // 判定搜索是否被激活
      loadingTab: false,
      // selectedOriginTableData: [],
      computedActionWidth: 0, // 计算过的自适应按钮列宽
      listQueryGql: '', // 列表query语句
      lastDragWidth: {},
      isDestory: false,
      // 卡片列表
      cardList: [],
    };
  },
  computed: {
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    valueKey() {
      return this.listJson.render?.checkbox?.value_key || 'id';
    },
    paginationLayoutComputed() {
      if (this.listJson?.render && this.listJson?.render?.page_size > 0) {
        return 'total, prev, pager, next,jumper';
      }
      return this.listJson?.render?.pagination_layout || this.paginationLayout;
    },
    resetShow() {
      return this.filters.length;
    },
    isCascader() {
      return this.listJson?.render?.cascader;
    },
    showData() {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      let list = [];
      if (this.selectedMode) {
        if (this.isSelectAll) {
          list = listSelectedData.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        }
        list = listSelectedData;
      }
      // 通过组件的dataFormat格式化列表数据
      list = this.getFormatList({ list: this.list });
      // 通过配置中的data_format格式化列表数据
      const { data_format: dataFormat } = this.listJson?.render || {};
      if (dataFormat) {
        list = execExpression(dataFormat, { t: list });
      }
      // 级联列表配置
      const { cascader } = this.listJson?.render || {};
      // 处理级联列表
      if (!_.isNil(cascader)) {
        const firstColumnFields = this.cascaderConf?.[0]?.fields;
        this.breadthFirstTraverse({ children: list }, 'children', (node) => {
          if (node.$height > 1) {
            const { fields = [], key } = this.cascaderConf[node.$height - 2] || {};
            fields.forEach((field, index) => {
              const prop = firstColumnFields[index].replaceAll('.', PROP_SPLIT);
              set(node, firstColumnFields[index], get(node, field));
              node[prop] = get(node, field);
              node[`${prop}${PROP_LABEL}`] = getFieldLabel(node, field);
              node.$rowKey = node.$rowKey ?? this.getRowKey(node) ?? this.getRandomRowKey();
              const column = this.column.filter((i) => i.field === firstColumnFields[index])[0];
              if (column) {
                const columnData = node[prop];
                const pramsObj = {
                  t: list,
                  $item: node,
                  $value: columnData,
                  $p: this.parentData,
                  json: this.listJson,
                  p: this.params,
                  $hook: this.hookRequestData,
                };
                if (column.format_tag_type) {
                  node[`__${column.field}_tag_type`] = execExpression(
                    column.format_tag_type,
                    pramsObj,
                  );
                }
              }
            });
            if (key) {
              if (!this.ignoreCascaderChild) {
                if (!isNil(get(node, key))) {
                  const children = get(node, key);
                  node.children = isArray(children) ? children : [children];
                }
              }
              if (node?.children?.length) {
                node.children = node.children.map((child) => ({
                  ...child,
                  $rowKey: child.$rowKey ?? this.getRowKey(child) ?? this.getRandomRowKey(),
                }));
              }
            }
          }
        });
      }
      return list;
    },
    actions() {
      return this.listJson?.actions || [];
    },
    listRender() {
      return this.listJson?.render || {};
    },
    hideRightRow() {
      return this.execExpression(this.listJson?.render?.row?.hide_right_row, this.expParamsObj);
    },
    rowActions() {
      return this.listJson?.render?.row?.actions || [];
    },
    actionsRowStyle() {
      return this.listJson?.render?.row?.actions_row_style;
    },
    filtersValue() {
      const filtersValue = {};
      (this.filters || []).forEach((item) => {
        const field = item?.field || item?.filter?.field;
        const value = item?.filter?.selected;
        field !== undefined
          && value !== undefined
          && (filtersValue[field.replaceAll('.', '-')] = value);
      });
      return filtersValue;
    },
    expParamsObj() {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      let jsonParam = this.listJson;
      if (!jsonParam) {
        const json = this.json ? this.json : this.layoutJson;
        const originJson = this.layout ? _.get(json, this.layout) : json;
        jsonParam = originJson;
      }
      return {
        t: {
          p: this.parentData,
          list: this.list,
          $checked: listSelectedData,
          $export: this.exportData,
          $filters: this.filters,
          $filtersValue: this.filtersValue,
          $search: this.search.input,
          $page: {
            limit: this.isSelectAll ? -1 : this.pageSize,
            offset: this.isSelectAll ? 0 : (this.page - 1) * this.pageSize,
          },
        },
        $filters: this.filters,
        $filtersValue: this.filtersValue,
        p: {
          ...this.params,
        },
        json: jsonParam,
        $hook: this.hookRequestData,
      };
    },
    // 读取布局判断是否需要多选
    needSelection() {
      return this.isSelectOne
        ? false
        : Boolean(this.selection || Boolean(this.listJson?.render?.checkbox));
    },
    showRadio() {
      return Boolean(this.listJson?.render?.show_radio);
    },
    // 是否全选列表
    isSelectAll() {
      return Boolean(this.selectAll || this.listJson?.render?.checkbox?.is_select_all);
    },
    selectAllText() {
      if (!this.selectAllSelectable) {
        return this.getDisableTooltipContent();
      }
      return this.isSelectAll ? '全选列表' : '全选当前页';
    },
    selectAllSelectable() {
      if (!this.json?.render?.checkbox?.disabled_expr) return true;
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      let cache = Array.isArray(listSelectedData) ? [...listSelectedData] : [];
      for (let i = 0; i < this.showData.length; i++) {
        const disabled = !this.calcSelectable(this.showData[i], i, cache);
        if (disabled) {
          return false;
        }
        cache.push(this.showData[i]);
      }
      cache = null;
      return true;
    },
    showOperationCol() {
      const showData = this.showData || [];
      for (let i = 0; i < showData.length; i++) {
        if (this.getRowActions(this.rowActions, showData[i], i).length > 0) {
          return true;
        }
      }
      return false;
    },
    isShowSelectedLen() {
      return Boolean(this.showSelectedLen || this.listJson?.render?.checkbox?.show_selected_len);
    },
    miniDashboard() {
      return this.listJson?.render?.mini_dashboard;
    },
    maxHeight() {
      return this.listJson?.render?.max_height;
    },
    triggerSearchByBtn() {
      return this.listJson?.render?.trigger_search_by_button || false;
    },
    filterSlots() {
      return Object.entries(this.$slots).filter((slot) => slot[0]?.startsWith('filter-'));
    },
    miniDashboardCard() {
      return this.listJson?.render?.mini_dashboard_card;
    },
    nowrap() {
      return this.listJson?.render?.nowrap;
    },
    alertJson() {
      return this.listJson?.render?.alert;
    },
    mainSubTitleSticky() {
      return this.alertJson?.sticky;
    },
    surroundComp() {
      return this.listJson?.render?.surround_components || {};
    },
    remoteUtils() {
      return {
        listLayout: {
          filterChange: this.filterChange,
          filterOnSubmit: this.filterOnSubmit,
        },
      };
    },
    searchStoreKey() {
      const json = this.listJson;
      const jsonKey = json?.render?.store_key || json.cmd || json.object;
      const path = location.pathname;
      const queryPath = query('path');
      const queryObject = query('object');
      return `SEARCH--${path}--${jsonKey}--${queryPath}--${queryObject}`;
    },
    showActionsRow() {
      if (!this.hideRightRow) {
        if (this.rowActions?.length && this.showOperationCol) {
          return true;
        }
        if (this.$scopedSlots.actionsBefore || this.$scopedSlots.actionsAfter) {
          return true;
        }
      }
      return false;
    },
    fixedTable() {
      return this.showActionsRow || this.column.find((i) => i.fixed);
    },
    trackingConfig() {
      const json = this.listJson;
      return {
        trigger: 'enter',
        params: {
          event_type: 'view',
          event_id: 'listObj_Pv',
          event_object: json?.object,
          event_label: json?.title,
        },
        config: this.execExpression(cloneDeep(json?.tracking)),
      };
    },
    tabs() {
      const res = this.listJson?.render?.tabs?.filter((item) => {
        return !this.execExpression(item.hidden, this.expParamsObj);
      });
      return res || [];
    },
    highLightFields() {
      return this.listJson?.highlight_fields || [];
    },
    highLightSection() {
      return this.execExpression(this.listJson?.highlight_section) || [];
    },
    rowKeys() {
      return this.showData.map((i) => i.$rowKey);
    },
    emptyText() {
      return this.listJson.render?.empty_text ?? '-';
    },
    // 是否开启筛存储筛选条件
    storeLayoutFilters() {
      return this.listJson?.store_layout_filters === true;
    },
    allSetStyleWidth() {
      return this.column.every((column) => column?.style?.width);
    },
    // 必填的筛选器
    requireFilters() {
      return this.filters.filter((filter) => filter?.filter?.require);
    },
    resetAction() {
      const configAction = this.getActions(this.actions).find((i) => i.action === 'RESET');
      const defaultAction = {
        label: '重置',
        action: 'RESET',
      };
      return configAction || defaultAction;
    },
    // expand列项是否默认展开
    expandAll() {
      return Object.prototype.hasOwnProperty.call(this.listJson?.render, 'expand_all')
        ? this.listJson?.render?.expand_all
        : this.defaultExpandAll;
    },
    // cardList() {
    //   if (this.listRender.type === 'card') {
    //     return this.tableAllData;
    //   }
    //   return [];
    // },
    searchByInputOnly() {
      return this.listJson?.render?.search?.search_by_input_only || false;
    },
    noDataText() {
      const render = this.listJson?.render;
      const search = render?.search;
      const noDataText = render?.no_data_text;
      const searchByInputOnlyText = search?.search_by_input_only_text || '此数据需要搜索查看';
      return {
        noDataText,
        searchByInputOnlyText,
      };
    },
    // 列表实际显示的数据
    tableShowData() {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      return this.selectedMode ? listSelectedData : this.showData;
    },
    listSelectedDataLen() {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      return listSelectedData?.length;
    },
  },
  watch: {
    async selectedMode(newValue) {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      if (newValue === true) {
        // 进入查看已选项的模式
        this.page = 1;
        if (!this.isSelectAll) {
          this.pageSize = listSelectedData.length;
        }
        this.total = listSelectedData.length;
        this.allDataIndeterminateStatus = false;
        this.selectStatus = !!listSelectedData.length;
        this.setSelected(this.listSelected);
      } else {
        // 恢复默认
        this.page = 1;
        this.pageSize
          = this.listJson.render?.page_size || (this.pageSizes || this.g_pageSizesConfig)[0];
        await this.getTableData();
        const currentSelectList = listSelectedData; // 当前的选择
        const otherList = this.otherSelectChoose(currentSelectList);
        this.otherSelectCount = otherList.length;
        if (listSelectedData.length === 0) {
          this.selectedMode = false;
          this.selectStatus = false;
          this.allDataIndeterminateStatus = false;
          return;
        }
        let chooseCount = 0;
        if (!this.isSelectAll) chooseCount = this.currentPageSelectCount();

        if (
          this.isSelectAll
          && listSelectedData.length !== this.tableAllData.length + this.otherSelectCount
        ) {
          this.allDataIndeterminateStatus = true;
        } else if (!this.isSelectAll && chooseCount !== this.list.length) {
          this.allDataIndeterminateStatus = true;
        } else {
          this.allDataIndeterminateStatus = false;
        }
      }
      setTimeout(() => {
        this.getEmptyWidth();
      }, 100);
    },
    hiddenExe(item) {
      return execExpression(item.hidden, this.expParamsObj);
    },
    listSelectedData(newVal) {
      const newValue = memoryStore.get(newVal, this.scopeId);
      // 当已选项为0时，退出查看已选项的模式
      if (newValue.length === 0) {
        this.selectedMode = false;
        this.selectStatus = false;
        this.allDataIndeterminateStatus = false;
        return;
      }

      // 当处于查看已选项的模式下，第一页的size时刻与已选项长度相同
      if (this.selectedMode) {
        if (!this.isSelectAll) {
          this.pageSize = newValue.length;
        }
        this.allDataIndeterminateStatus = false;
        this.total = newValue.length;
      } else {
        let chooseCount = 0;
        if (!this.isSelectAll) chooseCount = this.currentPageSelectCount();

        if (
          this.isSelectAll
          && newValue.length !== this.tableAllData.length + this.otherSelectCount
        ) {
          this.allDataIndeterminateStatus = true;
        } else if (!this.isSelectAll && chooseCount !== this.list.length) {
          this.allDataIndeterminateStatus = true;
        } else {
          this.allDataIndeterminateStatus = false;
        }

        if (this.isSelectAll && newValue.length === this.otherSelectCount) {
          this.allDataIndeterminateStatus = false;
          this.selectStatus = false;
        }

        if (
          this.isSelectAll
          && this.otherSelectCount === 0
          && newValue.length === this.tableAllData.length
        ) {
          this.allDataIndeterminateStatus = false;
          this.selectStatus = true;
        }

        if (!this.isSelectAll && chooseCount === 0) {
          // 当前页面为空时
          this.allDataIndeterminateStatus = false;
          this.selectStatus = false;
        }

        if (!this.isSelectAll && chooseCount === this.list.length) {
          this.allDataIndeterminateStatus = false;
          this.selectStatus = true;
        }
      }
    },
    selected(val) {
      this.listSelected = val;
    },
    selectedDataList(val) {
      const newVal = memoryStore.get(val, this.parentScopeId);
      this.listSelectedData = memoryStore.set(
        _.uniqBy(this.getFormatList({ list: newVal, simple: true }), this.rowKey),
        this.scopeId,
      );
    },
    maxHeight() {
      this.getTableMaxHeight();
    },
    tableLoading(value, oldValue) {
      if (!value && oldValue) {
        this.getTableMaxHeight();
      }
    },
    showData(val) {
      if (!val.length) {
        setTimeout(() => {
          this.getEmptyWidth();
        }, 100);
      }
    },
    expParamsObj: debounce(async function () {
      // 重新计算column的hidden属性 模仿初始化流程重新计算json
      const newJson = transJson(this.getOriginListJson(), this.expParamsObj);
      let { columns } = getColumns({
        json: newJson,
        objects: this.objects,
      });
      columns = columns.filter((item) => {
        return !this.execExpression(item.hidden) && item.type !== 'expand';
      });
      const newColumn = [];
      columns.forEach((col) => {
        const id = col.prop;
        // 如果列依然存在 那么保留现在的数据
        const now = this.column.find((i) => i.prop === id);
        if (now) newColumn.push(now);
        else newColumn.push(col);
      });
      this.column = await this.setComumnFromStore(newColumn);
    }, 200),
  },
  async destroyed() {
    this.__trkLeave();
    // 将 data 中的所有属性都设置为 null
    Object.keys(this.$data).forEach((key) => {
      this.$data[key] = null;
    });
    this.isDestory = true;
  },
  created() {
    // 存储在memoryStore中的scopeId
    this.scopeId = `${Math.random()}`.slice(2);
    this.tableAllData = []; // 全部数据
    // 同一组件 多实例 防抖存在问题  即表格在同一个页面多个相同组件,同一方法,防抖只会执行一次
    // https://yogwang.site/2022/vue-reused-components-debounce-problem/
    this.debounceProcessCheckboxData = debounce(this.processCheckboxData, 150);
  },
  async mounted() {
    this.getJsonAndInit();
  },
  unmounted() {
    this.debounceProcessCheckboxData.cancel();
  },
  beforeDestroy() {
    this.$refs.list = null;
    this.removeListenWinResize();
    Object.keys(this.$refs).forEach((key) => {
      this.$refs[key] = null;
    });
    this.scopeId && memoryStore.clear(this.scopeId);
  },
  methods: {
    // 对Table进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
    tableDoLayout() {
      this.$nextTick(() => {
        this.getActionWidth();
        // this.getActionHeight();
        this.$refs.list?.doLayout?.();
      });
    },
    async getJsonAndInit() {
      await getGlobalConfigGlobalFunc(this);
      this.tableLoading = true;
      await this.getListJson();
      if (!this.listJson) return;
      this.$nextTick(() => {
        reportEnd('pagestart');
      });
      await this.init();
    },
    async init() {
      this.__trkEnter();
      if (this.needSelection) {
        this.initCheckboxStyle();
      }
      if (this.maxHeight || this.dialogAutoMaxHeight) {
        this.$nextTick(() => {
          this.getTableMaxHeight();
        });
      }
      this.addWinListener();
      await this.getObjects();
      await this.initTable();
      this.setSelectedData(memoryStore.get(this.selectedDataList, this.parentScopeId));
      this.$nextTick(async () => {
        if (this.initData) {
          await this.getLayoutFilters();
          await this.getTableData(true, false, false, true);
          this.setSelectedData(memoryStore.get(this.selectedDataList, this.parentScopeId));
          if (this.needSelection) {
            this.initCheckboxStyle();
          }
        }
      });
    },
    selectable(row, index) {
      const disabledExpr = this.json?.render?.checkbox?.disabled_expr;
      if (!disabledExpr) return !row.disabled;
      // const params = {
      //   $item: row,
      //   $index: index,
      //   selected: this.listSelectedData,
      // };
      const params = this.expParamsObj;
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      params.t = {
        ...params.t,
        $item: this.list[index] || {},
        $index: index,
        selected: listSelectedData,
      };
      const disabled = disabledExpr ? execExpression(disabledExpr, params) : false;
      return !disabled && !row.disabled;
    },
    calcSelectable(row, index, selected = []) {
      const disabledExpr = this.json?.render?.checkbox?.disabled_expr || '';
      if (!disabledExpr) return true;
      const params = this.expParamsObj;
      params.t = {
        ...params.t,
        $item: this.list[index] || {},
        $index: index,
        selected,
      };
      const disabled = disabledExpr ? execExpression(disabledExpr, params) : false;
      return !disabled;
    },
    visibleChange(idx, e) {
      this.$set(this.topDropdownVisible, idx, e);
    },
    get,
    // 监听界面变化
    addWinListener() {
      if (isFunction(this.removeListenWinResize)) {
        this.removeListenWinResize();
      }
      const func = _.debounce(() => {
        this.tableDoLayout();
        if (this.maxHeight || this.dialogAutoMaxHeight) {
          this.getTableMaxHeight();
        }
        this.getEmptyWidth();
        this.scrollFixedStyleChange();
      }, 100);
      // const mainEl = document.querySelector('#app>.container>.container-body>main');
      window.addEventListener('resize', func);
      // mainEl.addEventListener('scroll', func);
      this.removeListenWinResize = () => {
        window.removeEventListener('resize', func);
        // mainEl.removeEventListener('scroll', func);
      };
    },
    // 获取屏幕高度
    getTableMaxHeight() {
      if (this.maxHeight) {
        if (this.maxHeight === 'auto_fit') {
          const winHeight
            = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
          const tableEl = this.$refs?.list?.$el;
          if (winHeight && tableEl) {
            const { top } = tableEl.getBoundingClientRect();
            this.tableMaxHeight = (top > 0 ? winHeight - top : winHeight) - 12;
          }
        } else {
          this.tableMaxHeight = this.maxHeight;
        }
      } else {
        const tableTop = this.$refs?.list?.$el?.getBoundingClientRect()?.top;
        if (this.dialogAutoMaxHeight && tableTop && this.dialogFooterTop) {
          // 底层dialogFooter top - table的top - pagination的高度 - 25(默认偏移值，间距/滚动条等)
          const maxHeight
            = this.dialogFooterTop
            - tableTop
            - (this.$refs?.pagination?.$el?.getBoundingClientRect()?.height || 0)
            - 25;
          // 如果计算出来的最大表格小于200，就不设置了，设置后反而会出问题
          if (maxHeight >= 200) {
            this.tableMaxHeight = maxHeight;
          }
        }
      }
    },
    breadthFirstTraverse(root, childKey, visitNode) {
      const stack = [];
      if (root) {
        stack.push(root);
        root.$height = 1;
        while (stack.length) {
          // 取第一个
          const curNode = stack.shift();
          const canBreak = visitNode(curNode, curNode.$height);
          if (canBreak) {
            break;
          }
          let children = curNode[childKey] || [];
          if (_.isPlainObject(children)) {
            children = [children];
          }
          const childrenLen = children.length;
          for (let i = 0; i < childrenLen; i++) {
            if (children[i]) {
              children[i].$height = curNode.$height + 1;
              stack.push(children[i]);
            }
          }
        }
      }
    },
    itemClicked(item, row, actionParams = {}) {
      this.$emit('action-handle', {
        action: item.action,
        action_params: { ...actionParams, _item: row },
      });
    },
    execExpression(obj, expParamsObj) {
      expParamsObj = expParamsObj ? expParamsObj : this.expParamsObj;
      return execExpressionDeep(obj, expParamsObj);
    },
    getFormatList(data) {
      const { simple } = data;
      const list = data.list || [];
      return this.dataFormat(list).map((item, index) => {
        // 针对listSelectedData的赋值只保证rowKey存在来维持基本逻辑 其他属性暂时不附加
        let $rowKey;
        const { row_key: rowKey } = this.listJson.render;
        if (has(item, '$rowKey')) {
          $rowKey = item.$rowKey;
        } else if (rowKey === 'RANDOM') {
          $rowKey = this.getRandomRowKey();
        } else {
          $rowKey = this.getRowKey(item) ?? this.getRandomRowKey();
        }
        if (simple) {
          const newData = item;
          newData.$rowKey = $rowKey;
          return newData;
        }

        const newData = { ...item };
        const getNewData = (column) => {
          const columnData = item[column.field];
          const pramsObj = {
            t: list,
            $item: item,
            $index: index,
            $value: columnData,
            $p: this.parentData,
            json: this.listJson,
            p: this.params,
            $hook: this.hookRequestData,
          };
          if (column.disabled) {
            newData.disabled = execExpression(column.disabled, pramsObj);
          }
          if (column.format_value) {
            newData[column.prop] = execExpression(column.format_value, pramsObj);
          } else {
            newData[column.prop] = item[column.prop];
          }
          if (column.type === 'tag' || column.type === 'state_tag') {
            const type = fieldClass(newData, column.field);
            if (type) {
              newData[`__${column.field}_tag_type`] = type.replace('is-', '');
            }
          }
          if (column.format_tag_type) {
            newData[`__${column.field}_tag_type`] = execExpression(
              column.format_tag_type,
              pramsObj,
            );
          }
          if (column.format_tag_icon) {
            newData[`__${column.field}_tag_icon`] = execExpression(
              column.format_tag_icon,
              pramsObj,
            );
          }
          if (column.type === 'url' && column.url) {
            newData[`_url_${column.prop}`] = execExpression(column.url, pramsObj);
          }
          if (column.type === 'progress') {
            newData[`_progress_${column.prop}`]
              = execExpressionDeep(cloneDeep(column.progress || {}), pramsObj) || {};
          }
          if (column.img_options) {
            newData[`__${column.field}_img_options`] = isExpression(column.img_options)
              ? execExpression(column.img_options, pramsObj)
              : column.img_options;
          } else {
            newData[`__${column.field}_img_options`] = {
              width: 40,
              height: 40,
              openPreview: true,
            };
          }
        };
        this.column.forEach(getNewData);
        this.expandColumn.forEach(getNewData);
        return {
          ...newData,
          $rowKey,
        };
      });
    },
    getRowKey(newData) {
      const { row_key: rowKey, checkbox } = this.listJson?.render || {};
      const { value_key: valueKey } = checkbox || {};
      return _.get(newData, rowKey || valueKey || 'id');
    },
    getRandomRowKey() {
      return `${Math.random()}_random`.slice(2);
    },
    setSelectedData(listData) {
      this.listSelected = this.selected;
      if (this.isShowSelectedLen) {
        this.listSelectedData = memoryStore.set(
          _.uniqBy(this.getFormatList({ list: listData, simple: true }), this.rowKey),
          this.scopeId,
        );
      }
    },
    // 根据用户存在store中的配置进行控制
    async setComumnFromStore(columns = []) {
      if (!this.listRender.enable_header_config) {
        return columns;
      }
      const { checkedList: storeColumns = [], hiddenList: hiddenColumns = [] }
        = (await LocalStore.getColsOrder(this.listJson)) || [];
      if (!storeColumns.length) {
        return columns;
      }
      const columnsMap = {};
      columns.forEach((item) => {
        columnsMap[item.label] = item;
      });
      let newColumns = [];
      storeColumns.forEach((item) => {
        if (columnsMap[item]) {
          newColumns.push(columnsMap[item]);
        }
      });
      newColumns = newColumns.concat(
        columns.filter((item) => {
          return !includes(storeColumns, item.label) && !includes(hiddenColumns, item.label);
        }),
      );
      return newColumns;
    },
    async initTable() {
      this.pageSize
        = this.listJson.render?.page_size || (this.pageSizes || this.g_pageSizesConfig)[0];
      let { columns, exportColumns } = getColumns({
        json: this.listJson,
        objects: this.objects,
      });
      console.log('------columns------', columns);

      // 过滤列 column 配置的hidden表达式
      let expandColumn = columns.filter((item) => {
        return item.type === 'expand';
      });
      console.log('-----expandColumn----', expandColumn);

      expandColumn = expandColumn.map((item) => {
        return {
          ...item,
          width: item.width || '50%',
        };
      });

      columns = columns.filter((item) => {
        return !this.execExpression(item.hidden) && item.type !== 'expand';
      });

      // 过滤列 exportColumns 配置的hidden表达式
      exportColumns = exportColumns.filter((item) => {
        return !this.execExpression(item.hidden) && item.type !== 'expand';
      });

      // 初始化单元格 单选多选
      // 【【web】编辑列表支持给指定列配置单选多选按钮】https://tapd.woa.com/MedSaaS/prong/stories/view/1020452645118380812
      this.initCheckboxRowConfig(columns);

      this.originColumn = columns;
      this.originExportColumn = exportColumns;
      this.column = await this.setComumnFromStore(columns);
      this.expandColumn = await this.setComumnFromStore(expandColumn);
      console.log('------this.expandColumn------', this.expandColumn);
      this.exportColumn = await this.setComumnFromStore(exportColumns);
      this.search = {
        ...this.search,
        ...this.listJson?.render?.search,
      };
      this.resetAllFilter();
      // const { filters: originFilters = [] } = this.listJson.render;
      // // 打入一个默认索引标志，用于级联更新的时候，能找到对应的原始filter重新渲染
      // originFilters.forEach((item, idx) => {
      //   item.__idx = idx;
      // });
      // let filters = cloneDeep(originFilters);
      // // 计算filter中的默认值，用于组装请求参数
      // filters = filters.map((item) => {
      //   const selected = get(item, 'filter.selected');
      //   if (selected) {
      //     set(item, 'filter.selected', this.execExpression(selected));
      //   }
      //   // filter内不需要混入column内的表达式
      //   delete item.format_value;
      //   delete item.style;
      //   delete item.action;
      //   return item;
      // });
      // // 表达式计算, 将其中非json的部分表达式计算一遍
      // filters = this.execFilterExpression(filters);
      // filters = getFilters({
      //   filters,
      //   objects: this.objects,
      //   json: this.listJson,
      // });
      // filters = filters.filter((item) => item && !item.filter?.hidden);
      // // 左侧树筛选
      // const leftFilterIdx = filters.findIndex(
      //   (filter) => filter?.filter?.value_type === 'CASCADER' && filter?.filter?.mode === 'left',
      // );
      // if (leftFilterIdx > -1) {
      //   this.leftFilter = filters[leftFilterIdx];
      // }
      // this.filters = filters.map((item) => ({
      //   visible: false,
      //   ...item,
      // }));
      this.rowKey = this.listJson.render?.row_key || this.valueKey;
      // if (this.listRender?.resizable) {
      this.colsWidthInStore = await LocalStore.getColsWidth(this.listJson);
      // }

      const { cascader } = this.listJson?.render || {};
      if (cascader) {
        const cascaderConf = [];
        let row = cascader;
        cascaderConf.push(row);
        while (row.children) {
          cascaderConf.push(row.children);
          row = row.children;
        }
        this.cascaderConf = cascaderConf;
      }
    },
    initSearch(payload) {
      this.search = {
        ...this.search,
        input: payload,
      };
      this.searchOnSubmit();
    },
    initCheckboxStyle() {
      this.$nextTick(() => {
        if (!this.$refs.list) {
          return;
        }
        let cellEle = this.$refs.list.$el.querySelector(
          '.el-table__header-wrapper .el-table__header thead tr th .cell',
        );
        const checkboxEle = this.$refs['outer-checkbox'].$el;
        if (cellEle && checkboxEle) {
          cellEle.append(checkboxEle);
        }
        cellEle = null;
      });
    },
    tableRowClassName(e) {
      const { row } = e;
      // 当前选中行id 与 表格的各行比较
      const tmpRowKey = this.rowKey.replace('.', '-'); // 替换否则无法匹配
      let isSelected = false;
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      (listSelectedData || []).forEach((item) => {
        if (item[tmpRowKey] === row[tmpRowKey]) {
          isSelected = true;
        }
      });
      if (isSelected) {
        return 'selected-row';
      }
      return 'table-row';
    },
    handleSelectAll(arr) {
      if (!arr.length) return;
      this.selectStatus = true;
      this.isClickAll = false;
      for (let i = 0; i < arr.length; i++) {
        if (i === arr.length - 1) {
          this.isClickAll = true;
        }
        this.$refs.list.toggleRowSelection(arr[i], true);
      }
      this.getList();
    },
    otherSelectChoose(currentSelectList) {
      // 获取当前列表以外的数据
      const ans = [];
      for (let i = 0; i < currentSelectList.length; i++) {
        const selectIndex = this.tableAllData.findIndex(
          (item) => _.get(item, this.rowKey) === _.get(currentSelectList[i], this.rowKey),
        );
        if (selectIndex === -1) {
          ans.push(currentSelectList[i]);
        }
      }
      return ans;
    },
    // 全选回调
    handleCheckBoxChange() {
      let listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      const currentSelectList = listSelectedData; // 当前的选择
      console.log(this.selectStatus);
      const otherList = this.otherSelectChoose(currentSelectList);
      this.otherSelectCount = otherList.length;
      const status = listSelectedData.length === this.otherSelectCount && !this.selectedMode;
      console.log(this.otherSelectCount, listSelectedData.length, status);
      // 实现全选
      if (this.isSelectAll) {
        if (this.isShowSelectedLen) {
          if (this.selectStatus) {
            this.listSelectedData = memoryStore.set(
              _.uniqBy(this.tableAllData, this.rowKey),
              this.scopeId,
            );
          } else {
            this.listSelectedData = memoryStore.set([], this.scopeId);
          }
          listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
          this.listSelected = listSelectedData.map((i) => _.get(i, this.rowKey));
          this.setSelected(this.listSelected);
        } else {
          this.clearSelection();
          !this.selectedMode && this.handleSelectAll(otherList);
          if (this.allDataIndeterminateStatus) {
            this.handleSelectAll(this.tableAllData);
            this.selectStatus = true;
            this.allDataIndeterminateStatus = false;
            return;
          }
          this.selectStatus && status && this.handleSelectAll(this.tableAllData);
        }
      } else {
        if (this.allDataIndeterminateStatus) {
          if (this.selectStatus) {
            this.$refs.list.toggleAllSelection();
          }
          this.selectStatus = true;
          this.allDataIndeterminateStatus = false;
        }
        this.$refs.list.toggleAllSelection();
      }
    },
    getOriginListJson() {
      const json = this.json ? this.json : this.layoutJson;
      const originJson = this.layout ? _.get(json, this.layout) : json;
      this.originListJson = cloneDeep(originJson);
      let listJson = {};
      const tabsValue = this.originListJson?.render?.tabs?.filter((item) => {
        return !this.execExpression(item.hidden, this.expParamsObj);
      });
      // 如果有tab，则直接用第一个tab打开
      if (originJson.render?.tabs?.length) {
        listJson = this.getTabListJson(this.originListJson, tabsValue || [], this.tabIdx);
      } else {
        listJson = originJson;
      }
      return listJson;
    },
    async getListJson() {
      let listJson = this.getOriginListJson();
      listJson = await getCustomJson(transJson(listJson, this.expParamsObj));
      // 预加载请求编排-初始化后请求
      this.hookRequestData = {
        ...this.hookRequestData,
        ...(await requestArrange(
          get(listJson, 'hook_request.inited'),
          this.expParamsObj,
          () => {
            // this.tableLoading = true;
          },
          () => {
            // this.tableLoading = false;
          },
        )),
      };
      await this.$nextTick();
      this.listJson = listJson;
    },
    getActions(actions) {
      function resetAction(action) {
        if (action?.action_callback) {
          resetAction(action.action_callback);
        }
        if (action?.popup?.actions?.length > 0) {
          action.popup.actions.forEach((action) => {
            resetAction(action);
          });
        }
        if (
          includes(['IMPORT', 'CUSTOM_IMPORT', 'CUSTOM_EXPORT'], action?.action)
          && has(action, 'action_params.request')
        ) {
          action.action_params.$request_action_callback = action.action_params.request;
          delete action.action_params.request;
        }
        if (
          includes(['IMPORT', 'CUSTOM_IMPORT', 'EXPORT', 'CUSTOM_EXPORT'], action?.action)
          && has(action, 'action_callback')
        ) {
          action[`$${action.action.toLowerCase()}_action_callback`] = action.action_callback;
          delete action.action_callback;
        }
        return action;
      }
      return actions
        .filter((action) => !execExpression(action.hidden, this.expParamsObj))
        .filter(this.mixinFilterPermission)
        .map(resetAction)
        .map((act) => {
          if (act.action === 'RESET') {
            return {
              ...act,
              label: '重置',
            };
          }
          return act;
        });
    },
    getPopupActions(popup = {}) {
      if (popup.actions) {
        return popup.actions.filter((action) => {
          return !execExpression(action.hidden, this.expParamsObj);
        });
      }
      return [];
    },
    getRowActions(actions, item, index) {
      return actions
        .filter((item) => !item.popup)
        .filter((action) => !execExpression(action.hidden, this.getRowExpParams(item, index)))
        .filter(this.mixinFilterPermission)
        .filter(this.mixinFilterWritePermission(item))
        .map((action) => {
          const a = {
            ...action,
            write_disabled: this.mixinDisabledWritePermission(action, item),
            disabled: execExpression(action.disabled, this.getRowExpParams(item, index)),
          };
          if (a.sync) {
            const handleSyncNewActions = (action) => {
              action.sync = true;
              const funcName = Object.keys(this.actionExpose).find((i) => i === action.action);
              if (funcName) {
                action.CUSTOM_ACTION = (...e) => this.actionExpose[funcName](...e);
              }
              if (action?.action_callback) {
                handleSyncNewActions(action.action_callback);
              }
            };
            handleSyncNewActions(a);
          }
          return a;
        });
    },
    getRowPopupActions(actions, item, index) {
      const popup = actions.find((item) => item.popup);
      if (!popup) return [];
      return (popup.popup?.actions || [])
        .filter((action) => !execExpression(action.hidden, this.getRowExpParams(item, index)))
        .filter(this.mixinFilterPermission)
        .map((action) => ({
          ...action,
          write_disabled: this.mixinDisabledWritePermission(action, item),
        }));
    },
    getObjects() {
      return GetObjectsByNames({
        objects: getObjects(this.listJson),
      })
        .then(({ list }) => {
          this.objects = list;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getColumnFilter(column) {
      const { field } = column;
      if (field) {
        const columnFilter = this.filters.filter((item) => item.field === field)[0];
        if (column.value_type && columnFilter) {
          columnFilter.filter.value_type = column.value_type;
        }
        return columnFilter;
      }
    },
    cmdService({ pageIndex, pageSize, extraCondition = {} }) {
      const { payload = {} } = this.listJson;
      const pagePayload = execExpressionDeep(cloneDeep(payload), {
        t: { p: this.parentData },
        $hook: this.hookRequestData,
      });
      return request(
        this.listJson?.cmd,
        this.translateParams
          ? this.translateParams({
              page_index: pageIndex,
              page_size: pageSize,
              input: this.search.input,
              filters: this.filters.map((item) => item.filter),
              filtersValue: this.filtersValue,
              json: this.listJson,
              pagePayload,
              hookRequestData: this.hookRequestData,
            })
          : cmdPayload({
              json: this.listJson,
              page: pageIndex + 1,
              pageSize,
              input: this.search.input,
              filters: this.filters,
              filtersValue: this.filtersValue,
              parentData: this.parentData,
              layoutParams: cloneDeep(this.params),
              hookRequestData: this.hookRequestData,
              customCmdCondition: {
                ...extraCondition,
                ...this.customCmdCondition,
              },
            }),
      ).then((data) => {
        let processedData = this.processData(data);
        const { response } = this.listJson || {};
        if (response)
          processedData = execExpression(response, {
            t: processedData,
            json: this.listJson,
            p: this.params,
            $hook: this.hookRequestData,
          });
        return processedData;
      });
    },
    McmAdminService(params) {
      if (!this.listJson?.object) return;
      const execExp = (data) => {
        return execExpressionDeep(_.cloneDeep(data), this.expParamsObj);
      };
      const newJson = {
        ...this.listJson,
        where: execExp(this.listJson?.where),
        wheres: execExp(this.listJson?.wheres),
        orders: execExp(this.listJson?.orders),
        sub_query: execExp(this.listJson?.sub_query),
        trigger_ext: execExp(this.listJson?.trigger_ext),
      };
      const { columns } = this.listJson?.render || {};
      const query = queryGql({
        json: newJson,
        objects: this.objects,
        input: this.search.input,
        params,
        filters: this.filters.map((item) => item.filter),
        customWhere: [this.otherConditionStr, this.customCondition]
          .filter((item) => item)
          .join(','),
        customGql: this.customGql,
        columns,
      });
      return DataService({
        query,
        trigger_ext: newJson.trigger_ext,
      }).then(async (data) => {
        data = this.formatAggregate({ data, columns: columns || [] });
        let tableData
          = transData({
            json: this.listJson,
            objects: this.objects,
            data,
          }) || {};
        const { response } = this.listJson || {};
        if (response) {
          const newListObj
            = execExpression(response, {
              t: tableData,
              json: this.listJson,
              p: this.params,
              $hook: this.hookRequestData,
            }) || {};
          tableData = {
            ...tableData,
            ...newListObj,
          };
        }
        if (tableData.list) {
          // 如果需要检查写权限，则查询权限数据并插入结果
          tableData.list = await checkDataRowWritePermission(this.json, tableData.list);
        }
        this.listQueryGql = query; // 暂存请求的 query
        return tableData;
      });
    },
    // 处理聚合相关
    formatAggregate({ data, columns }) {
      const aggregates = columns.filter(
        (column) => column?.aggregate && !column.field.includes('.'),
      );
      if (data?.[this.listJson?.object]?._aggregate && aggregates?.length) {
        // $isAggregate代表是否包含聚合行
        const aggregateItem = { $isAggregate: true };
        const aggregateData = data?.[this.listJson?.object]?._aggregate;
        const aggregateKeys = Object.keys(aggregateData || {});
        aggregates.forEach((column) => {
          const { label } = column.aggregate;
          const key = aggregateKeys.find((aggregateKey) =>
            aggregateKey.includes(`${column.field}`),
          );
          if (key) {
            const value = aggregateData[key];
            if (!_.isNil(label)) {
              const { label } = column.aggregate;
              aggregateItem[column.field] = isExpression(label)
                ? execExpression(label, { t: value })
                : label;
            } else {
              aggregateItem[column.field] = value;
            }
          } else if (!_.isNil(label)) {
            aggregateItem[column.field] = label;
          }
        });
        if (_.isArray(data?.[this.listJson?.object]?._data)) {
          data[this.listJson?.object]._data.unshift(aggregateItem);
        }
      }
      return data;
    },
    // isFirstPage: 是否直接刷新到第一页
    // isStatic: 是否静态刷新，不会弹出loading
    // once: 由于有查询当前页为空时，跳到最后页的逻辑，避免程序出现异常死循环，故加这个参数控制
    // disableFilterStaticData: 作为当选多选选择器时，不主动触发筛选过滤逻辑
    // isFirstTime： 是否是初始化的时候获取数据的时机？
    async getTableData(isFirstPage, isStatic = false, once = false, isFirstTime = false) {
      if (!this.handleIsAllRequire(isFirstTime)) {
        this.tableLoading = false;
        this.total = 0;
        this.list = [];
        setTimeout(() => {
          this.getEmptyWidth();
        }, 100);
        return;
      }
      // 没有输入不触发搜索
      if (this.searchByInputOnly && !this.search.input) {
        this.tableLoading = false;
        this.$nextTick(() => {
          this.getEmptyWidth();
        });
        return;
      }
      this.$refs.dashboard?.init();
      this.$refs.dashboardCard?.init();
      this.searchIndex = this.searchIndex + 1;
      const scopedSearchIndex = this.searchIndex;
      try {
        // 预加载请求编排-前置请求
        this.hookRequestData = {
          ...this.hookRequestData,
          ...(await requestArrange(
            get(this.listJson, 'hook_request.before_fetch'),
            this.expParamsObj,
            () => {
              // this.tableLoading = true;
            },
            () => {
              // this.tableLoading = false;
            },
          )),
        };
        await this.$nextTick();
        !isStatic && (this.tableLoading = true);
        if (isFirstPage) this.page = 1;
        let tableData;
        // 接受页面提供的数据源
        if (this.defaultListData) {
          tableData = _.cloneDeep(memoryStore.get(this.defaultListData, this.parentScopeId));
          if (tableData?.list && _.isArray(tableData.list) && this.listJson) {
            const { response } = this.listJson;
            if (response) {
              tableData.list
                = execExpression(response, {
                  t: tableData.list,
                  json: this.listJson,
                  p: this.params,
                  $hook: this.hookRequestData,
                }) || [];
            }
            const newJson = {
              ...this.listJson,
              where: this.execExpression(this.listJson?.where),
              wheres: this.execExpression(this.listJson?.wheres),
              orders: this.execExpression(this.listJson?.orders),
              sub_query: this.execExpression(this.listJson?.sub_query),
              trigger_ext: this.execExpression(this.listJson?.trigger_ext),
            };
            tableData.list = getStaticDataByFilters({
              list: tableData.list,
              filters: this.filters.map((item) => item.filter),
              json: newJson,
              input: this.search.input,
            });
            tableData.list = tableData.list.map((item) => {
              return transItem({
                item,
                objects: this.objects,
                json: this.listJson,
              });
            });
            tableData.total = tableData.list.length || 0;
          }
        } else if (this.parentRequest) {
          tableData = await this.parentRequest({
            pageIndex: this.isSelectAll ? 0 : this.page - 1,
            pageSize: this.isSelectAll ? -1 : this.pageSize,
            filters: this.filters.map((item) => item.filter),
          });
        } else if (this.listJson?.cmd) {
          tableData = await this.cmdService({
            pageIndex: isFirstPage || this.isSelectAll ? 0 : this.page - 1,
            pageSize: this.isSelectAll ? -1 : this.pageSize,
          });
        } else {
          tableData = await this.McmAdminService({
            limit: this.isSelectAll ? -1 : this.pageSize,
            offset: isFirstPage || this.isSelectAll ? 0 : (this.page - 1) * this.pageSize,
          });
        }
        if (scopedSearchIndex !== this.searchIndex) {
          return;
        }
        console.log('table data is', tableData);
        const list = tableData?.list || [];
        const total = tableData?.total || 0;
        this.searchActive = true;
        if (this.isSelectAll || this.defaultListData) {
          this.tableAllData = list;
          // 卡片列表模式需要把所有数据渲染出来
          if (this.listRender.type === 'card') {
            this.cardList = this.tableAllData;
          }
          this.list = list.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
          // 若当前页数据已经不存在，则跳到最后一页
          if (!this.list.length && list.length) {
            this.page = parseInt(list.length / (this.pageSize + 1)) + 1;
            this.list = list.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
          }
          this.total = total;
        } else {
          this.total = total;
          this.list = list;
          // 如果数据查出来为空，则回到最后一页。
          // 场景：最后一页只有一条数据，删除此条数据并刷新后，此时要跳回到倒数第二页
          if (list.length === 0 && total > 0 && !once) {
            this.page = ceil(total / this.pageSize);
            this.getTableData(isFirstPage, isStatic, true);
          }
        }
        this.$nextTick(() => {
          if (!this.inDetailTab) reportEnd('datastart');
        });
        this.setSelected(this.listSelected);
        this.requestUrlChange();

        this.tableLoading = false;
        this.$nextTick(() => {
          // 告诉组件外，数据请求成功了
          this.$emit('get-data-success', this.showData);
          // 存储所有的历史数据
          this.historyList[this.page] = this.showData;
          this.scrollFixedStyleChange();
          this.tableDoLayout();
          this.debounceProcessCheckboxData();
        });
        // 预加载请求编排-后置请求
        this.hookRequestData = {
          ...this.hookRequestData,
          ...(await requestArrange(
            get(this.listJson, 'hook_request.after_fetch'),
            this.expParamsObj,
            () => {
              this.tableLoading = true;
            },
            () => {
              this.tableLoading = false;
            },
          )),
        };
      } catch (err) {
        if (scopedSearchIndex !== this.searchIndex) {
          return;
        }
        this.tableLoading = false;
        this.searchActive = true;
        console.error(err);
        this.$message.error(err.message || '查询失败');
        this.$nextTick(() => {
          this.getEmptyWidth();
        });
      }
    },
    // 是否必填全部通过
    handleIsAllRequire(isFirstTime) {
      if (!isFirstTime) {
        const refs = this.$refs;
        if (refs) {
          for (const k of Object.keys(refs)) {
            if (startsWith(k, 'filterPopover')) {
              let filterRefs = refs[k];
              if (!isArray(refs[k])) {
                filterRefs = [refs[k]];
              }
              filterRefs.forEach((ref) => {
                ref?.validate?.();
              });
            }
          }
        }
      }
      const isAllHadValue = this.requireFilters.every((filter) => filter?.filter?.selected);
      return isAllHadValue;
    },
    searchInputChange(v) {
      if (v) {
        this.$set(this.search, 'input', v);
        this.searchOnSubmit();
      }
    },
    searchFocus() {
      if (!this.search.input) {
        this.$refs.searchHistory.show();
      }
    },
    searchBlur() {
      this.$refs.searchHistory.hide();
    },
    searchTracking(val) {
      if (this.search) {
        this.__compTracking(
          {
            event_type: 'input',
            event_id: 'input_Event',
            event_data_content: val,
          },
          this.execExpression(this.search?.tracking),
        );
      }
    },
    searchOnSubmit(force = false) {
      this.$refs?.searchHistory?.hide();
      this.searchActive = false;
      if (force === true) {
        this.$refs?.searchHistory?.setHistoryStore(this.search?.input);
        this.filterOnSubmit();
        return;
      }
      if (this.triggerSearchByBtn) {
        return;
      }
      if (!this.searchDebounce) {
        this.searchDebounce = _.debounce(() => {
          this.filterOnSubmit();
        }, 1000);
      }
      this.searchDebounce();
    },
    // 获取列表
    filterOnSubmit(triggerBySearchBtn) {
      if (triggerBySearchBtn) {
        this.$refs?.searchHistory?.setHistoryStore(this.search?.input);
      }
      this.hideFilter();
      this.page = 1;
      this.tableStatusChange(true);
      this.saveLayoutFilter(); // 存储筛选条件
    },
    topFilterOnSubmit(valueType, force = false) {
      setTimeout(() => {
        // 针对文本输入框做特殊处理，需要对输入的防抖逻辑做处理，与搜索框逻辑保持一致
        if (valueType === 'TEXT') {
          this.searchOnSubmit(valueType, force);
        } else if (force === true || !this.triggerSearchByBtn) {
          this.filterOnSubmit();
        }
      });
    },
    debounceFilterOnSubmit: debounce(function () {
      this.filterOnSubmit();
    }, 200),
    debounceTopFilterOnSubmit: debounce(function () {
      if (!this.triggerSearchByBtn) {
        this.filterOnSubmit();
      }
    }, 200),
    async handleIndexChange(val) {
      this.page = val;
      await this.tableStatusChange();
      this.setSelected(this.listSelected);
    },
    currentPageSelectCount() {
      let chooseCount = 0;
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < listSelectedData?.length; j++) {
          if (_.get(this.list[i], this.rowKey) === _.get(listSelectedData?.[j], this.rowKey)) {
            chooseCount += 1;
            break;
          }
        }
      }
      return chooseCount;
    },

    // 获取当前页面的静态列表
    getList() {
      this.list = this.tableAllData.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize,
      );
    },

    async tableStatusChange(searchOrFilter) {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      if (this.isSelectAll) {
        if (searchOrFilter) {
          await this.getTableData();
          this.otherSelectCount = this.otherSelectChoose(listSelectedData).length;
          console.log(listSelectedData.length, this.otherSelectCount);
          if (listSelectedData.length === this.otherSelectCount) {
            this.allDataIndeterminateStatus = false;
            this.selectStatus = false;
          } else if (listSelectedData.length === this.otherSelectCount + this.tableAllData.length) {
            this.allDataIndeterminateStatus = false;
            this.selectStatus = true;
          } else {
            this.allDataIndeterminateStatus = true;
            this.selectStatus = true;
          }
          return;
        }
        this.getList();
      } else {
        await this.getTableData();
        const chooseCount = this.currentPageSelectCount();
        if (chooseCount === 0) {
          this.selectStatus = false;
          this.allDataIndeterminateStatus = false;
        } else if (chooseCount < this.list.length) {
          this.allDataIndeterminateStatus = true;
        } else {
          this.selectStatus = true;
          this.allDataIndeterminateStatus = false;
        }
      }
      if (this.selectedMode) {
        const total = listSelectedData.length;
        console.log('---tableStatusChange-count---', total);
        this.total = total;
      }
    },

    async handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      await this.tableStatusChange();
    },
    async handleExportExcel(action) {
      const actionParams = action?.action_params;
      const maxNum = actionParams?.max_num || 50000;
      const asyncExport = actionParams?.async_export || false;
      const { total } = this;
      console.log('---total---', total);
      console.log('---totmaxNumal---', maxNum);
      if (
        total < maxNum
        || (this.needSelection && this.listSelectedData?.length)
        || this.defaultListData
      ) {
        this.pageExport(action);
      } else if (asyncExport) {
        this.newExportIsShow = true;
        this.exportActionParams = action?.action_params || {};
      } else {
        this.exportWarning = true;
        this.exportMaxNum = maxNum;
      }
    },
    async pageExport(action) {
      try {
        let { total } = this;
        let staticData = null;
        const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
        if (this.needSelection && listSelectedData?.length) {
          total = listSelectedData.length;
          staticData = cloneDeep(listSelectedData);
        }
        if (this.defaultListData) {
          total = this.tableAllData.length;
          staticData = this.tableAllData;
        }
        const maxNum = action?.action_params?.max_num || 50000;
        if (total < 1) return;
        this.isExportLoading = true;
        this.downloadTotal = total;
        this.$refs.downloadDialog.openDialog();
        this.$refs.downloadDialog.startRidiculousProgress(30);
        let tableData;
        if (staticData) {
          // 若是有选中数据，直接用选中数据来导出
          // 此时不能再执行一次数据格式化函数
          tableData = { list: staticData };
        } else if (this.listJson?.cmd) {
          tableData = await this.cmdService({
            pageIndex: 0,
            pageSize: maxNum,
          });
        } else {
          tableData = await this.McmAdminService({
            limit: maxNum,
            offset: 0,
          });
        }
        const { object } = this.listJson;
        let excelName = '';
        if (this.excelName?.length) {
          excelName = this.excelName;
        } else {
          const objectItem = this.objects.filter((item) => item.object.name === object)[0];
          excelName
            = this.listJson.render?.excel_name || objectItem?.object.display_name || object || '';
        }
        const sheetName = this.listJson.render?.sheet_name || excelName;
        const excelData = [];
        const dataFormat = staticData ? tableData.list : this.dataFormat(tableData.list); // 格式化数据后再生成excel格式数据
        dataFormat.forEach((item, index) => {
          const excel = {};
          this.exportColumn.forEach((column) => {
            let columnData;
            if (column.format_value) {
              columnData = execExpression(column.format_value, {
                t: this.list,
                $item: item,
                $index: index,
                p: this.params,
                $p: this.parentData,
                $value: item[column.field],
                json: this.listJson,
                $hook: this.hookRequestData,
              });
            } else {
              columnData = item[column.prop] !== undefined ? item[column.prop] : item[column.field];
            }
            excel[column.label] = Array.isArray(columnData) ? columnData.join('；') : columnData;
          });
          excelData.push(excel);
        });
        this.$refs.downloadDialog.finishProgress(excelData, excelName, sheetName, [], action);
        this.isExportLoading = false;
      } catch (err) {
        this.isExportLoading = false;
        console.log(err);
        this.$message.error('下载失败');
        this.$refs.downloadDialog.abortProgress();
      }
    },
    hideFilter() {
      this.column.forEach((column, index) => {
        const element = this.$refs[`filterPopover${index}`];
        if (!element) return;
        if (Array.isArray(element)) {
          element.forEach((ele) => {
            ele.doClose && ele.doClose();
          });
        } else {
          element.doClose && element.doClose();
        }
      });
      this.$emit('hide-filter');
    },
    async filterOptions({ filter }) {
      try {
        if (filter.queryOptions?.length) return;
        if (filter.query && !filter.use_split_page) {
          this.$set(filter, '$loading', true);
          const res = await DataService({
            query: filter.query,
            trigger_ext: filter.trigger_ext,
          });
          this.$set(filter, '$loading', false);
          filter.queryOptions = filterOptions(filter, res);
          filter.options = [...filter.options, ...filter.queryOptions];
        } else if (filter?.options_from && (!filter.$isLoaded || filter?.options_from?.refresh)) {
          this.$set(filter, '$loading', true);
          const res = await getOptionsFrom({
            optionsFrom: filter.options_from,
            objects: this.objects,
            filters: this.filters,
            expParamsObj: this.expParamsObj,
          });
          this.$set(filter, '$loading', false);
          filter.$isLoaded = true;
          filter.options
            = filter?.value_type === 'SELECT_ONE'
              ? [{ label: '全部', value: undefined }, ...res]
              : [...res];
          this.filters = [...this.filters];
        }
      } catch (err) {
        console.error(err);
        this.$message.error(err.message || '加载失败');
      }
    },
    filterClick(filter) {
      this.hideFilter();
      // const item = this.getColumnFilter(column);
      filter.visible = true;
      this.filterOptions(filter);
    },
    resetAll() {
      this.$emit('reset-filter');
      this.resetAllFilter(true);
      this.search.input = '';
      this.otherConditionStr = '';
      this.searchOnSubmit(true);
      this.$refs.list?.clearSort();
    },
    resetAllFilter(reloadOption = false) {
      this.filters?.forEach((filter) => {
        this.resetFilter(filter, true);
      });
      // 调用内部的方法重置过滤器，顺便清空里边的搜索
      const refs = this.$refs;
      if (refs) {
        for (const k of Object.keys(refs)) {
          if (startsWith(k, 'filterPopover')) {
            let filterRefs = refs[k];
            if (!isArray(refs[k])) {
              filterRefs = [refs[k]];
            }
            filterRefs.forEach((ref) => {
              ref?.resetFilter();
            });
          }
        }
      }
      this.$nextTick(() => {
        const { filters: originFilters = [] } = this.listJson.render;
        // 打入一个默认索引标志，用于级联更新的时候，能找到对应的原始filter重新渲染
        originFilters.forEach((item, idx) => {
          item.__idx = idx;
        });
        let filters = cloneDeep(originFilters);
        // 计算filter中的默认值，用于组装请求参数
        filters = filters.map((item) => {
          const selected = get(item, 'filter.selected');
          if (selected) {
            set(item, 'filter.selected', this.execExpression(selected));
          }
          // filter内不需要混入column内的表达式
          delete item.format_value;
          delete item.style;
          delete item.action;
          return item;
        });
        // 表达式计算, 将其中非json的部分表达式计算一遍
        filters = this.execFilterExpression(filters);
        filters = getFilters({
          filters,
          objects: this.objects,
          json: this.listJson,
        });
        filters = filters.filter((item) => item && !item.filter?.hidden);
        // 左侧树筛选
        const leftFilterIdx = filters.findIndex(
          (filter) => filter?.filter?.value_type === 'CASCADER' && filter?.filter?.mode === 'left',
        );
        if (leftFilterIdx > -1) {
          this.leftFilter = filters[leftFilterIdx];
        }
        this.filters = filters.map((item) => ({
          visible: false,
          ...item,
        }));
        if (reloadOption) {
          // 重置筛选器后重新获取选项
          this.filters.forEach((filter) => {
            if (get(filter, 'filter.show_type') === 'search_bar') {
              this.filterClick(filter);
            }
          });
        }
      });
    },
    resetFilter(filter, noWatch = false) {
      filter.filter.selected = '';
      if (has(filter.filter, 'where_gql') && has(filter.filter, '$whereGql')) {
        filter.filter.where_gql = filter.filter.$whereGql;
      }
      !noWatch && this.execWatch(filter);
    },
    initDisableTooltip() {
      this.$nextTick(() => {
        this.showData.forEach((item, index) => {
          const el = (this.$refs[`outer-single-checkbox-${index}`] || [])[0];
          let cellEles = this.$refs.list.$el.querySelectorAll(
            '.el-table__body-wrapper tr .el-table-column--selection .cell',
          );
          const cellEle = cellEles[index];
          if (cellEle && el) {
            cellEle.append(el);
          }
          cellEles = null;
        });
      });
    },
    getDisableTooltipContent($item, $index) {
      if (!this.json.render?.checkbox?.disabled_text) return '暂不支持选择';
      const disabledText = this.json.render?.checkbox?.disabled_text || '暂不支持选择';
      const params = this.expParamsObj;
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      params.t = {
        ...params.t,
        $item,
        $index,
        selected: listSelectedData,
      };
      return execExpression(disabledText, params);
    },
    // 初始化cell  checkbox 配置
    // 【【web】编辑列表支持给指定列配置单选多选按钮】https://tapd.woa.com/MedSaaS/prong/stories/view/1020452645118380812
    initCheckboxRowConfig(columns) {
      const checkboxRowConfig = {};
      columns.forEach((item, index) => {
        if (item.checkbox) {
          const checkboxId
            = (item.checkbox.value_key || item.field) + (item.checkbox.type || '') + index;
          item.checkbox.$id = checkboxId;
          checkboxRowConfig[checkboxId] = {
            header: {
              // header多选 半选 反选 先隐藏吧, 写了部分 逻辑不完善, 没时间处理, 设计稿无此功能, 暂不处理
              indeterminate: false, // 表头checkbox 半选(仅多选生效)
              selected: false, // 表头checkbox 选中(仅多选生效)
              hidden: true, // 表头隐藏checkbox(仅多选生效)
              disabled: false, // 表头禁用checkbox(仅多选生效)
            },
            selectedRowKeys: [], // 选中的$rowKey
            hiddenRowKeys: [], // 隐藏的$rowKey
            disabledRowKeys: [], // 禁用的$rowKey
          };
        }
      });
      this.checkboxRowConfig = checkboxRowConfig;
    },
    // 处理全量cell  checkbox 数据
    processCheckboxData() {
      try {
        const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
        const dataList = this.selectedMode ? listSelectedData : this.showData;
        this.column?.forEach((column) => {
          if (!column.checkbox) {
            return;
          }
          if (column.checkbox.type !== 'radio') {
            let { show_header: showHeader } = column.checkbox;
            if (isExpression(showHeader)) {
              showHeader = execExpression(showHeader, this.expParamsObj);
            }
            this.checkboxRowConfig[column.checkbox.$id].header.hidden = !showHeader;
          } else {
            this.checkboxRowConfig[column.checkbox.$id].header.hidden = true;
          }
          dataList.forEach((row) => {
            this.processCheckboxRowData(row, column);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },
    // 处理单个cell  checkbox 数据
    processCheckboxRowData(row, column) {
      try {
        if (!row || !column?.checkbox) {
          return;
        }
        const { checkbox } = column;
        const rowIndex = row._index - 1;
        let { selected, hidden, disabled } = checkbox;
        if (isExpression(selected)) {
          selected = execExpression(selected, this.getRowExpParams(row, rowIndex));
          this.checkboxAddRowData('selected', row, column, selected);
        } else if (selected !== undefined) {
          this.checkboxAddRowData('selected', row, column, selected);
        }
        if (isExpression(hidden)) {
          hidden = execExpression(hidden, this.getRowExpParams(row, rowIndex));
          this.checkboxAddRowData('hidden', row, column, hidden);
        } else if (hidden !== undefined) {
          this.checkboxAddRowData('hidden', row, column, hidden);
        }
        if (isExpression(disabled)) {
          disabled = execExpression(disabled, this.getRowExpParams(row, rowIndex));
          this.checkboxAddRowData('disabled', row, column, disabled);
        } else if (disabled !== undefined) {
          this.checkboxAddRowData('disabled', row, column, disabled);
        }
        // checkbox.$indeterminate = false; // 半选
      } catch (e) {
        console.error(e);
      }
    },
    // cell  checkbox seleted/disabled/hidden状态
    checkboxRowStatus(type, row, column) {
      if (!row?.$rowKey || !column?.checkbox) {
        return { seleted: false, disabled: true, hidden: true }[type];
      }
      if (row.disabled === true && type === 'disabled') {
        return true;
      }
      return !!this.checkboxRowConfig[column.checkbox.$id][`${type}RowKeys`].includes(row.$rowKey);
    },
    // header  checkbox indeterminate/seleted/disabled/hidden状态
    checkboxHeaderStatus(type, column) {
      if (!column?.checkbox) {
        return { indeterminate: false, seleted: false, disabled: true, hidden: true }[type];
      }
      return this.checkboxRowConfig[column.checkbox.$id].header[type];
    },
    // 添加或删除cell 选中数据
    checkboxAddRowData(type, row, column, isAdd) {
      const config = this.checkboxRowConfig[column.checkbox.$id];
      const isExist = this.checkboxRowStatus(type, row, column);
      const rowKeys = config[`${type}RowKeys`];
      if (isAdd) {
        if (!isExist) {
          // 单选只能保存一个
          if (column.checkbox.type === 'radio') {
            config[`${type}RowKeys`] = [row.$rowKey];
          } else {
            // 没有添加过 就添加一次
            rowKeys.push(row.$rowKey);
          }
        }
      } else {
        if (isExist) {
          // 存在就需要删除
          config[`${type}RowKeys`] = rowKeys.filter((key) => key !== row.$rowKey);
        }
      }
      return config[`${type}RowKeys`];
    },
    // checkbox header表头 全选 反选
    handleCheckboxHeaderSelectionChange(column) {
      if (!column?.checkbox) {
        return;
      }
      const config = this.checkboxRowConfig[column.checkbox.$id];
      const headerConfig = config?.header;
      if (!headerConfig || headerConfig.hidden || headerConfig.disabled) {
        return;
      }
      // 全选 - 此处只有多选
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      const dataList = this.selectedMode ? listSelectedData : this.showData;
      headerConfig.indeterminate = false;
      if (!dataList?.length) {
        headerConfig.selected = false;
        return;
      }
      headerConfig.selected = !headerConfig.selected;
      const rowKeys = dataList.map((row) => row.$rowKey);
      rowKeys.forEach((key) => {
        if (headerConfig.selected) {
          // 全选, 添加
          if (!config.selectedRowKeys.includes(key)) {
            config.selectedRowKeys.push(key);
          }
        } else {
          // 反选  删除
          if (config.selectedRowKeys.includes(key)) {
            config.selectedRowKeys = config.selectedRowKeys.filter((rowKey) => rowKey != key);
          }
        }
      });
    },
    // checkbox cell单元格选择
    handleCheckboxRowSelectionChange(row, column) {
      if (!row || !column?.checkbox) {
        return;
      }
      const { type, selected, actions, change } = column.checkbox;
      const rowIndex = row._index - 1;
      if (
        this.checkboxRowStatus('hidden', row, column)
        || this.checkboxRowStatus('disabled', row, column)
      ) {
        // 隐藏 禁用直接返回
        return;
      }
      if (type === 'radio' && this.checkboxRowStatus('selected', row, column)) {
        // 单选 复选- 不能取消
        return;
      }
      if (change && isExpression(change)) {
        // 留个口子, 暂未使用
        execExpression(change, this.getRowExpParams(row, rowIndex));
        // this.$nextTick(() => {
        // this.$forceUpdate();
        // });
        this.processCheckboxRowData(row, column);
      }
      if (actions?.length) {
        // 目前会议所在流程
        actions.forEach((action) => {
          this.rowItemActionHandleNew(action, row, rowIndex);
        });
        // this.itemClicked({ action }, row, { ...row });
        this.$nextTick(() => {
          this.processCheckboxRowData(row, column);
        });
      }
      if (selected === undefined) {
        // 留个口子, 暂未使用 (自定义了选择事件就不要使用默认选中逻辑了)
        const selected = this.checkboxRowStatus('selected', row, column);
        const selectedRowKeys = this.checkboxAddRowData('selected', row, column, !selected);
        console.log('row-checkbox-change', column, this.checkboxRowConfig);
        // this.$forceUpdate();
        this.$emit('row-checkbox-change', {
          ...this.getRowExpParams(row, rowIndex),
          $checkbox: {
            row,
            column,
            selected: !selected,
            selectedRowKeys: [...selectedRowKeys],
          },
        });
      }
    },
    /**
     * 列表中的行选择回调
     */
    handleSelectionChange(val) {
      this.initDisableTooltip();
      if (this.isSelectAll && !this.isClickAll) return;
      let thisListSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      const oldListLength = thisListSelectedData.length || 0;
      if (this.isShowSelectedLen) {
        // 当前页未选数据
        const currentPageUnSelectedData = [];
        (this.selectedMode ? thisListSelectedData : this.showData).forEach((i) => {
          const isExit = val.some((ii) => _.get(ii, this.rowKey) === _.get(i, this.rowKey));
          if (!isExit) {
            currentPageUnSelectedData.push(i);
          }
        });
        let listSelectedData = [...thisListSelectedData];
        val.forEach((i) => {
          const isExit = listSelectedData.some(
            (ii) => _.get(ii, this.rowKey) === _.get(i, this.rowKey),
          );
          if (!isExit) {
            thisListSelectedData.push(i);
          }
        });
        listSelectedData = [...thisListSelectedData];
        currentPageUnSelectedData.forEach((i) => {
          const item = listSelectedData.find(
            (ii) => _.get(ii, this.rowKey) === _.get(i, this.rowKey),
          );
          if (item) {
            this.listSelectedData = memoryStore.set(
              thisListSelectedData.filter(
                (ii) => _.get(ii, this.rowKey) !== _.get(item, this.rowKey),
              ),
              this.scopeId,
            );
            thisListSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
          }
        });
        this.listSelectedData = memoryStore.set(
          _.uniqBy([...thisListSelectedData], this.rowKey),
          this.scopeId,
        );
        thisListSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
        this.listSelected = thisListSelectedData.map((i) => _.get(i, this.rowKey));
        this.setSelected(this.listSelected);
        console.log('this.listSelectedData', thisListSelectedData);
        !this.isShowSelectedLen
          && this.$emit('selection-change', thisListSelectedData, this.isSelectAll);
      } else {
        this.listSelectedData = memoryStore.set(val, this.scopeId);
        thisListSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
        console.log('this.listSelectedData', thisListSelectedData);
        this.$emit('selection-change', val, this.isSelectAll);
      }
      this.resetTabCount(this.listSelected.length - oldListLength);
      this.getTableMaxHeight();
    },
    handleCurrentChange(val, c, e) {
      if (val.disabled === true) {
        return;
      }
      const clickSrcElement = e?.srcElement;
      const clickClassList = clickSrcElement?.classList;
      if (
        e?.[1]?.type === 'selection'
        || (clickClassList
          && (clickClassList.contains('table-cell')
            || clickClassList.contains('cell')
            || clickClassList.contains('el-radio__inner')
            || clickClassList.contains('el-radio__input')
            || clickClassList.contains('select-list-radio')
            || clickClassList.contains('el-table__cell')
            || (clickClassList.contains('el-tooltip') && !clickSrcElement?.firstChild?.tagName)))
      ) {
        if (this.needSelection && this.selectByRowClick) {
          this.$refs.list.toggleRowSelection(val);
        }
        this.tmpChecked = val.$rowKey;
        if (this.isSelectOne) {
          this.$emit('current-change', val);
        }
        this.resetTabCount(1, 'radio');
        this.$nextTick(() => {
          this.getTableMaxHeight();
        });
      } else if (this.listSelected && this.listSelected.length > 0) {
        this.setSelected(this.listSelected);
      } else {
        this.$refs?.list?.setCurrentRow(undefined);
      }
    },
    isImage(row, prop) {
      return /\.(png|jpe?g|gif|svg)(\?.*)?$/.test(row[prop]);
    },
    isArray(row, prop) {
      return _.isArray(row[prop]);
    },
    setOtherConditionStr(val) {
      this.otherConditionStr = val;
    },
    getTableColumnWidth(style = {}, defaultWidth = 'inherit') {
      const { width } = style;
      if (width) return width;
      return this.computedActionWidth || defaultWidth;
    },
    execFilterExpression(filter) {
      return execExpressionDeep(filter, this.expParamsObj, true, false, {}, '', [
        'json',
        'where_gql',
        'payload',
        'response',
        'row',
        'disable_func',
      ]);
    },
    // filter: 触发change的filter
    // value: 触发change的value
    // recordFilter: 已经触发过change的过滤器列表, 这类过滤器不再触发execWatch
    filterChange(filter, value, recordFilters) {
      console.log('------filterChange------', filter, value, recordFilters);
      filter.filter.selected = value;
      this.execWatch(filter, recordFilters);
    },
    // 防抖控制，触发其他组件的监听逻辑
    // debounceExecWatch(filter) {
    //   const filterIdx = filter.__idx;
    //   let filterDebounce = this.filterWatchDebounce[filterIdx];
    //   filterDebounce?.cancel();
    //   filterDebounce = debounce(() => {
    //     this.execWatch(filter);
    //   }, 250);
    //   filterDebounce();
    //   this.filterWatchDebounce[filterIdx] = filterDebounce;
    // },
    // 触发其他组件的监听逻辑
    execWatch(filter, recordFilters = []) {
      const { filters: originFilters = [] } = this.listJson.render;
      // 获取监听中的filters
      const listeningFilters
        = getListeningFilters({
          filters: cloneDeep(originFilters),
          changeFilter: filter,
          recordFilters,
        }) || [];
      recordFilters.push(filter);
      const newListeningOriginFilters = listeningFilters.map((filter) => {
        return this.execFilterExpression(filter);
      });
      const newListeningFilters = getFilters({
        filters: newListeningOriginFilters,
        objects: this.objects,
        json: this.listJson,
      });
      newListeningFilters.forEach((filter) => {
        const replaceIdx = this.filters.findIndex((item) => item.__idx === filter.__idx);
        if (replaceIdx > -1) {
          // if(JSON.stringify(this.filters[replaceIdx]) === JSON.stringify(filter)) {
          //   return;
          // }
          this.filters.splice(replaceIdx, 1, filter);
        } else {
          this.filters.push(filter);
        }
        this.filterChange(filter, filter?.filter?.selected || '', recordFilters);
        // 顶部搜索框直接触发刷新
        if (get(filter, 'filter.show_type') === 'search_bar') {
          this.filterClick(filter);
        }
      });
      this.filters = this.filters
        .filter((item) => item && !item?.filter?.hidden)
        .sort((a, b) => {
          return a.__idx - b.__idx;
        });
    },
    whereGqlChange(filter, whereGql) {
      filter.filter.where_gql = whereGql;
    },
    filterClose(filter) {
      filter.visible = false;
    },
    handleCellClick(...e) {
      this.$emit('cell-click', ...e);

      // e:  row, column, cell, event
      const row = e?.[0];
      const column = this.column[e?.[1]?.index];
      this.handleCheckboxRowSelectionChange(row, column);

      if (!this.needSelection) {
        return;
      }
      const clickSrcElement = e?.[3]?.srcElement;
      const clickClassList = clickSrcElement?.classList;
      // 触发选择的事件包括 选择框、单元格空白区域、单元格内的纯文字区域
      if (
        e?.[1]?.type === 'selection'
        || (clickClassList
          && (clickClassList.contains('table-cell')
            || clickClassList.contains('cell')
            || clickClassList.contains('el-table__cell')
            || (clickClassList.contains('el-tooltip') && !clickSrcElement?.firstChild?.tagName)))
      ) {
        const idx = this.rowKeys.findIndex((item) => item === e[0].$rowKey);
        const selectable = this.selectable(e[0], idx);
        if (!selectable) return;
        this.$refs.list.toggleRowSelection(e[0]);
      }
    },
    handleHeaderClick(col) {
      const column = this.column[col.index];
      this.handleCheckboxHeaderSelectionChange(column);
    },
    handleSortChange(...e) {
      this.$emit('sort-change', ...e);
    },
    getTotalCount() {
      return this.total;
    },
    rowItemActionHandle(action = {}, row = {}, rowIndex) {
      const newAction = execExpressionDeep(cloneDeep(action), this.getRowExpParams(row, rowIndex));
      this.m_actionHandle(newAction);
    },
    rowItemActionHandleNew(action = {}, row = {}, rowIndex) {
      let newAction = execExpressionDeep(cloneDeep(action), this.getRowExpParams(row, rowIndex));
      newAction = {
        ...newAction,
        action_params: {
          ...row,
          _item: { ...row },
          _data: { ...row },
          _index: rowIndex,
          ...(newAction.action_params || {}),
        },
      };
      this.m_actionHandle(newAction);
    },
    m_customAction(action = {}) {
      const { action: actionName } = action;
      switch (actionName) {
        case 'EXPORT': {
          this.exportData = [];
          this.handleExportExcel(action);
          break;
        }
        case 'RESET':
          this.resetAll();
          break;
        case 'RELOAD':
          this.getTableData(true);
          this.$emit('list-reload');
          this.clearSelection(); // 刷新列表后清空选中数据
          break;
        case 'RELOAD_CUR_PAGE':
          this.getTableData();
          break;
        case 'CUSTOM_DIALOG':
        case 'CUSTOM_DRAWER':
          this.$nextTick(() => {
            this.$refs?.custom_asp?.open(action);
          });
          break;
        case 'IMPORT':
        case 'CUSTOM_IMPORT': {
          this.handleImport(action);
          break;
        }
        case 'CUSTOM_EXPORT': {
          this.handleCustomExport(action);
          break;
        }
        case 'COMPLIANCE_SHOW': {
          // this.handleComplianceShow(action?.action_params);
          break;
        }
        default:
          break;
      }
    },
    // 导出数据成功后回调
    handleExportSuccess({ action = {}, data }) {
      this.exportData = data;
      if (has(action, '$export_action_callback')) {
        this.m_actionHandle(action.$export_action_callback);
      }
    },
    // 设置单选多选
    setSelected(selected) {
      const listSelectedData = memoryStore.get(this.listSelectedData, this.scopeId);
      if (this.needSelection || this.isSelectOne) {
        let newSelected = selected || [];
        if (!Array.isArray(newSelected)) {
          newSelected = [newSelected];
        }
        const selection = [];
        newSelected.forEach((selectedItem) => {
          // 用来设置已选的行
          const tableRow = (this.selectedMode ? listSelectedData : this.showData).find(
            (item) => _.get(item, this.rowKey) === selectedItem,
          );
          if (tableRow) {
            selection.push(tableRow);
            this.$nextTick(() => {
              if (this.needSelection) {
                if (!this.isShowSelectedLen) {
                  this.$refs?.list?.toggleRowSelection(tableRow, true);
                }
              } else {
                this.$refs?.list?.setCurrentRow(tableRow);
              }
            });
          }
        });
        if (
          this.needSelection
          && this.isShowSelectedLen
          && this.$refs?.list?.store?.states?.selection
        ) {
          this.$refs.list.store.states.selection = selection;
          this.$emit('selection-change', listSelectedData, this.isSelectAll);
        }
      }
    },
    getRowExpParams(rowData, index) {
      return {
        t: {
          ...rowData,
          $filters: this.filters,
          $filtersValue: this.filtersValue,
          p: {
            p: this.parentData,
          },
        },
        $filters: this.filters,
        $filtersValue: this.filtersValue,
        $index: index,
        $item: rowData,
        json: this.listJson,
        p: {
          ...this.params,
        },
        $hook: this.hookRequestData,
      };
    },
    clearSelection() {
      if (this.isShowSelectedLen) {
        this.$refs.list.store.states.selection = [];
        this.setSelectedData(memoryStore.get(this.selectedDataList, this.parentScopeId));
        this.selectedMode = false;
      } else {
        this.$refs.list?.clearSelection();
      }
    },
    handleImport(action) {
      let actionParams = action?.action_params || {};
      actionParams = {
        ...actionParams,
        template: execExpressionDeep(actionParams.template, this.expParamsObj),
        title: execExpressionDeep(actionParams.title, this.expParamsObj),
        callback: action?.$import_action_callback,
      };
      this.importConfig = actionParams;
      this.openImportDialog = true;
      this.$nextTick(() => {
        this.$refs.importDialog.open();
      });
    },
    async handleCustomExport(action) {
      let actionParams = action?.action_params || {};
      actionParams = {
        ...actionParams,
        callback: action?.$custom_export_action_callback,
      };
      this.exportConfig = actionParams;
      this.$refs.customExportDialog.open();
    },
    // 拖拽时的回调
    headerDrag: debounce(function (newWidth, oldWidth, column) {
      const body = this.$el.getElementsByClassName('el-table__body-wrapper')[0];
      const colWidth = body.getElementsByClassName(column.id)[0].offsetWidth;
      // 当某一列拖动后宽度大于拖动应当设置的宽度 就视为使用了自动填充
      // 因为自动填充导致实际宽度与数值宽度不符 拖动时和fixed隐藏表的展示都会出现问题
      if (Math.abs(colWidth - newWidth) > 2) {
        // 精细计算容易出现误差 直接重置表格以刷新所有列的宽度
        this.tableVisible = false;
        // 保证拖动的最后一列的宽度刷新后依然不变
        this.lastDragWidth = { [column.index]: Math.floor(newWidth) };
        this.$nextTick(() => {
          this.tableVisible = true;
        });
      }
      LocalStore.setColWidth(newWidth, column, this.listJson);
    }, 250),
    // 调起列表头排序弹窗
    setColConf() {
      this.tableColumns = (this.originColumn || []).map((item) => {
        return item.label;
      });
      this.$nextTick(() => {
        this.$refs['cols-config'].open();
      });
    },
    resetTableCol() {
      this.column = [];
      this.$nextTick(async () => {
        this.column = await this.setComumnFromStore(this.originColumn);
        this.exportColumn = await this.setComumnFromStore(this.originExportColumn);
      });
    },
    getCellStyle({ row, column, rowIndex }) {
      const prop = column.property;
      if (!prop) {
        return {};
      }
      const columnConf = this.column.find((item) => item.prop === prop);
      let style = get(columnConf, 'style');
      if (hasExpression(style)) {
        style = style !== undefined ? cloneDeep(style) : style;
        style = execExpressionDeep(style, this.getRowExpParams(row, rowIndex));
      }
      if (isPlainObject(style)) {
        const bgColor = get(style, 'background-color');
        if (bgColor) {
          const newBgColor
            = { success: '#CEF3E2', danger: '#FFD9D6', warning: '#FDEBD5' }[bgColor] || bgColor;
          set(style, 'background-color', newBgColor);
        }
      }

      return style || {};
    },
    /**
     * 获取表单配置，考虑级联情况
     * row.$height确认层级，column中的字段确定列数
     */
    getColumn({ row, column }) {
      // 级联列表配置
      const { cascader } = this.listJson?.render || {};
      const level = row?.$height;
      // 非级联列表或者第一层直接返回表单配置
      if (_.isNil(cascader) || level === 2) {
        return column || {};
      }
      const prefixKeys = [];
      for (let i = 0; i < level - 2; i++) {
        prefixKeys.push(`${this.cascaderConf[i]?.key || ''}`);
      }
      const fields = this.cascaderConf[level - 2]?.fields;
      if (fields && Array.isArray(fields)) {
        const columnIndex = this.column.findIndex((c) => c.field === column.field);
        prefixKeys.push(fields[columnIndex]);
        const cascaderField = prefixKeys.join('.');
        const cascaderColumn
          = this.listJson.render.cascaderColumns.find(({ field }) => {
            return field === cascaderField;
          }) || {};
        return cascaderColumn;
      }
      return {};
    },
    closeImportDialog() {
      this.$nextTick(() => {
        this.openImportDialog = false;
      });
    },
    async checkRules() {
      if (this.$refs?.['form-item']?.length) {
        const checkRulesPromise = this.$refs?.['form-item'].map(async (i) => await i.checkRules());
        const res = await Promise.all(checkRulesPromise);
        return res;
      }
      return [];
    },
    handleDropDownClick(refKey) {
      this.$refs[refKey]?.[0]?.onClick();
    },
    // 如果url带了requestOnce参数 则在请求之后将值从url中去除
    requestUrlChange() {
      if (this.$route.query.request_once) {
        const list = this.$route.query.request_once.split(',');
        const { query } = this.$route;
        // 保证下次读取route读取到了实时query
        delete query.request_once;
        list.forEach((i) => delete query[i]);
        window.history.replaceState(null, '', replaceParams(query, this.$route.path));
      }
    },
    // 空占位符宽度调整
    getEmptyWidth() {
      try {
        const emptyEl = this.$el.getElementsByClassName('release-list-noData')[0];
        if (!emptyEl) return;
        const { clientWidth } = this.$el.getElementsByClassName('table-list')[0];
        if (clientWidth <= 0) return;
        const margin = (clientWidth - 240) / 2;
        emptyEl.setAttribute('style', `margin-left: ${margin}px;`);
      } catch (e) {
        console.log(e);
      }
    },
    getRowParam(scope) {
      return {
        t: this.list,
        $item: scope.row,
        $value: scope.row[scope.column.property],
        $p: this.parentData,
        json: this.listJson,
        p: this.params,
        $hook: this.hookRequestData,
      };
    },
    getCellClassName({ column }) {
      const col = this.column.find(({ field }) => {
        if (column.className && typeof column.className === 'string') {
          const colField = column.className.split(' ')[0];
          return colField === field;
        }
        return false;
      });
      if (col && col.mutation) return 'mutation-cell';
      return '';
    },
    handleUpdateData(params) {
      // const { row, prop, data } = params;
      // set(row, prop, data);
      this.$emit('update-data', params);
    },
    handleComplianceShow(res) {
      this.validateObj = res?.validateObj;
    },
    openListFilter(ref) {
      const el = this.$refs[ref][0];
      el && el.filterClick && el.filterClick();
    },
    scrollFixedStyleChange() {
      setTimeout(() => {
        try {
          // ipad上滚动条的样式无法设置 所以直接使用el-table的默认逻辑处理高度问题即可
          if (this.isIpad) return;

          const hasFixedRight = this.$el.getElementsByClassName('el-table__fixed-right');
          if (hasFixedRight.length) {
            const scrollEl = this.$el.getElementsByClassName('el-table__body-wrapper')[0];
            const fixedEl = hasFixedRight[0];
            // 有纵向滚动条
            if (scrollEl.clientHeight + 20 < scrollEl.scrollHeight) {
              fixedEl.style.right = '16px';
            } else {
              fixedEl.style.right = 0;
            }
            // 有横向滚动条
            if (scrollEl.clientWidth + 10 < scrollEl.scrollWidth) {
              fixedEl.style.bottom = '16px';
              const fixedBodyEl = fixedEl.getElementsByClassName('el-table__fixed-body-wrapper')[0];
              if (fixedBodyEl) {
                const oldMaxHeight = scrollEl.style.maxHeight.slice(
                  0,
                  fixedBodyEl.style.maxHeight.length - 2,
                );
                const maxHeight = Number(oldMaxHeight) - 16;
                fixedBodyEl.style.maxHeight = `${maxHeight}px`;
              }
            } else {
              fixedEl.style.bottom = 0;
            }
          }
          const hasFixedLeft = this.$el.getElementsByClassName('el-table__fixed');
          if (hasFixedLeft.length) {
            const scrollEl = this.$el.getElementsByClassName('el-table__body-wrapper')[0];
            const fixedEl = hasFixedLeft[0];
            // 有横向滚动条
            if (scrollEl.clientWidth + 10 < scrollEl.scrollWidth) {
              fixedEl.style.bottom = '16px';
              const fixedBodyEl = this.$el.getElementsByClassName(
                'el-table__fixed-body-wrapper',
              )[0];
              if (fixedBodyEl) {
                const oldMaxHeight = scrollEl.style.maxHeight.slice(
                  0,
                  fixedBodyEl.style.maxHeight.length - 2,
                );
                const maxHeight = Number(oldMaxHeight) - 16;
                fixedBodyEl.style.maxHeight = `${maxHeight}px`;
              }
            } else {
              fixedEl.style.bottom = 0;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }, 200);
    },
    // 设置输入值
    setSearchInput(kw) {
      this.$set(this.search, 'input', kw);
      this.searchOnSubmit();
    },

    getTabListJson(listJson, tabs, tabIdx) {
      const tabJson = cloneDeep(tabs[Number(tabIdx)]?.json) || {};
      const newListJson = mergeJson(cloneDeep(listJson), tabJson);
      return newListJson;
    },

    // tab发生切换
    async tabChange() {
      if (this.tabIdx === this.oldTabIdx) {
        return;
      }
      this.loadingTab = true;
      await this.$nextTick();
      this.oldTabIdx = this.tabIdx;
      const listJson = this.getTabListJson(this.originListJson, this.tabs, this.tabIdx);
      const newListJson = await getCustomJson(transJson(listJson, this.expParamsObj));
      this.tableVisible = false;
      await this.$nextTick();
      this.tableVisible = true;
      this.listJson = newListJson;
      this.page = 1;
      await this.init();
      this.loadingTab = false;
    },

    // 用于selectmany组件中用来得到每个tab默认的选中数量
    async getTabSelectCount(customGqlCondition, customCmdCondition) {
      this.tabSelectTmp = {};
      if (!this.tabs.length || !this.showTabSelectCount) {
        return;
      }
      const promises = [];
      const getCount = (listJson) => {
        const execExp = (data) => {
          return execExpressionDeep(_.cloneDeep(data), this.expParamsObj);
        };
        if (listJson.cmd) {
          return request(
            listJson?.cmd,
            cmdPayload({
              json: listJson,
              page: 1,
              pageSize: 1,
              parentData: this.parentData,
              layoutParams: cloneDeep(this.params),
              hookRequestData: this.hookRequestData,
              customCmdCondition,
            }),
          ).then((data) => {
            let processedData = this.processData(data);
            const { response } = this.listJson || {};
            if (response)
              processedData = execExpression(response, {
                t: processedData,
                json: listJson,
                p: this.params,
                $hook: this.hookRequestData,
              });
            return processedData.list || [];
          });
        }
        const newJson = {
          ...listJson,
          where: execExp(listJson?.where),
          wheres: execExp(listJson?.wheres),
          orders: execExp(listJson?.orders),
          sub_query: execExp(listJson?.sub_query),
          trigger_ext: execExp(listJson?.trigger_ext),
        };
        return DataService({
          query: queryGql({
            json: {
              ...newJson,
              ignore_id: true,
              ignore_record_type: true,
              // fields: [newJson?.render?.checkbox?.value_key || 'id'],
              fields: [],
            },
            params: {
              limit: 1,
              offset: 0,
            },
            customWhere: [customGqlCondition].filter((item) => item).join(','),
          }),
          trigger_ext: newJson.trigger_ext,
        }).then((data) => {
          return get(data, `${listJson.object}._aggregate.count` || 0);
        });
      };
      this.tabs.forEach((tab, idx) => {
        let listJson = this.getTabListJson(this.originListJson, this.tabs, idx);
        listJson = transJson(listJson, this.expParamsObj);
        promises.push(getCount(listJson));
      });
      this.tabCountLoading = true;
      Promise.all(promises)
        .then((data) => {
          data.forEach((item, idx) => {
            this.$set(this.tabSelectTmp, idx, item);
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.tabCountLoading = false;
        });
    },
    resetTabCount(val, type) {
      // const k = this.valueKey;
      const tabIdx = Number(this.tabIdx);
      if (type === 'radio') {
        if (this.needSelection) return; // 如果是多选触发的，则跳过，已经由selection-change触发
        for (const idx of Object.keys(this.tabSelectTmp)) {
          this.$set(this.tabSelectTmp, idx, 0);
        }
        this.$set(this.tabSelectTmp, tabIdx, 1);
      } else {
        // const tabData = this.tabSelectTmp[tabIdx] || [];
        // // 去掉当前页取消选择的数据。
        // const noSelectData = (this.$refs.list?.data || []).filter(
        //   (item) => (val || []).findIndex((v) => v[k] === item[k]) === -1,
        // );
        // let newTabData = [];
        // tabData.forEach((item) => {
        //   if (noSelectData.findIndex((item2) => item[k] === item2[k]) === -1) {
        //     newTabData.push(item);
        //   }
        // });
        // newTabData = uniqBy([].concat(val).concat(newTabData), k);
        this.$set(this.tabSelectTmp, tabIdx, (this.tabSelectTmp[tabIdx] || 0) + val);
      }
    },
    getLineClampStyle(line) {
      if (line && line !== undefined && line !== null) {
        const lineClamp = isNumber(line) ? line : 3;
        return {
          '-webkit-line-clamp': lineClamp > 3 ? 3 : lineClamp,
          'max-height': `${22 * (lineClamp > 3 ? 3 : lineClamp)}px`,
        };
      }
      return {};
    },
    saveLayoutFilter() {
      if (!this.storeLayoutFilters) return;
      try {
        const data = {
          ...(this.search?.fields?.length ? { search: this.search } : {}),
          ...(this.filters?.length ? { filters: this.filters } : {}),
        };
        if (Object.keys(data).length) {
          LocalStore.setLayoutFilters(data, this.listJson);
        }
      } catch (error) {
        console.error('Error save layout filters:', error);
      }
    },
    async getLayoutFilters() {
      if (!this.storeLayoutFilters) return;
      try {
        const filterData = (await LocalStore.getLayoutFilters(this.listJson)) || {};
        if (this.search?.fields?.length && filterData.search?.fields?.length) {
          this.search = filterData.search;
        }
        if (this.filters?.length && filterData.filters?.length) {
          this.filters = filterData.filters;
        }
      } catch (error) {
        console.error('Error fetching layout filters:', error);
      }
    },
    setColumnMinWidth({ style }, index) {
      if (style?.width) {
        const isLastOrSecondLastColumn
          = index === this.column?.length - 1 || index === this.column?.length - 2;
        return this.allSetStyleWidth && isLastOrSecondLastColumn ? style.width : 0;
      }
      return 0;
    },
    setColumnWidth({ label, style }, expParamsObj, index) {
      if (this.lastDragWidth[index]) {
        return this.lastDragWidth[index];
      }
      const styleWidth = this.colsWidthInStore[this.execExpression(label, expParamsObj)];
      if (styleWidth) {
        return styleWidth;
      }
      if (style?.width) {
        const isLastOrSecondLastColumn
          = index === this.column?.length - 1 || index === this.column?.length - 2;
        return this.allSetStyleWidth && isLastOrSecondLastColumn ? 'auto' : style.width;
      }
      return 'auto';
    },
    // 计算按钮列内所有元素的宽度
    getActionWidth() {
      if (!this.showActionsRow) return;
      if (this.actionsRowStyle?.width) return;
      try {
        const table = this.$el.getElementsByClassName('el-table__fixed-body-wrapper')[0];
        const actionsCellList = table.getElementsByClassName('actions-col');
        if (!actionsCellList.length) return;

        let maxWidth = 0;
        [...actionsCellList].forEach((el) => {
          const cell = el.getElementsByClassName('cell')[0]?.children || [];
          let allWidth = 32;
          [...cell].forEach((i) => {
            const style = window.getComputedStyle(i);
            const width = i.offsetWidth;
            const marginLeft = parseInt(
              style.getPropertyValue('margin-left').replace('px', ''),
              10,
            );
            const marginRight = parseInt(
              style.getPropertyValue('margin-right').replace('px', ''),
              10,
            );
            const elWidth = width + marginLeft + marginRight;
            allWidth += elWidth;
          });
          maxWidth = Math.max(maxWidth, allWidth);
        });
        this.computedActionWidth = maxWidth;
      } catch (e) {
        console.log(e);
      }
    },
    // 针对某些场景下fixed列的行高和主表格本身行高不符的问题 先处理最广泛的操作列
    getActionHeight() {
      if (!this.showActionsRow) return;
      try {
        // 获取主表格行高
        const mainTable = this.$el.getElementsByClassName('el-table__body-wrapper')[0];
        const tableRowList = mainTable.getElementsByClassName('el-table__row');
        const tableRowHeightList = [...tableRowList].map((el) => el.offsetHeight);

        const fixedTableable = this.$el.getElementsByClassName('el-table__fixed-body-wrapper')[0];
        const actionsCellList = fixedTableable.getElementsByClassName('actions-col');
        [...actionsCellList].forEach((el, idx) => {
          if (el.offsetHeight !== tableRowHeightList[idx]) {
            el.style.height = `${tableRowHeightList[idx]}px`;
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    getListQueryGql() {
      return this.listQueryGql;
    },
  },
};
