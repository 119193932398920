//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopMultipleSelect from './topMultipleSelect';
import TopCascader from './topCascader';
import topSubordinate from './topSubordinate';
import { includes, isArray, isNumber, isString } from 'lodash';
import { execExpression, execExpressionDeep } from '../../../../layout/expression';
import { formatTime } from '../../../utils/util';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';

export default {
  components: {
    TopMultipleSelect,
    TopCascader,
    topSubordinate,
  },
  mixins: [TrackingCompMixin],
  props: {
    // 弹出框类型
    valueType: {
      type: String,
      default: '',
    },
    // 最小值
    min: {
      type: [Number, String],
      default: '',
    },
    // 最大值
    max: {
      type: [Number, String],
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: () => undefined,
    },
    placeholder: {
      type: [String, Array],
      default: () => undefined,
    },
    filter: {
      type: Object,
      default: () => {},
    },
    // 自定义筛选对象
    customFilters: {
      type: [Object, void 0],
      default: () => {},
    },
    filterTitle: {
      type: String,
      default: '',
    },
    expParamsObj: {
      type: Object,
      default: () => {},
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        value: this.value,
        min: this.min,
        max: this.max,
      },
      itemFocus: false,
      changeDebounce: null,
      // 级联多选的选项框扩充宽度
      selectWidth: '256px',
      passed: true,
    };
  },
  computed: {
    // 自定义筛选slot唯一key
    filterSlot() {
      return this?.filter?.filter?.slot;
    },
    width() {
      return ['INT', 'DATETIME'].includes(this.valueType) ? 400 : 200;
    },
    isDate() {
      return ['WEEK', 'MONTH', 'YEAR', 'DATES', 'MONTHS', 'YEARS', 'MONTHRANGE'].includes(
        this.valueType,
      );
    },
    timeDisableds() {
      return this.filter?.filter?.disables || [];
    },
    timeDisableFunc() {
      return this.filter?.filter?.disable_func;
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const valueType = this.valueType?.toLowerCase();
          let formatStr = '';
          if (includes(valueType, 'year')) {
            formatStr = 'yyyy';
          } else if (includes(valueType, 'month')) {
            formatStr = 'yyyy-mm';
          } else if (includes(valueType, 'date')) {
            formatStr = 'yyyy-mm-dd';
          }
          if (!formatStr) {
            return false;
          }
          const currentMonth = formatTime(time, formatStr);
          if (this.timeDisableFunc) {
            return execExpression(this.timeDisableFunc, {
              t: {
                value: currentMonth,
                ...this.expParamsObj,
              },
            });
          }
          return this.timeDisableds.some((disabledMonth) => disabledMonth === currentMonth);
        },
        onPick: this.onPick,
      };
    },
    isRequire() {
      return this?.filter?.filter?.require;
    },
    requireErrorTip() {
      return this?.filter?.filter?.require_error_tip;
    },
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
    customFilters: {
      handler(nv) {
        if (this.filterSlot) {
          let { where_gql: whereGql } = this?.filter?.filter || {};
          if (whereGql) {
            whereGql = execExpression(whereGql, {
              t: nv[this.filterSlot],
            });
            this.$emit('whereGqlChange', whereGql);
          } else {
            this.$emit('change', nv[this.filterSlot]);
          }
          this.debounceFilterOnSubmit();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.filterClick();
    this.setValue(this.value);
  },
  methods: {
    validate() {
      const v = this.filter?.filter?.selected;
      if (this.isRequire && ((isArray(v) && !v.length) || (isString(v) && v.trim() === ''))) {
        this.passed = false;
      } else {
        this.passed = true;
      }
    },
    setValue(val) {
      let formValue = val;
      if (val) {
        if (this.isDate && isNumber(val)) {
          formValue = val * 1000;
        } else if (isArray(val) && (this.valueType === 'DATETIME' || this.isDate)) {
          formValue = val.map((item) => {
            return new Date(isNumber(item) ? item * 1000 : item);
          });
        }
      }
      this.form.value = formValue;
    },
    getPlaceholder(defaultStr, idx) {
      if (isArray(this.placeholder) && idx !== undefined) {
        return this.placeholder[idx] || defaultStr;
      }
      return this.placeholder || defaultStr;
    },
    resetFilter() {
      this.$emit('resetFilter');
      // reset后重新赋值给组件的from
      this.$nextTick(() => {
        this.form.value = this.value;
        this.form.min = this.min;
        this.form.max = this.max;
      });
      if (this.$refs['multiple-select']) {
        this.$refs['multiple-select'].searchText = '';
      }
      if (this.$refs.cascader) {
        this.$refs.cascader?.reset();
      }
      if (this.$refs.subordinate) {
        this.$refs.subordinate?.reset();
      }
    },
    filterOnSubmit() {
      this.$emit('filterOnSubmit', this.valueType);
    },
    debounceFilterOnSubmit() {
      this.$emit('debounceFilterOnSubmit');
    },
    filterClick() {
      this.$emit('filterClick');
    },
    valueChange(val, submitNow = true, noTracking = false, data) {
      this.$emit('change', val, data);
      submitNow && this.filterOnSubmit();
      if (!noTracking) {
        this.tracking(val);
      }
    },
    valueChangeWithWhereGql(val) {
      let whereGql = this.getWhereGql();
      let t = val;
      if (isArray(val)) {
        t = val.map((v) => {
          // 毫秒时间戳
          if (`${v}`.length === 13) {
            return parseInt(v / 1000, 10);
          }
        });
      } else if (`${val}`.length === 13) {
        t = parseInt(val / 1000, 10);
      }
      if (whereGql) {
        whereGql = execExpression(whereGql, {
          t,
        });
        this.$emit('whereGqlChange', whereGql);
      }
      this.$emit('change', t);
      this.filterOnSubmit();
    },
    getWhereGql() {
      if (!this.originSelectedExp) {
        const { where_gql: whereGql } = this.filter.filter || {};
        this.originSelectedExp = whereGql;
      }
      return this.originSelectedExp;
    },
    singleSelectChange(val) {
      this.valueChange(val);
      this.filterOnSubmit();
    },
    minChange(val) {
      const newValue = isArray(this.value) ? this.value : [];
      newValue[0] = val;
      this.valueChange(newValue);
      this.tracking(newValue, 'input');
    },
    maxChange(val) {
      const newValue = isArray(this.value) ? this.value : [];
      newValue[1] = val;
      this.valueChange(newValue);
      this.tracking(newValue, 'input');
    },
    onPick({ maxDate, minDate }) {
      if (maxDate && this.timeDisableds.length) {
        const isInvalid = this.timeDisableds.find((item) => {
          const disabledDate = new Date(formatTime(item, 'yyyy-mm-dd hh:ii:ss'));
          return disabledDate >= minDate && disabledDate <= maxDate;
        });
        if (isInvalid) {
          this.$message('区间不能包含禁用的月份');
          throw Error('区间不能包含禁用的月份');
        }
      }
    },
    execExpression(obj) {
      return execExpressionDeep(obj, this.expParamsObj);
    },
    tracking(value, type = 'select') {
      this.__compTracking(
        {
          event_type: type,
          event_id: `${type}_Event`,
          event_data_content: value,
          event_name: `${this.filterTitle || ''}${type === 'select' ? '选择' : '输入'}`,
        },
        this.execExpression(this.filter?.tracking),
      );
    },
    // 宽度自动调整
    layoutReset() {
      this.$nextTick(() => {
        if (this.$refs.cascader.selected.length === 0) {
          this.selectWidth = '256px';
          return;
        }
        const labelList = [];
        this.$refs.cascader.selected.forEach((i) => {
          const opt = this.$refs.cascader.list.find((j) => j.value === i);
          if (opt) labelList.push(opt.label);
        });
        const maxLength = Math.max(...labelList.map((i) => i.length));
        // 第一个标签的长度
        const maxLengthSize = maxLength * 14 + 4 + 8 + 8;
        // 第二个标签的长度
        let numSize = 0;
        if (labelList.length > 1) {
          numSize = (labelList.length.toString().length + 1) * 14 + 4 + 8 + 8;
        }
        // 标题长度
        let titleSize = 0;
        if (this.filterTitle) {
          titleSize = (this.filterTitle.length + 1) * 14 + 10;
        }
        const result = titleSize + maxLengthSize + numSize + 50;
        const min = this.filterTitle?.length > 6 ? 520 : 256;
        this.selectWidth = `${Math.max(result, min)}px`;
      });
    },
  },
};
