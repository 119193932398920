import { setLoginInfo, clearLoginInfo, getLoginInfo, toLogin, lstore } from './common';
import { isWxwork } from '../util';
import { getStaffUserInfo, getDoctorUserInfo, Logout, ParseToken, GetAppConfig } from '../api';
import { requestConfig } from '../request';
import { LOGIN_AS, LOGIN_STEP } from '../constant';
import { gotoUrl } from '../common';
import { cloneDeep, isEmpty } from 'lodash';

let loginSdk = {};
loginSdk = require('./webLogin');
loginSdk.setConfig();




const { login, customLogin, setConfig } = loginSdk;

const loginUserInfo = {
  userInfo: {},
  token: '',
  fetchUserInfo() {
    return new Promise((resolve) => {
      const step = getLoginInfo('step');
      // 多因子认证前不获取员工信息
      if (step === LOGIN_STEP.phone) {
        resolve();
        return;
      }
      const loginToken = customLogin.getToken();
      if (loginToken && getLoginInfo('tokentype') !== 'tourist') {
        const type = getLoginInfo('type');
        GetAppConfig()
          .then(() => {
            const fetchFunc =
              type === 'staff'
                ? getStaffUserInfo.bind(null, {}, 'GetStaffUserInfo')
                : getDoctorUserInfo;

            if (loginToken !== loginUserInfo.token) {
              fetchFunc()
                .then((data) => {
                  loginUserInfo.token = loginToken;
                  loginUserInfo.userInfo = {
                    ...cloneDeep(data || {}),
                    type: type || '',
                  };
                })
                .catch(() => {
                  loginUserInfo.userInfo = {};
                })
                .finally(() => {
                  resolve();
                });
            } else {
              resolve();
            }
          })
          .catch(() => {
            loginUserInfo.userInfo = {};
          })
          .finally(() => {
            resolve();
          });
      } else {
        loginUserInfo.userInfo = {};
        resolve();
      }
    });
  },
  getUserInfoCache() {
    if (!loginUserInfo.userInfo || isEmpty(loginUserInfo.userInfo)) {
      loginUserInfo.fetchUserInfo();
    }
    return loginUserInfo.userInfo;
  },
};

function doLogin(curUrl = '') {
  return customLogin
    .doLogin(curUrl)
    .then(async (data) => {
      await GetAppConfig();
      loginUserInfo.fetchUserInfo();
      return Promise.resolve(data);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}

function clearLogin(clearAdminLogin = true) {
  login.clearLogin();
  clearLoginInfo();
  if (clearAdminLogin) adminLoginUtil.setUserStack([]);
  // 即使接下来请求的是一个白名单内的接口也不能带上之前的token 防止直接用上次的结果跳过多因子认证
  requestConfig({
    token: '',
  });
  
}

// 获取当前登录信息
function obtainLoginInfo() {
  if (customLogin.getToken()) {
    return {
      type: getLoginInfo('type'),
      tokentype: getLoginInfo('tokentype'),
    };
  }
  return {};
}

function pageShow() {
  return true;
  
  const wxwork = isWxwork();
  if (wxwork) return true;
  return customLogin.getToken();
}

async function exitLogin(curUrl) {
  if (getLoginInfo('loginas') === LOGIN_AS.LOGIN_OTHER) {
    await exitAdminLogin(curUrl);
  } else {
    await Logout();
    clearLogin();
    if (isWxwork()) {
      return customLogin.toLogin(curUrl);
    }
    return doLogin(curUrl);
  }
}

// 管理员Loginas登录退出
async function exitAdminLogin(curUrl) {
  await Logout();
  clearLogin(false);
  adminLoginUtil.popUser();
  gotoUrl({
    wxaurl: curUrl,
    force: 'reLaunch',
  });
}

// 获取员工信息
function getStaffInfo() {
  return getStaffUserInfo();
}

// 管理员登录-用户信息存储，登录进栈，登出出栈
const adminLoginUtil = {
  storeKey: 'LOGIN_AS_USER_STACK',
  getUserStack() {
    const userStack = lstore.getItem(this.storeKey);
    return !Array.isArray(userStack) ? [] : userStack;
  },
  setUserStack(val) {
    lstore.setItem(this.storeKey, val);
  },
  /**
   * 存储管理员用户的登录相关信息
   */
  pushUser() {
    const userStack = this.getUserStack();
    // 存在登录sdk里的数据
    const sdkLoginInfo = {
      token: login.getLoginInfo('token'),
      uin: login.getLoginInfo('uin'),
      time: login.getLoginInfo('time'),
      type: login.getLoginInfo('type'),
    };
    // 存在本地storge里的数据
    const storeLoginInfo = {
      tokentype: getLoginInfo('tokentype'),
      status: getLoginInfo('status'),
      type: getLoginInfo('type'),
    };
    userStack.push({
      sdkLoginInfo,
      storeLoginInfo,
    });
    this.setUserStack(userStack);
    setLoginInfo('loginas', LOGIN_AS.LOGIN_OTHER);
    loginUserInfo.fetchUserInfo();
  },
  // 还原原有用户的用户信息
  popUser() {
    setLoginInfo('loginas', LOGIN_AS.LOGIN_SELF);
    const userStack = this.getUserStack();
    const { sdkLoginInfo, storeLoginInfo } = userStack.pop() || {};
    if (sdkLoginInfo && storeLoginInfo) {
      login.setLogin(sdkLoginInfo);
      setLoginInfo('tokentype', storeLoginInfo.tokentype);
      setLoginInfo('status', storeLoginInfo.status);
      setLoginInfo('type', storeLoginInfo.type);
      this.setUserStack(userStack);
    }
    loginUserInfo.fetchUserInfo();
  },
};

function fetchUserInfo() {
  return loginUserInfo.fetchUserInfo();
}

function getUserInfoCache() {
  return loginUserInfo.getUserInfoCache();
}

function ssoLogin(data) {
  login.setLogin({ token: data.token, time: 120 });
  return ParseToken({ token: data.token })
    .then((res) => {
      login.setLogin({
        token: data.token,
        uin: res.uin,
        time: res.expire,
      });
      // sso下默认是员工
      setLoginInfo('status', '1');
      setLoginInfo('type', 'staff');
      setLoginInfo('tokentype', 'account');
      return Promise.resolve();
    })
    .catch((err) => {
      // 清除登录
      login.setLogin({ token: '', uin: '' });
      return Promise.reject(err);
    });
}

export {
  setConfig,
  doLogin,
  clearLogin,
  exitLogin,
  login,
  customLogin,
  getLoginInfo,
  setLoginInfo,
  toLogin,
  obtainLoginInfo,
  pageShow,
  getStaffInfo,
  adminLoginUtil,
  fetchUserInfo,
  getUserInfoCache,
  ssoLogin,
};
