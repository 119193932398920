//
//
//
//
//
//
//

export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    leftImg() {
      return `${this.g_imgReplace(
        'https://baike-med-1256891581.file.myqcloud.com/2021034/c03485a0-8608-11eb-947c-032fa08bea6e_0.png',
      )}`;
    },
  },
};
